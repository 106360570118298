import CONTROLS_CONSTANTS from "./constantsControls";
const DEMO = {
  formConfig: {
    headline: null,
    subHeadline: null,
    isShowHeadline: false,
    renderFormTag: false,
    formActionURL: null,
    formMethod: "POST",
    enableServerSideValidation: false,
    serverSideValidationEndpoint: "",
  },
  sections: {
    "section-a19a793d-275e-4008-a291-8b981d917ca6": {
      uniqueId: "section-a19a793d-275e-4008-a291-8b981d917ca6",
      headline: "Client Info",
      headlineAdditionalClass: null,
      subHeadline: "Client information is auto filled from client account",
      subHeadlineAdditionalClass: null,
      isShowHeadline: true,
      sortOrder: 1,
      type: "normal",
      rows: [],
      controls: [
        "control-00485e81-debf-4745-8859-8eb22520a70e",
        "control-929810eb-105d-4ddb-90dc-3dd08e2836cc",
        "control-cd521d6a-2f40-49a4-8cf3-51d741572bea",
        "control-281148f3-e928-4539-96e3-b6193b648380",
        "control-adb625b6-6f3a-45bd-9715-3417362c2956",
        "control-72e3db1d-4adb-4046-ac33-7b9a263def23",
        "control-fc9ae961-314f-4f7c-9448-a9bf67666b43",
        "control-27ea1114-3dc2-4193-8115-83913d3174c2",
        "control-84abc6d6-25c7-4c73-b2cc-04ed18967ac0",
        "control-93b99bca-e166-4e6b-b6a0-72a87ce19c22",
        "control-1cf14eea-c485-4c72-996c-156fe4c6c910",
        "control-d6374595-49a6-42d7-a39e-31cfe52837f9",
      ],
    },
    "section-f5212855-ecb6-4867-8c66-d796194e80df": {
      uniqueId: "section-f5212855-ecb6-4867-8c66-d796194e80df",
      headline: "Author Info",
      headlineAdditionalClass: null,
      subHeadline: "Author information is auto filled from author account",
      subHeadlineAdditionalClass: null,
      isShowHeadline: true,
      sortOrder: 1,
      type: "normal",
      rows: [],
      controls: [
        "control-f08fe974-6e60-4a58-a0e7-62fd4ab4338a",
        "control-f086d58f-8e3b-4016-831e-5baaa550cf5f",
        "control-34f302d4-c165-4f34-b1fc-4b112a2c13ce",
        "control-86d4d95d-528f-4d87-8f89-85128edb8241",
        "control-5219292d-6986-4ed8-a262-c600352feffd",
      ],
    },
    "section-3bfa6f90-b5e5-4de1-8c22-a89b8476cf34": {
      uniqueId: "section-3bfa6f90-b5e5-4de1-8c22-a89b8476cf34",
      headline: "Session Info",
      headlineAdditionalClass: null,
      subHeadline: "Session information is auto filled from system",
      subHeadlineAdditionalClass: null,
      isShowHeadline: true,
      sortOrder: 1,
      type: "normal",
      rows: [],
      controls: [
        "control-02c013ac-3949-465c-a97d-e08146f6c8cb",
        "control-c3e02da5-5755-4eab-92af-164c4e22564b",
        "control-cf189da7-4e58-4da7-aa58-940168839be0",
        "control-22e237cd-3b8b-4da3-8dee-a9ab7c0406bb",
        "control-5a9d4025-94a0-4c5d-9b0b-0eec870a9c11",
        "control-663de5b8-3519-44f3-b596-b801945fa798",
        "control-9bfb1597-3d17-478e-8ebe-b0bd3c09ed42",
      ],
    },
    "section-f0c115a3-4019-44f0-ae35-0d21af008215": {
      uniqueId: "section-f0c115a3-4019-44f0-ae35-0d21af008215",
      headline: "Service Info",
      headlineAdditionalClass: null,
      subHeadline: "Service info is auto filled from system",
      subHeadlineAdditionalClass: null,
      isShowHeadline: true,
      sortOrder: 1,
      type: "normal",
      rows: [],
      controls: [
        "control-fafc3f88-f4b7-45a2-8e74-75bb9fb6de6d",
        "control-7be604e0-9155-445f-93c4-b1b4ba909785",
        "control-a84dcbab-e7a8-4cb9-aa5e-b4d1979c4f99",
        "control-f0f11ed5-c2a9-40d0-8e9b-3880e057b38e",
        "control-3db40012-f35c-4893-9b4d-406ff0ef5de7",
        "control-998c6942-b9c9-4c06-b6dc-aeca8db5788d",
        "control-e8da8f59-ce3d-4b42-97f3-79b4e505259b",
      ],
    },
  },
  rows: {},
  controls: {
    "control-00485e81-debf-4745-8859-8eb22520a70e": {
      uniqueId: "control-00485e81-debf-4745-8859-8eb22520a70e",
      type: "input",
      name: "patientName",
      label: "Name",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-cd521d6a-2f40-49a4-8cf3-51d741572bea": {
      uniqueId: "control-cd521d6a-2f40-49a4-8cf3-51d741572bea",
      type: "input",
      name: "patientFirstName",
      label: "First Name",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-281148f3-e928-4539-96e3-b6193b648380": {
      uniqueId: "control-281148f3-e928-4539-96e3-b6193b648380",
      type: "input",
      name: "patientMiddleName",
      label: "Middle Name",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-adb625b6-6f3a-45bd-9715-3417362c2956": {
      uniqueId: "control-adb625b6-6f3a-45bd-9715-3417362c2956",
      type: "input",
      name: "patientLastName",
      label: "Last Name",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-929810eb-105d-4ddb-90dc-3dd08e2836cc": {
      uniqueId: "control-929810eb-105d-4ddb-90dc-3dd08e2836cc",
      type: "input",
      name: "patientEmail",
      label: "Email Address",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-72e3db1d-4adb-4046-ac33-7b9a263def23": {
      uniqueId: "control-72e3db1d-4adb-4046-ac33-7b9a263def23",
      type: "input",
      name: "patientPhone",
      label: "Phone Number",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-84abc6d6-25c7-4c73-b2cc-04ed18967ac0": {
      uniqueId: "control-84abc6d6-25c7-4c73-b2cc-04ed18967ac0",
      type: "input",
      name: "patientClaimNumber",
      label: "Claim Number",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-fc9ae961-314f-4f7c-9448-a9bf67666b43": {
      uniqueId: "control-fc9ae961-314f-4f7c-9448-a9bf67666b43",
      type: "input",
      name: "patientFundingSource",
      label: "Funding Source",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-1cf14eea-c485-4c72-996c-156fe4c6c910": {
      uniqueId: "control-1cf14eea-c485-4c72-996c-156fe4c6c910",
      type: "input",
      name: "patientDiagnosis",
      label: "Diagnosis",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-27ea1114-3dc2-4193-8115-83913d3174c2": {
      uniqueId: "control-27ea1114-3dc2-4193-8115-83913d3174c2",
      type: "input",
      name: "patientStatus",
      label: "Status",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-f08fe974-6e60-4a58-a0e7-62fd4ab4338a": {
      uniqueId: "control-f08fe974-6e60-4a58-a0e7-62fd4ab4338a",
      type: "input",
      name: "authorName",
      label: "Name",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-f086d58f-8e3b-4016-831e-5baaa550cf5f": {
      uniqueId: "control-f086d58f-8e3b-4016-831e-5baaa550cf5f",
      type: "input",
      name: "authorEmail",
      label: "Email Address",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-34f302d4-c165-4f34-b1fc-4b112a2c13ce": {
      uniqueId: "control-34f302d4-c165-4f34-b1fc-4b112a2c13ce",
      type: "input",
      name: "authorFirstName",
      label: "First Name",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-86d4d95d-528f-4d87-8f89-85128edb8241": {
      uniqueId: "control-86d4d95d-528f-4d87-8f89-85128edb8241",
      type: "input",
      name: "authorLastName",
      label: "Last Name",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-5219292d-6986-4ed8-a262-c600352feffd": {
      uniqueId: "control-5219292d-6986-4ed8-a262-c600352feffd",
      type: "input",
      name: "authorStatus",
      label: "Status",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-c3e02da5-5755-4eab-92af-164c4e22564b": {
      uniqueId: "control-c3e02da5-5755-4eab-92af-164c4e22564b",
      type: "date",
      name: "sessionEndDate",
      label: "End Date",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      format: "YYYY-MM-DD",
      firstDay: 0,
      numberOfMonths: 1,
      numberOfColumns: 1,
      minDate: null,
      maxDate: null,
      singleMode: true,
      minDays: 0,
      maxDays: 0,
      returnType: "format",
    },
    "control-22e237cd-3b8b-4da3-8dee-a9ab7c0406bb": {
      uniqueId: "control-22e237cd-3b8b-4da3-8dee-a9ab7c0406bb",
      type: "input",
      name: "sessionReason",
      label: "Reason",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-9bfb1597-3d17-478e-8ebe-b0bd3c09ed42": {
      uniqueId: "control-9bfb1597-3d17-478e-8ebe-b0bd3c09ed42",
      type: "text",
      name: "sessionMemo",
      label: "Memo",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 4,
    },
    "control-5a9d4025-94a0-4c5d-9b0b-0eec870a9c11": {
      uniqueId: "control-5a9d4025-94a0-4c5d-9b0b-0eec870a9c11",
      type: "input",
      name: "sessionType",
      label: "Type",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-663de5b8-3519-44f3-b596-b801945fa798": {
      uniqueId: "control-663de5b8-3519-44f3-b596-b801945fa798",
      type: "input",
      name: "sessionStatus",
      label: "Status",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-02c013ac-3949-465c-a97d-e08146f6c8cb": {
      uniqueId: "control-02c013ac-3949-465c-a97d-e08146f6c8cb",
      type: "date",
      name: "sessionStartDate",
      label: "Start Date",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      format: "YYYY-MM-DD",
      firstDay: 0,
      numberOfMonths: 1,
      numberOfColumns: 1,
      minDate: null,
      maxDate: null,
      singleMode: true,
      minDays: 0,
      maxDays: 0,
      returnType: "format",
    },
    "control-cf189da7-4e58-4da7-aa58-940168839be0": {
      uniqueId: "control-cf189da7-4e58-4da7-aa58-940168839be0",
      type: "label",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-fafc3f88-f4b7-45a2-8e74-75bb9fb6de6d": {
      uniqueId: "control-fafc3f88-f4b7-45a2-8e74-75bb9fb6de6d",
      type: "input",
      name: "serviceName",
      label: "Name",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-e8da8f59-ce3d-4b42-97f3-79b4e505259b": {
      uniqueId: "control-e8da8f59-ce3d-4b42-97f3-79b4e505259b",
      type: "text",
      name: "serviceDescription",
      label: "Description",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-f0f11ed5-c2a9-40d0-8e9b-3880e057b38e": {
      uniqueId: "control-f0f11ed5-c2a9-40d0-8e9b-3880e057b38e",
      type: "input",
      name: "serviceProv",
      label: "Prov",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-7be604e0-9155-445f-93c4-b1b4ba909785": {
      uniqueId: "control-7be604e0-9155-445f-93c4-b1b4ba909785",
      type: "radio",
      name: "serviceTaxable",
      label: "Taxable",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      displayMode: "next",
      position: "left",
      items: [
        {
          value: "true",
          text: "Yes",
        },
        {
          value: "false",
          text: "No",
        },
      ],
    },
    "control-a84dcbab-e7a8-4cb9-aa5e-b4d1979c4f99": {
      uniqueId: "control-a84dcbab-e7a8-4cb9-aa5e-b4d1979c4f99",
      type: "radio",
      name: "serviceCatastrophic",
      label: "Catastrophic",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      displayMode: "next",
      position: "center",
      items: [
        {
          value: "true",
          text: "Yes",
        },
        {
          value: "false",
          text: "No",
        },
      ],
    },
    "control-3db40012-f35c-4893-9b4d-406ff0ef5de7": {
      uniqueId: "control-3db40012-f35c-4893-9b4d-406ff0ef5de7",
      type: "number",
      name: "serviceRate",
      label: "Rate",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      isReal: true,
      decimalPlace: 1,
    },
    "control-998c6942-b9c9-4c06-b6dc-aeca8db5788d": {
      uniqueId: "control-998c6942-b9c9-4c06-b6dc-aeca8db5788d",
      type: "input",
      name: "serviceUnit",
      label: "Unit",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      typeAttribute: "text",
    },
    "control-93b99bca-e166-4e6b-b6a0-72a87ce19c22": {
      uniqueId: "control-93b99bca-e166-4e6b-b6a0-72a87ce19c22",
      type: "input",
      name: "patientPolicyNumber",
      label: "Policy Number",
      subLabel: "",
      isShowLabel: true,
      placeholderText: "",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "",
      additionalFieldClass: "",
      additionalLabelClass: "",
      defaultValue: "",
      validations: [],
      typeAttribute: "text",
    },
    "control-d6374595-49a6-42d7-a39e-31cfe52837f9": {
      uniqueId: "control-d6374595-49a6-42d7-a39e-31cfe52837f9",
      type: "date",
      name: "patientInjuryDate",
      label: "Date of Injury",
      subLabel: "",
      isShowLabel: true,
      placeholderText: "",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "",
      additionalFieldClass: "",
      additionalLabelClass: "",
      defaultValue: "",
      validations: [],
      format: "YYYY-MM-DD",
      firstDay: 0,
      numberOfMonths: 1,
      numberOfColumns: 1,
      minDate: null,
      maxDate: null,
      singleMode: true,
      minDays: 0,
      maxDays: 0,
      returnType: "format",
    },
  },
};

const DEMO2 = {
  formConfig: {
    headline: null,
    subHeadline: null,
    isShowHeadline: false,
    renderFormTag: false,
    formActionURL: null,
    formMethod: "POST",
  },
  sections: {
    "section-79a921fc-f1d8-45f2-b955-00ba8dcf8f96": {
      uniqueId: "section-79a921fc-f1d8-45f2-b955-00ba8dcf8f96",
      headline: null,
      headlineAdditionalClass: null,
      subHeadline: "This is the sub-headline of the new section",
      subHeadlineAdditionalClass: null,
      isShowHeadline: false,
      sortOrder: 1,
      type: "normal",
      rows: [],
      controls: [
        "control-685b5aa6-1ac4-43c1-a907-e2f0f5fd15f4",
        "control-dee00e1e-496b-4ff7-8d1c-7f9ec5c27af5",
        "control-c170b76a-477f-4ea2-8cc2-8f5b4f5c4def",
        "control-541aba29-50f4-4f6b-8ad1-6c79f7a067e5",
        "control-aba38445-14fb-4091-9dab-184377d613d8",
        "control-ffcddd17-e0b3-4e89-9c60-4d9ae5039692",
        "control-29bc1d09-7170-4117-a7d9-1d77250917d3",
        "control-e3dc7c1c-4489-4f38-a1da-0f4f11fe2972",
        "control-47db0ae0-e3f5-4143-8222-c9817cb980ad",
        "control-71f1ff16-c4d8-4f95-a480-dd97bb07de9d",
        "control-1167a993-ac47-4b13-97a8-ad875ea00053",
        "control-f29461fa-3e5b-487e-91ae-4005d135bdfd",
        "control-413de867-8802-4414-8795-5f27c62329ac",
        "control-38816927-4e5f-45d7-bd1c-044bf581a8fe",
        "control-9c3c0fc8-e398-4989-b8a2-8e5a041787a1",
        "control-9bd095fe-3bd9-490f-9163-8c7ce2c814a7",
        "control-6eb61b67-e1bf-4583-bb58-26cfdce0708e",
        "control-0d8931eb-fb9d-4c1e-bb06-c3f54038aacc",
        "control-63e65022-7633-408f-a53d-a2af7a624b9c",
        "control-7d7b5e0b-ed6f-47fc-830a-22a6167aab25",
        "control-5d62083d-c844-4a55-bc02-32187c3d79e8",
        "control-4c8ded2e-002e-48a6-8aa5-88a375b2e12a",
        "control-649702bb-1831-4b3e-81ea-1395da6fba76",
        "control-be0e8025-1fae-4d4f-a7e6-307ab2f7ae50",
        "control-2fabfd7f-acd8-4b24-b855-6b17a8496518",
        "control-c1f08862-0b2b-40a1-a98a-e2592193cb46",
        "control-d4d64a32-a94a-4704-9a94-badc0f873830",
        "control-14ea7fdb-524a-4656-adb4-140928249bff",
        "control-78a6a842-9eb7-44f0-bd99-a5631d3c0632",
        "control-6bbd5262-c8f7-4056-a296-61aa4b7f9a75",
        "control-b558f1b1-05d9-4611-b39b-c7684d01cef2",
        "control-28385e15-938f-4c29-ad0b-031625c3cf13",
        "control-0fa68e30-1b15-4e2b-bb44-411a9751e892",
        "control-f35559cb-6b89-46f4-918a-3c4b18ddf453",
        "control-c065fd5a-8a1f-4d42-a763-5e8171721b4c",
        "control-fcafdaf4-7340-4b08-b973-2eed63eef7e2",
        "control-79d13bf2-24a1-4af7-bc6e-21ca39fb55e2",
        "control-f7360444-86c4-4c56-9328-9d86355c9cbd",
        "control-eddacea6-b649-4a3d-b479-ac9919739922",
        "control-3d966f7a-0906-40b3-af7b-b82c05cafbdd",
        "control-904f46cf-295b-495e-bb85-c66afcae901b",
        "control-ea7ad577-dab5-4d2a-9c11-25b6458483d0",
        "control-7b5f31e4-f56e-4b91-9c3c-ff9f9e639f4e",
        "control-da199831-3f4b-4d4a-b044-4082556d59c3",
        "control-87f54821-1316-43fc-8c3b-940800dfb93d",
        "control-0881f9b4-648e-4ce1-a3e1-43d76b12a817",
        "control-d467ce4e-7791-45dd-a56c-d5a8353c7dcb",
        "control-c9a6eea6-a602-4251-8be1-b3ec8ac47c6f",
        "control-66d1ea4d-1996-484c-a746-a0e48b947d18",
        "control-78248ad4-f4a2-4c7e-b239-ce4069e39752",
        "control-047af7e6-8663-46a6-a2af-01d02931314c",
        "control-5745ee7d-5d79-4f9c-907e-7c574675917e",
        "control-a17981ac-0503-4089-8caf-0173f762bbb2",
        "control-07e83c18-03c1-4fb7-a8b9-3961ef255082",
        "control-824e058c-adfd-4ebc-a651-963b4411f414",
        "control-2e1835b4-a80f-4025-a017-b3be904cc68d",
        "control-0f66f708-e680-440f-97b1-67d739aaf73e",
        "control-e076c1dd-99cc-49c6-9eaf-c15247d5f4bf",
        "control-3099bce7-c629-47df-97c6-e7417927351f",
        "control-ceb40596-7d8b-4a8d-923f-fa635d6f3780",
        "control-a3cd35cd-ea84-4435-9011-eb8a1f2dbcb8",
        "control-b752efcd-e6ac-4b25-b20b-dbf4e9ff27b1",
        "control-683f5ed3-dd70-43be-975b-c4616fb4c8a3",
        "control-43f4be41-610b-4cb1-8b76-45bf2093bc1e",
        "control-e378f13e-1a3f-4c7d-9e37-28882e6c90f0",
      ],
    },
  },
  rows: {},
  controls: {
    "control-685b5aa6-1ac4-43c1-a907-e2f0f5fd15f4": {
      uniqueId: "control-685b5aa6-1ac4-43c1-a907-e2f0f5fd15f4",
      type: "label",
      name: null,
      label: "CLIENT NAME",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-dee00e1e-496b-4ff7-8d1c-7f9ec5c27af5": {
      uniqueId: "control-dee00e1e-496b-4ff7-8d1c-7f9ec5c27af5",
      type: "input",
      name: "patientLastName",
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Last Name",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-c170b76a-477f-4ea2-8cc2-8f5b4f5c4def": {
      uniqueId: "control-c170b76a-477f-4ea2-8cc2-8f5b4f5c4def",
      type: "input",
      name: "patientFirstName",
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "First Name",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-541aba29-50f4-4f6b-8ad1-6c79f7a067e5": {
      uniqueId: "control-541aba29-50f4-4f6b-8ad1-6c79f7a067e5",
      type: "text",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Insurance Information:",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up no-resize",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-aba38445-14fb-4091-9dab-184377d613d8": {
      uniqueId: "control-aba38445-14fb-4091-9dab-184377d613d8",
      type: "label",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: null,
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-ffcddd17-e0b3-4e89-9c60-4d9ae5039692": {
      uniqueId: "control-ffcddd17-e0b3-4e89-9c60-4d9ae5039692",
      type: "input",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Approved Stipend Expense/Activity Completed",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-29bc1d09-7170-4117-a7d9-1d77250917d3": {
      uniqueId: "control-29bc1d09-7170-4117-a7d9-1d77250917d3",
      type: "input",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Approved RSW Mileage/Destination",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass:
        "m-0 no-padding no-radius sticky-up sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-e3dc7c1c-4489-4f38-a1da-0f4f11fe2972": {
      uniqueId: "control-e3dc7c1c-4489-4f38-a1da-0f4f11fe2972",
      type: "label",
      name: null,
      label: "Rehabilitation Therapist",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 pt-4 text-bold",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-47db0ae0-e3f5-4143-8222-c9817cb980ad": {
      uniqueId: "control-47db0ae0-e3f5-4143-8222-c9817cb980ad",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Lead Rehabilitation Therapist",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "api",
      multiple: false,
      items: [],
      apiURL: "http://{getProvidersAPI}",
      apiTextKey: "name",
      apiValueKey: "id",
    },
    "control-71f1ff16-c4d8-4f95-a480-dd97bb07de9d": {
      uniqueId: "control-71f1ff16-c4d8-4f95-a480-dd97bb07de9d",
      type: "input",
      name: null,
      label: "Secondary RT (If applicable)",
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Secondary RT (If applicable)",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-1167a993-ac47-4b13-97a8-ad875ea00053": {
      uniqueId: "control-1167a993-ac47-4b13-97a8-ad875ea00053",
      type: "date",
      name: "sessionStartDate",
      label: "Session Date",
      subLabel: null,
      isShowLabel: true,
      placeholderText: "YYYY-MM-DD",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass:
        "no-padding no-radius white-bg border input-border-0",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 pl-3 pt-1 text-primary",
      defaultValue: null,
      validations: [],
      format: "YYYY-MM-DD",
      firstDay: 1,
      numberOfMonths: 1,
      numberOfColumns: 1,
      minDate: null,
      maxDate: null,
      singleMode: true,
      minDays: 0,
      maxDays: 0,
      returnType: "format",
    },
    "control-f29461fa-3e5b-487e-91ae-4005d135bdfd": {
      uniqueId: "control-f29461fa-3e5b-487e-91ae-4005d135bdfd",
      type: "label",
      name: null,
      label: "Session Start Time",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0  no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 mt-3",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-9c3c0fc8-e398-4989-b8a2-8e5a041787a1": {
      uniqueId: "control-9c3c0fc8-e398-4989-b8a2-8e5a041787a1",
      type: "dropDown",
      name: "sessionStartAMPM",
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "AM/PM",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left2",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "AM", text: "AM" },
        { value: "PM", text: "PM" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-9bd095fe-3bd9-490f-9163-8c7ce2c814a7": {
      uniqueId: "control-9bd095fe-3bd9-490f-9163-8c7ce2c814a7",
      type: "label",
      name: null,
      label: "Session End Time",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 mt-3",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-63e65022-7633-408f-a53d-a2af7a624b9c": {
      uniqueId: "control-63e65022-7633-408f-a53d-a2af7a624b9c",
      type: "dropDown",
      name: "sessionEndAMPM",
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "AM/PM",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left2",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "AM", text: "AM" },
        { value: "PM", text: "PM" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-413de867-8802-4414-8795-5f27c62329ac": {
      uniqueId: "control-413de867-8802-4414-8795-5f27c62329ac",
      type: "input",
      name: "sessionStartHours",
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "HH",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-38816927-4e5f-45d7-bd1c-044bf581a8fe": {
      uniqueId: "control-38816927-4e5f-45d7-bd1c-044bf581a8fe",
      type: "input",
      name: "sessionStartMinutes",
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "MM",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-6eb61b67-e1bf-4583-bb58-26cfdce0708e": {
      uniqueId: "control-6eb61b67-e1bf-4583-bb58-26cfdce0708e",
      type: "input",
      name: "sessionEndHours",
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "HH",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-0d8931eb-fb9d-4c1e-bb06-c3f54038aacc": {
      uniqueId: "control-0d8931eb-fb9d-4c1e-bb06-c3f54038aacc",
      type: "input",
      name: "sessionEndMinutes",
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "MM",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
    },
    "control-7d7b5e0b-ed6f-47fc-830a-22a6167aab25": {
      uniqueId: "control-7d7b5e0b-ed6f-47fc-830a-22a6167aab25",
      type: "label",
      name: null,
      label: "Programming Goals",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0  no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 mt-4 text-uppercase",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-5d62083d-c844-4a55-bc02-32187c3d79e8": {
      uniqueId: "control-5d62083d-c844-4a55-bc02-32187c3d79e8",
      type: "checkbox",
      name: null,
      label: "Pandemic Precautions Used-RT",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 white-bg border p-0",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 text-primary",
      defaultValue: null,
      validations: [],
      displayMode: "next",
      position: "left",
      items: [
        { value: "Surgical Mask/Cloth Mask", text: "Surgical Mask/Cloth Mask" },
        { value: "N-95 Mask", text: "N-95 Mask" },
        { value: "Gloves", text: "Gloves" },
        { value: "Gown", text: "Gown" },
        { value: "Face Shield", text: "Face Shield" },
        { value: "MOH Self Assessment", text: "MOH Self Assessment" },
        { value: "Hand Washing", text: "Hand Washing" },
        { value: "Social Distancing (6FT)", text: "Social Distancing (6FT)" },
        { value: "Attached Screening", text: "Attached Screening" },
      ],
    },
    "control-4c8ded2e-002e-48a6-8aa5-88a375b2e12a": {
      uniqueId: "control-4c8ded2e-002e-48a6-8aa5-88a375b2e12a",
      type: "checkbox",
      name: null,
      label: "Pandemic Precautions Client",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 white-bg border p-0 sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 text-primary",
      defaultValue: null,
      validations: [],
      displayMode: "next",
      position: "left",
      items: [
        { value: "Surgical Cloth/Mask", text: "Surgical Cloth/Mask" },
        { value: "N95 Mask", text: "N95 Mask" },
        { value: "Gloves", text: "Gloves" },
        { value: "Gown", text: "Gown" },
        { value: "Face Shield", text: "Face Shield" },
        { value: "MOH Self Assessment", text: "MOH Self Assessment" },
        { value: "Hand Washing", text: "Hand Washing" },
        { value: "Social Distancing(6FT)", text: "Social Distancing(6FT)" },
      ],
    },
    "control-649702bb-1831-4b3e-81ea-1395da6fba76": {
      uniqueId: "control-649702bb-1831-4b3e-81ea-1395da6fba76",
      type: "checkbox",
      name: null,
      label: "Location of Meeting",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 white-bg border p-0 sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 text-primary",
      defaultValue: null,
      validations: [],
      displayMode: "next",
      position: "left",
      items: [
        { value: "Clients Home", text: "Clients Home" },
        { value: "Outside", text: "Outside" },
        { value: "Community Location", text: "Community Location" },
        { value: "Clinic/Medical", text: "Clinic/Medical" },
      ],
    },
    "control-be0e8025-1fae-4d4f-a7e6-307ab2f7ae50": {
      uniqueId: "control-be0e8025-1fae-4d4f-a7e6-307ab2f7ae50",
      type: "text",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText:
        "If unable to maintain a distance of 6 Feet/2 metres in session please state why and precautions taken by RT",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up no-resize",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-2fabfd7f-acd8-4b24-b855-6b17a8496518": {
      uniqueId: "control-2fabfd7f-acd8-4b24-b855-6b17a8496518",
      type: "dropDown",
      name: null,
      label: "Rehabilitation Goals 1",
      subLabel:
        "Please choose from the drop down one of the goals pertaining to the session",
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: "mt-4 text-bold",
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "empty", text: null },
        {
          value:
            "Client required full support to plan activity (cueing, generating ideas, breaking down tasks into steps) ( FS)",
          text: "Client required full support to plan activity (cueing, generating ideas, breaking down tasks into steps) ( FS)",
        },
        {
          value:
            "Client required moderate support to plan activity (able to generate ideas, but required RT support with task analysis) (MS)",
          text: "Client required moderate support to plan activity (able to generate ideas, but required RT support with task analysis) (MS)",
        },
        {
          value:
            "Client required minimal support to plan activity (able to generate a plan, but required some cueing and support from RT)(MinS)",
          text: "Client required minimal support to plan activity (able to generate a plan, but required some cueing and support from RT)(MinS)",
        },
        { value: "No support needed", text: "No support needed" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-c1f08862-0b2b-40a1-a98a-e2592193cb46": {
      uniqueId: "control-c1f08862-0b2b-40a1-a98a-e2592193cb46",
      type: "label",
      name: null,
      label: "ACTIVITY OBSERVATIONS",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 pt-4 text-bold",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-d4d64a32-a94a-4704-9a94-badc0f873830": {
      uniqueId: "control-d4d64a32-a94a-4704-9a94-badc0f873830",
      type: "text",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Describe activity/task(s) completed",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius no-resize",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-14ea7fdb-524a-4656-adb4-140928249bff": {
      uniqueId: "control-14ea7fdb-524a-4656-adb4-140928249bff",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Level of Support Required to Plan Activity",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value:
            "Client required full support to plan activity (cueing, generating ideas, breaking down tasks into steps) ( FS)",
          text: "Client required full support to plan activity (cueing, generating ideas, breaking down tasks into steps) ( FS)",
        },
        {
          value:
            "Client required moderate support to plan activity (able to generate ideas, but required RT support with task analysis) (MS)",
          text: "Client required moderate support to plan activity (able to generate ideas, but required RT support with task analysis) (MS)",
        },
        {
          value:
            "Client required minimal support to plan activity (able to generate a plan, but required some cueing and support from RT)(MinS)",
          text: "Client required minimal support to plan activity (able to generate a plan, but required some cueing and support from RT)(MinS)",
        },
        { value: "No support needed", text: "No support needed" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-78a6a842-9eb7-44f0-bd99-a5631d3c0632": {
      uniqueId: "control-78a6a842-9eb7-44f0-bd99-a5631d3c0632",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Prompting Category",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Verbal prompts", text: "Verbal prompts" },
        { value: "Gestural prompts", text: "Gestural prompts" },
        { value: "Indirect prompts", text: "Indirect prompts" },
        {
          value: "Verbal and Gestural prompts",
          text: "Verbal and Gestural prompts",
        },
        {
          value: "Verbal, Gestural, and Indirect prompts",
          text: "Verbal, Gestural, and Indirect prompts",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-6bbd5262-c8f7-4056-a296-61aa4b7f9a75": {
      uniqueId: "control-6bbd5262-c8f7-4056-a296-61aa4b7f9a75",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Number of Prompts",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass:
        "m-0 no-padding no-radius sticky-up sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "1-2 Prompts", text: "1-2 Prompts" },
        { value: "3-5 Prompts", text: "3-5 Prompts" },
        { value: "5-10 Prompts", text: "5-10 Prompts" },
        { value: "10 + Prompts", text: "10 + Prompts" },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-b558f1b1-05d9-4611-b39b-c7684d01cef2": {
      uniqueId: "control-b558f1b1-05d9-4611-b39b-c7684d01cef2",
      type: "radio",
      name: null,
      label:
        "Were any cognitive difficulties noted during activity? If yes, please comment below",
      subLabel: null,
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 pr-0 white-bg border sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 text-primary h5",
      defaultValue: null,
      validations: [],
      displayMode: "next",
      position: "left",
      items: [
        { value: "Yes", text: "Yes" },
        { value: "No", text: "No" },
      ],
    },
    "control-28385e15-938f-4c29-ad0b-031625c3cf13": {
      uniqueId: "control-28385e15-938f-4c29-ad0b-031625c3cf13",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Cognitive difficulties observed during activity",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Memory Difficulties (MD)", text: "Memory Difficulties (MD)" },
        {
          value: "Attention and Concentration Difficulties (A/C)",
          text: "Attention and Concentration Difficulties (A/C)",
        },
        {
          value: "Memory, Attention, Concentration Difficulties (MD/AC)",
          text: "Memory, Attention, Concentration Difficulties (MD/AC)",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-0fa68e30-1b15-4e2b-bb44-411a9751e892": {
      uniqueId: "control-0fa68e30-1b15-4e2b-bb44-411a9751e892",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Cognitive difficulties observed contd.",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass:
        "m-0 no-padding no-radius sticky-up sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value: "Limited Insight into Impairments (LI)",
          text: "Limited Insight into Impairments (LI)",
        },
        {
          value: "Executive Functioning Difficulties (EFD)",
          text: "Executive Functioning Difficulties (EFD)",
        },
        {
          value: "Insight, Executive Functioning Difficulties (LI/EFD)",
          text: "Insight, Executive Functioning Difficulties (LI/EFD)",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-f35559cb-6b89-46f4-918a-3c4b18ddf453": {
      uniqueId: "control-f35559cb-6b89-46f4-918a-3c4b18ddf453",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Cognitive difficulties observed contd.",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass:
        "m-0 no-padding no-radius sticky-up sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value: "Difficulties with Communication",
          text: "Difficulties with Communication",
        },
        {
          value: "Difficulties Picking up on Social Cues",
          text: "Difficulties Picking up on Social Cues",
        },
        {
          value: "Struggled with Social Interactions",
          text: "Struggled with Social Interactions",
        },
        {
          value: "Social/Communication Difficulties",
          text: "Social/Communication Difficulties",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-c065fd5a-8a1f-4d42-a763-5e8171721b4c": {
      uniqueId: "control-c065fd5a-8a1f-4d42-a763-5e8171721b4c",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Level of Engagement",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value: "Fully engaged in activity (required minimal cueing)",
          text: "Fully engaged in activity (required minimal cueing)",
        },
        {
          value: "Somewhat engaged (cueing required throughout activity)",
          text: "Somewhat engaged (cueing required throughout activity)",
        },
        {
          value:
            "Client struggled to stay on task (required consistent cueing)",
          text: "Client struggled to stay on task (required consistent cueing)",
        },
        {
          value: "Client did not engage despite cueing and support",
          text: "Client did not engage despite cueing and support",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-fcafdaf4-7340-4b08-b973-2eed63eef7e2": {
      uniqueId: "control-fcafdaf4-7340-4b08-b973-2eed63eef7e2",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Compensatory Strategies Employed",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass:
        "m-0 no-padding no-radius sticky-up sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value: "Scheduling/Time Management",
          text: "Scheduling/Time Management",
        },
        {
          value: "Organizational Strategies",
          text: "Organizational Strategies",
        },
        {
          value: "To Do list and/or Reminders",
          text: "To Do list and/or Reminders",
        },
        {
          value: "Technology utilized (smartphone, laptop, iPad)",
          text: "Technology utilized (smartphone, laptop, iPad)",
        },
        {
          value: "Cognitive strategies (as directed by RHP)",
          text: "Cognitive strategies (as directed by RHP)",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-79d13bf2-24a1-4af7-bc6e-21ca39fb55e2": {
      uniqueId: "control-79d13bf2-24a1-4af7-bc6e-21ca39fb55e2",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Outcome/Consequence",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value:
            "Client successfully completed task (required cueing and support)(TCmin)",
          text: "Client successfully completed task (required cueing and support)(TCmin)",
        },
        {
          value:
            "Client completed task with full support from the RT (cueing and incorporating breaks) (TCfull)",
          text: "Client completed task with full support from the RT (cueing and incorporating breaks) (TCfull)",
        },
        {
          value: "Client was unable to complete task (TCnc)",
          text: "Client was unable to complete task (TCnc)",
        },
        {
          value:
            "Client declined completing tasks (was unable to initiate, plan and/or execute activity) (Declined)",
          text: "Client declined completing tasks (was unable to initiate, plan and/or execute activity) (Declined)",
        },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-f7360444-86c4-4c56-9328-9d86355c9cbd": {
      uniqueId: "control-f7360444-86c4-4c56-9328-9d86355c9cbd",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Observed Physical Barriers",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value:
            "Client required maximum support and assistance during activity due to difficulties with physical dysfunction (see example)",
          text: "Client required maximum support and assistance during activity due to difficulties with physical dysfunction (see example)",
        },
        {
          value: "Client struggled with maintaining balance",
          text: "Client struggled with maintaining balance",
        },
        {
          value: "Client had difficulties with weight bearing",
          text: "Client had difficulties with weight bearing",
        },
        {
          value:
            "Client required assistance from the RT when navigating uneven terrain",
          text: "Client required assistance from the RT when navigating uneven terrain",
        },
        {
          value:
            "Client struggled with activity/task due to ongoing difficulties with fatigue and physical dysfunction (see general observations)",
          text: "Client struggled with activity/task due to ongoing difficulties with fatigue and physical dysfunction (see general observations)",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-eddacea6-b649-4a3d-b479-ac9919739922": {
      uniqueId: "control-eddacea6-b649-4a3d-b479-ac9919739922",
      type: "text",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "General observations/ Examples",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up no-resize",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-3d966f7a-0906-40b3-af7b-b82c05cafbdd": {
      uniqueId: "control-3d966f7a-0906-40b3-af7b-b82c05cafbdd",
      type: "label",
      name: null,
      label: "Fatigue Observations",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "pt-4 pb-4 m-0 text-uppercase",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-904f46cf-295b-495e-bb85-c66afcae901b": {
      uniqueId: "control-904f46cf-295b-495e-bb85-c66afcae901b",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Fatigue Category",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Physical Fatigue", text: "Physical Fatigue" },
        { value: "Mental Fatigue", text: "Mental Fatigue" },
        { value: "Emotional Fatigue", text: "Emotional Fatigue" },
        {
          value: "Mental and Physical Fatigue",
          text: "Mental and Physical Fatigue",
        },
        {
          value: "Emotional, Physical and Mental Fatigue",
          text: "Emotional, Physical and Mental Fatigue",
        },
        { value: "No fatigue noted", text: "No fatigue noted" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-ea7ad577-dab5-4d2a-9c11-25b6458483d0": {
      uniqueId: "control-ea7ad577-dab5-4d2a-9c11-25b6458483d0",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Level of Fatigue noted",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Extreme Fatigue", text: "Extreme Fatigue" },
        { value: "Moderate Fatigue", text: "Moderate Fatigue" },
        { value: "Mild Fatigue", text: "Mild Fatigue" },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-7b5f31e4-f56e-4b91-9c3c-ff9f9e639f4e": {
      uniqueId: "control-7b5f31e4-f56e-4b91-9c3c-ff9f9e639f4e",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Level of Support Required",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value:
            "Client demonstrated little insight into their fatigue levels, and required maximum support and assistance from RT to complete activity.",
          text: "Client demonstrated little insight into their fatigue levels, and required maximum support and assistance from RT to complete activity.",
        },
        {
          value:
            "Client required a moderate level of support and assistance from the RT to complete/engage in activity",
          text: "Client required a moderate level of support and assistance from the RT to complete/engage in activity",
        },
        {
          value:
            "Client required some cueing and support form the RT to complete/engage in activity",
          text: "Client required some cueing and support form the RT to complete/engage in activity",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-da199831-3f4b-4d4a-b044-4082556d59c3": {
      uniqueId: "control-da199831-3f4b-4d4a-b044-4082556d59c3",
      type: "text",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText:
        "During which activity or task was the client engaged in when fatigue was reported or observed? What strategies were employed.",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "no-padding no-radius no-resize",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-87f54821-1316-43fc-8c3b-940800dfb93d": {
      uniqueId: "control-87f54821-1316-43fc-8c3b-940800dfb93d",
      type: "label",
      name: null,
      label: "Pain Report",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "pt-4 pb-4 m-0 text-uppercase",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-0881f9b4-648e-4ce1-a3e1-43d76b12a817": {
      uniqueId: "control-0881f9b4-648e-4ce1-a3e1-43d76b12a817",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Location of Pain",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "A. Head (Headaches)", text: "A. Head (Headaches)" },
        { value: "B. Back", text: "B. Back" },
        {
          value: "C. Headaches and Back Pain",
          text: "C. Headaches and Back Pain",
        },
        { value: "D. Abdomen", text: "D. Abdomen" },
        { value: "E. N/A", text: "E. N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-d467ce4e-7791-45dd-a56c-d5a8353c7dcb": {
      uniqueId: "control-d467ce4e-7791-45dd-a56c-d5a8353c7dcb",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Location of Pain",
      containerClass: "col-md-6 md-layout-item md-size-50",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "A. Upper Extremity", text: "A. Upper Extremity" },
        { value: "B. Lower Extremity", text: "B. Lower Extremity" },
        {
          value: "C. Both Upper and Lower Extremities",
          text: "C. Both Upper and Lower Extremities",
        },
        { value: "D. Phantom Pain", text: "D. Phantom Pain" },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-c9a6eea6-a602-4251-8be1-b3ec8ac47c6f": {
      uniqueId: "control-c9a6eea6-a602-4251-8be1-b3ec8ac47c6f",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Pain Indicators",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value:
            "Client could not engage in proposed activity due to extreme pain, and required support and assistance with most activities during the session",
          text: "Client could not engage in proposed activity due to extreme pain, and required support and assistance with most activities during the session",
        },
        {
          value:
            "Client was noted to grimace, clutch the area(s) in question, and consistently grunt/moan throughout activity.",
          text: "Client was noted to grimace, clutch the area(s) in question, and consistently grunt/moan throughout activity.",
        },
        {
          value:
            "Client was noted to be in moderate pain, required support cueing from RT to complete/engage in activity",
          text: "Client was noted to be in moderate pain, required support cueing from RT to complete/engage in activity",
        },
        {
          value:
            "Client was noted to have mild pain seen through occasional grimacing and reports of pain.",
          text: "Client was noted to have mild pain seen through occasional grimacing and reports of pain.",
        },
        {
          value: "Client had little insight into pain levels (see example)",
          text: "Client had little insight into pain levels (see example)",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-66d1ea4d-1996-484c-a746-a0e48b947d18": {
      uniqueId: "control-66d1ea4d-1996-484c-a746-a0e48b947d18",
      type: "text",
      name: null,
      label:
        "Specify Body and/or Body Part where pain was reported or observed. What activity was the client engaged in when pain was observed?",
      subLabel:
        "Please indicated the specific body part where pain was reported or observed.",
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "no-padding no-radius no-resize",
      additionalFieldClass: null,
      additionalLabelClass: "pt-2",
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-78248ad4-f4a2-4c7e-b239-ce4069e39752": {
      uniqueId: "control-78248ad4-f4a2-4c7e-b239-ce4069e39752",
      type: "dropDown",
      name: null,
      label: "Please indicate the level of pain reported by the client",
      subLabel:
        "Please ask client during each treatment session about the pain they may be feeling. Pain indicators are located in the drop down choices.",
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: "pt-3",
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "0 No pain", text: "0 No pain" },
        { value: "1 Mild pain", text: "1 Mild pain" },
        { value: "2 Moderate pain", text: "2 Moderate pain" },
        { value: "3 Severe pain", text: "3 Severe pain" },
        { value: "4 Very severe pain", text: "4 Very severe pain" },
        { value: "5 Worst possible pain", text: "5 Worst possible pain" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-047af7e6-8663-46a6-a2af-01d02931314c": {
      uniqueId: "control-047af7e6-8663-46a6-a2af-01d02931314c",
      type: "label",
      name: null,
      label: "Behaviour Observations",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "pt-4 pb-2 m-0 text-uppercase",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-5745ee7d-5d79-4f9c-907e-7c574675917e": {
      uniqueId: "control-5745ee7d-5d79-4f9c-907e-7c574675917e",
      type: "dropDown",
      name: null,
      label: "Negative or Maladaptive Behaviour(s) observed.",
      subLabel: "Select the behaviour observed during the treatment session.",
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "no-padding no-radius no-resize mb-2",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Verbal Aggression", text: "Verbal Aggression" },
        {
          value: "Physical aggression against objects",
          text: "Physical aggression against objects",
        },
        {
          value: "Physical aggression against other people",
          text: "Physical aggression against other people",
        },
        {
          value: "Inappropriate sexual behaviour",
          text: "Inappropriate sexual behaviour",
        },
        {
          value: "Perseveration/repetitive behaviour",
          text: "Perseveration/repetitive behaviour",
        },
        { value: "Wandering/Absconding", text: "Wandering/Absconding" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-a17981ac-0503-4089-8caf-0173f762bbb2": {
      uniqueId: "control-a17981ac-0503-4089-8caf-0173f762bbb2",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Frequency of Behaviour",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Observed 1-2 times", text: "Observed 1-2 times" },
        { value: "Observed 3-5 times", text: "Observed 3-5 times" },
        { value: "Observed 5 or more times", text: "Observed 5 or more times" },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-07e83c18-03c1-4fb7-a8b9-3961ef255082": {
      uniqueId: "control-07e83c18-03c1-4fb7-a8b9-3961ef255082",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Duration of Behaviour",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Brief or up to a minute", text: "Brief or up to a minute" },
        { value: "Between 1-5 minutes", text: "Between 1-5 minutes" },
        { value: "5-10 minutes", text: "5-10 minutes" },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-824e058c-adfd-4ebc-a651-963b4411f414": {
      uniqueId: "control-824e058c-adfd-4ebc-a651-963b4411f414",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Intensity of Behaviour",
      containerClass: "col-md-4 md-layout-item md-size-33",
      additionalContainerClass: "m-0 no-padding no-radius sticky-left",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Slightly disruptive", text: "Slightly disruptive" },
        {
          value: "Disruptive but not dangerous",
          text: "Disruptive but not dangerous",
        },
        { value: "Verbal/Physical threats", text: "Verbal/Physical threats" },
        {
          value: "Destructive to environment",
          text: "Destructive to environment",
        },
        {
          value: "Danger to themselves/others",
          text: "Danger to themselves/others",
        },
        { value: "N/A", text: "N/A" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-2e1835b4-a80f-4025-a017-b3be904cc68d": {
      uniqueId: "control-2e1835b4-a80f-4025-a017-b3be904cc68d",
      type: "text",
      name: null,
      label: "Antecedent/Behaviour/Consequence",
      subLabel:
        "Please describe the events that lead to the selected behaviour, Describe the behaviour itself and the consequence or response to the behaviour.",
      isShowLabel: true,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "no-padding no-radius no-resize",
      additionalFieldClass: null,
      additionalLabelClass: "pt-3 text-bold",
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-e076c1dd-99cc-49c6-9eaf-c15247d5f4bf": {
      uniqueId: "control-e076c1dd-99cc-49c6-9eaf-c15247d5f4bf",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Noted Observations",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value:
            "Difficulties with motivation (not engaging in day to day routine, minimal engagement in rehab related activities)",
          text: "Difficulties with motivation (not engaging in day to day routine, minimal engagement in rehab related activities)",
        },
        {
          value:
            "Observed/reported struggle with sensory stimuli (crowds, lighting, noise)",
          text: "Observed/reported struggle with sensory stimuli (crowds, lighting, noise)",
        },
        {
          value:
            "Observed/reported difficulties with accessing the community (pedestrian, passenger, etc.)",
          text: "Observed/reported difficulties with accessing the community (pedestrian, passenger, etc.)",
        },
        {
          value:
            "Client self-reported difficulties with personal relationships",
          text: "Client self-reported difficulties with personal relationships",
        },
        {
          value:
            "Reported difficulties with maintaining pre-accident family and/or peer relationships (see example)",
          text: "Reported difficulties with maintaining pre-accident family and/or peer relationships (see example)",
        },
        {
          value: "Community safety concerns (see example)",
          text: "Community safety concerns (see example)",
        },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-0f66f708-e680-440f-97b1-67d739aaf73e": {
      uniqueId: "control-0f66f708-e680-440f-97b1-67d739aaf73e",
      type: "label",
      name: null,
      label: "Additional Observations",
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "mt-4 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "m-0",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
    "control-3099bce7-c629-47df-97c6-e7417927351f": {
      uniqueId: "control-3099bce7-c629-47df-97c6-e7417927351f",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Observations Contd.",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-ceb40596-7d8b-4a8d-923f-fa635d6f3780": {
      uniqueId: "control-ceb40596-7d8b-4a8d-923f-fa635d6f3780",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Observations Contd.",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        {
          value:
            "Difficulties with motivation (not engaging in day to day routine, minimal engagement in rehab related activities)",
          text: "Difficulties with motivation (not engaging in day to day routine, minimal engagement in rehab related activities)",
        },
        {
          value:
            "Observed/reported struggle with sensory stimuli (crowds, lighting, noise)",
          text: "Observed/reported struggle with sensory stimuli (crowds, lighting, noise)",
        },
        {
          value:
            "Observed/reported difficulties with accessing the community (pedestrian, passenger, etc.)",
          text: "Observed/reported difficulties with accessing the community (pedestrian, passenger, etc.)",
        },
        {
          value:
            "Client self-reported difficulties with personal relationships",
          text: "Client self-reported difficulties with personal relationships",
        },
        {
          value:
            "Reported difficulties with maintaining pre-accident family and/or peer relationships (see example)",
          text: "Reported difficulties with maintaining pre-accident family and/or peer relationships (see example)",
        },
        {
          value: "Community safety concerns (see example)",
          text: "Community safety concerns (see example)",
        },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-a3cd35cd-ea84-4435-9011-eb8a1f2dbcb8": {
      uniqueId: "control-a3cd35cd-ea84-4435-9011-eb8a1f2dbcb8",
      type: "text",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Example, discussion of noted observation:",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding no-radius sticky-up no-resize",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-b752efcd-e6ac-4b25-b20b-dbf4e9ff27b1": {
      uniqueId: "control-b752efcd-e6ac-4b25-b20b-dbf4e9ff27b1",
      type: "text",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Plan for next session:",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "mt-4 no-padding no-radius no-resize",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      rows: 3,
    },
    "control-683f5ed3-dd70-43be-975b-c4616fb4c8a3": {
      uniqueId: "control-683f5ed3-dd70-43be-975b-c4616fb4c8a3",
      type: "date",
      name: null,
      label: "Date of next session",
      subLabel: null,
      isShowLabel: true,
      placeholderText: "YYYY-MM-DD",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass:
        "no-padding no-radius white-bg border input-border-0 sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 pl-3 pt-1 text-primary",
      defaultValue: null,
      validations: [],
      format: "YYYY-MM-DD",
      firstDay: 1,
      numberOfMonths: 1,
      numberOfColumns: 1,
      minDate: null,
      maxDate: null,
      singleMode: true,
      minDays: 0,
      maxDays: 0,
      returnType: "format",
    },
    "control-e378f13e-1a3f-4c7d-9e37-28882e6c90f0": {
      uniqueId: "control-e378f13e-1a3f-4c7d-9e37-28882e6c90f0",
      type: "dropDown",
      name: null,
      label: null,
      subLabel: null,
      isShowLabel: false,
      placeholderText: "Progress Notes Status",
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "no-padding no-radius sticky-up",
      additionalFieldClass: null,
      additionalLabelClass: null,
      defaultValue: null,
      validations: [],
      dataMode: "list",
      multiple: false,
      items: [
        { value: "Pending Approval", text: "Pending Approval" },
        { value: "Approved", text: "Approved" },
        { value: "Rejected", text: "Rejected" },
      ],
      apiURL: null,
      apiTextKey: "text",
      apiValueKey: "value",
    },
    "control-43f4be41-610b-4cb1-8b76-45bf2093bc1e": {
      uniqueId: "control-43f4be41-610b-4cb1-8b76-45bf2093bc1e",
      type: "label",
      name: null,
      label: 'RSW: set to "Pending Approval", Director: set to "Approved"',
      subLabel: null,
      isShowLabel: false,
      placeholderText: null,
      containerClass: "col-md-12 md-layout-item md-size-100",
      additionalContainerClass: "m-0 no-padding",
      additionalFieldClass: null,
      additionalLabelClass: "p-0 m-0 pt-4",
      defaultValue: null,
      validations: [],
      forAttribute: null,
    },
  },
};

const DEMOES = {
  Demo: DEMO,
  "RT/Progress Note-Pandemic": DEMO2,
  "Behaviour Consultation Clinical Note": {
    formConfig: {
      headline: null,
      subHeadline: null,
      isShowHeadline: false,
      renderFormTag: false,
      formActionURL: null,
      formMethod: "POST",
    },
    sections: {
      "section-b2f350a5-c74e-4a8e-a7e0-46b156135959": {
        uniqueId: "section-b2f350a5-c74e-4a8e-a7e0-46b156135959",
        headline: null,
        headlineAdditionalClass: "no-section",
        subHeadline: null,
        subHeadlineAdditionalClass: "m-0",
        isShowHeadline: true,
        sortOrder: 2,
        type: "normal",
        rows: [],
        controls: [
          "control-29fc157c-37ca-4a21-b808-44737082dc1f",
          "control-f38e8da7-972b-44e7-a063-1f655250a169",
          "control-83005a17-85cf-45f7-970a-47b441909ed5",
          "control-a3ff42ad-9eff-4fa1-8dd3-88d21c3b8d83",
          "control-a5c52346-bbfd-47a1-9cec-57d827283f36",
          "control-371d5cc8-5f75-490a-b880-4cccd166da2e",
          "control-5cc82373-d176-4433-9439-87d70595acc6",
          "control-71fd0530-802c-49db-b772-b7549b2a1628",
          "control-8e346bd5-5de3-4e13-b5c9-2975e5d12c52",
          "control-6c807ce2-d8f8-4703-817c-12c2b7a6ed68",
          "control-9e2d4cd0-2aaf-4184-be75-e8bf6861bfb6",
          "control-224ed853-8473-40ea-a568-2314d88a5b02",
          "control-c18f75e3-50f4-45b7-a641-21a5c6fe39c7",
          "control-d0f5d0fe-6288-4562-9ef2-11f3bc84c9e1",
          "control-3a1ad305-6f91-4708-ab4b-494b4129a1e6",
        ],
      },
      "section-da52f038-bcab-4587-a367-58716776759a": {
        uniqueId: "section-da52f038-bcab-4587-a367-58716776759a",
        headline: null,
        headlineAdditionalClass: null,
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: false,
        sortOrder: 1,
        type: "normal",
        rows: [],
        controls: [
          "control-5643d23d-3fef-4df3-92fd-364fd121783d",
          "control-0c14eb7b-e0b3-4331-b360-e0453021d3b0",
          "control-e9ca3d94-551e-454f-b9d4-1c540a23798d",
          "control-987375bc-7bf6-4094-8dfe-3ffda7db9c37",
        ],
      },
    },
    rows: {},
    controls: {
      "control-0c14eb7b-e0b3-4331-b360-e0453021d3b0": {
        uniqueId: "control-0c14eb7b-e0b3-4331-b360-e0453021d3b0",
        type: "input",
        name: "patientFirstName",
        label: "First Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-224ed853-8473-40ea-a568-2314d88a5b02": {
        uniqueId: "control-224ed853-8473-40ea-a568-2314d88a5b02",
        type: "label",
        name: null,
        label: "Plan:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "mt-0 mb-2 no-padding border-bottom-bold",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 pt-4 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-29fc157c-37ca-4a21-b808-44737082dc1f": {
        uniqueId: "control-29fc157c-37ca-4a21-b808-44737082dc1f",
        type: "date",
        name: "sessionStartDate",
        label: "Date of Session",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-371d5cc8-5f75-490a-b880-4cccd166da2e": {
        uniqueId: "control-371d5cc8-5f75-490a-b880-4cccd166da2e",
        type: "label",
        name: null,
        label: "Topics of Discussion:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "mt-0 mb-2 no-padding border-bottom-bold",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 pt-4 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-3a1ad305-6f91-4708-ab4b-494b4129a1e6": {
        uniqueId: "control-3a1ad305-6f91-4708-ab4b-494b4129a1e6",
        type: "text",
        name: null,
        label: "Behaviour Consultant Signature",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 12,
      },
      "control-5643d23d-3fef-4df3-92fd-364fd121783d": {
        uniqueId: "control-5643d23d-3fef-4df3-92fd-364fd121783d",
        type: "label",
        name: null,
        label: "CLIENT NAME:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-5cc82373-d176-4433-9439-87d70595acc6": {
        uniqueId: "control-5cc82373-d176-4433-9439-87d70595acc6",
        type: "text",
        name: "topicsOfDiscussion",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-resize",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-6c807ce2-d8f8-4703-817c-12c2b7a6ed68": {
        uniqueId: "control-6c807ce2-d8f8-4703-817c-12c2b7a6ed68",
        type: "label",
        name: null,
        label: "Recommendations:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "mt-0 mb-2 no-padding border-bottom-bold",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 pt-4 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-71fd0530-802c-49db-b772-b7549b2a1628": {
        uniqueId: "control-71fd0530-802c-49db-b772-b7549b2a1628",
        type: "label",
        name: null,
        label: "Assessment:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "mt-0 mb-2 no-padding border-bottom-bold",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 pt-4 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-83005a17-85cf-45f7-970a-47b441909ed5": {
        uniqueId: "control-83005a17-85cf-45f7-970a-47b441909ed5",
        type: "input",
        name: "sessionEndTime",
        label: "Session End Time:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-8e346bd5-5de3-4e13-b5c9-2975e5d12c52": {
        uniqueId: "control-8e346bd5-5de3-4e13-b5c9-2975e5d12c52",
        type: "text",
        name: "assessment",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-resize",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-987375bc-7bf6-4094-8dfe-3ffda7db9c37": {
        uniqueId: "control-987375bc-7bf6-4094-8dfe-3ffda7db9c37",
        type: "dropDown",
        name: "behaviourConsultant",
        label: "Behaviour Consultant:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 h-30 p-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-9e2d4cd0-2aaf-4184-be75-e8bf6861bfb6": {
        uniqueId: "control-9e2d4cd0-2aaf-4184-be75-e8bf6861bfb6",
        type: "text",
        name: "recommendations",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-resize",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-a3ff42ad-9eff-4fa1-8dd3-88d21c3b8d83": {
        uniqueId: "control-a3ff42ad-9eff-4fa1-8dd3-88d21c3b8d83",
        type: "input",
        name: "sessionAttendees",
        label: "SESSION ATTENDEES:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-radius no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-a5c52346-bbfd-47a1-9cec-57d827283f36": {
        uniqueId: "control-a5c52346-bbfd-47a1-9cec-57d827283f36",
        type: "radio",
        name: "sessionType",
        label: "How was this session Conducted:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "white-bg sticky-up border pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "m-0",
        defaultValue: null,
        validations: [],
        displayMode: "line",
        position: "left",
        items: [
          { text: "Virtual", value: "VIRTUAL" },
          { text: "In-Person", value: "IN-PERSON" },
        ],
      },
      "control-c18f75e3-50f4-45b7-a641-21a5c6fe39c7": {
        uniqueId: "control-c18f75e3-50f4-45b7-a641-21a5c6fe39c7",
        type: "text",
        name: "plan",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-resize",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-d0f5d0fe-6288-4562-9ef2-11f3bc84c9e1": {
        uniqueId: "control-d0f5d0fe-6288-4562-9ef2-11f3bc84c9e1",
        type: "date",
        name: "sessionNextDate",
        label: "Date of Next Session:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-e9ca3d94-551e-454f-b9d4-1c540a23798d": {
        uniqueId: "control-e9ca3d94-551e-454f-b9d4-1c540a23798d",
        type: "input",
        name: "patientLastName",
        label: "Last Name:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-f38e8da7-972b-44e7-a063-1f655250a169": {
        uniqueId: "control-f38e8da7-972b-44e7-a063-1f655250a169",
        type: "input",
        name: "sessionStartTime",
        label: "Session Start Time:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
    },
  },
  "BTA Note": {
    formConfig: {
      headline: null,
      subHeadline: null,
      isShowHeadline: false,
      renderFormTag: false,
      formActionURL: null,
      formMethod: "POST",
    },
    sections: {
      "section-icxpsqtuyuuhjcsmczhnpwlkielznovnagjslrjogojpfnxypj": {
        uniqueId: "section-icxpsqtuyuuhjcsmczhnpwlkielznovnagjslrjogojpfnxypj",
        headline: null,
        headlineAdditionalClass: "no-section",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: false,
        sortOrder: 4,
        type: "normal",
        rows: [],
        controls: [
          "control-dryhsiumjprrjgkrycyoadixdqprlpmuzabqpbrdyijlojcpax",
          "control-ojhofyzhsaccouuzdounniroebnaybziihotrvbynbaeklscem",
          "control-yscxlkqpkkxyulccalrctvclwwqtnjqjvnidahaxcmggjpxxdh",
          "control-knnmihrcuoyybfzxwgcgsrqewdkukvucqmhvxeshqymryioaio",
          "control-thdwlbnslhhgxwnsucuplybqkljdctmrwdtyrvehufajfpkbui",
          "control-fesaqngdcfsipnnvymlgypenmsgfqzmlnufgyqfpxjactiodou",
          "control-ocokkmihdqxdbsyebfpsbngacsqiwdlyrjnblyberboiyqzirt",
          "control-mjknluzbhhvurbzabslgpndmmegmhsiaqbmqwqdhtrfswyjfwu",
          "control-bwuqukusmpcpufqjqmzvrmqfqylqovcstmyderiekpoydfcywt",
          "control-twpdemtnsycztsntxyyboghfucmaankkborgzcqzmkgjmvkykc",
          "control-hgivnzsbmkcjmxuxccbtfyvqdqpabilyyajttcasyqxecqhjmy",
          "control-pprozzesobfqwsmqzvmqsflhqgcanrkeniwlitfrgaqfrfcgsj",
          "control-pnthtobdwonnxadnlsnthekvxjmxzwolieppxevybjqhcwwkff",
          "control-0b2c96d7-e7b4-4241-8303-c283da19d281",
          "control-36a58ab5-6a00-4591-b214-be05d4ac26d5",
          "control-943adb05-785b-49b7-8808-78433d713e30",
        ],
      },
      "section-nmqsttnfuxjzxjfeusvcdwqhqoltwscwhtxjiqlxqzxwmhfmer": {
        uniqueId: "section-nmqsttnfuxjzxjfeusvcdwqhqoltwscwhtxjiqlxqzxwmhfmer",
        headline: null,
        headlineAdditionalClass: null,
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: false,
        sortOrder: 1,
        type: "normal",
        rows: [],
        controls: [
          "control-lfzjoiissgvbdhbhzcadiqzrroxrcqdylpmcidthjfzdlkairf",
          "control-rbanvpkfdvervrgbukbuhiaoxieyifmgbzztnxrlrcxhrsdxok",
          "control-mguyukvamoiyojzbyvubzzifttmwhmqevxjmwgxfyincqtvxcv",
          "control-f850d1e0-3428-4759-a9c1-417e247da84b",
          "control-50b09646-280d-46b2-aa27-e906e6b38522",
        ],
      },
      "section-pjbqxsntzeadthmjvhydrmnybxdaaclbutstwdudyjvgreynpy": {
        uniqueId: "section-pjbqxsntzeadthmjvhydrmnybxdaaclbutstwdudyjvgreynpy",
        headline: null,
        headlineAdditionalClass: "no-section",
        subHeadline: "This is the sub-headline of the new section",
        subHeadlineAdditionalClass: null,
        isShowHeadline: false,
        sortOrder: 5,
        type: "normal",
        rows: [],
        controls: [
          "control-aymghbddgfcirxpijuskqyupzaumlrgmihqpgelfaxriozzjty",
          "control-mghsbnhfmpjcdkalbnlumuqavmxjxrxtoovnbjtxklshgygcfb",
          "control-mbiyxgzvxpcdnbeitpiuxddkpyvhpoqhuyjvacdodmnldlkefm",
          "control-xmkghymoqlmpxccebakqhbdytjebzzkpijihixbnvpeofotkgo",
          "control-vsyukcmkocnlomtctpgfztvdntmnzfdjhiycwroshezzefeqrk",
          "control-c8920b25-2539-4d1e-8032-8ba89ffe09e5",
          "control-c283610b-7401-4c1b-bf6f-1b0bc984acd0",
          "control-285431bc-bef7-4a0d-859a-60dfd13746ef",
          "control-33db13f4-8945-4231-b185-e9ae1f91870a",
        ],
      },
      "section-togafthtidccgwrtzzivwkadigjudboecogukqvhrcjirvnnfz": {
        uniqueId: "section-togafthtidccgwrtzzivwkadigjudboecogukqvhrcjirvnnfz",
        headline: null,
        headlineAdditionalClass: null,
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: false,
        sortOrder: 2,
        type: "normal",
        rows: [],
        controls: [
          "control-uvecrfobcgpuwtyjioqisxavfmevvtistwrwqrhtfbotpdhfci",
          "control-botzktihaortthyccgqlkezyzajovzufcjttnjxbkdzctkekpj",
          "control-3398e48b-417a-41e5-9b22-699b47a0efd4",
          "control-ac69e643-56d4-4e52-8154-837442035516",
          "control-9175748b-ce12-4b5f-a7a4-ba78170161e9",
          "control-ca0eb531-fc7c-47b0-b501-f57411c5c05a",
          "control-574ec8bc-4cd3-464c-9422-bf0363ce98d3",
          "control-9a3e8210-6d78-45b5-b461-5310bbbb0f24",
          "control-2c342b67-b3b3-4fa5-a830-8bbc1018729c",
          "control-f75ae9eb-7051-406b-88e9-d4bdaa270bc8",
          "control-952445e9-82b3-4b9b-a928-735d48d1cb65",
          "control-e4574160-94c2-4ef4-ab9e-0b76d8e4f2a2",
          "control-22885751-0325-477b-b4e6-78d0742ecb80",
          "control-c8d4fdeb-16e7-43e1-8f44-737d487fd856",
          "control-a18973f7-f980-487a-89f5-8718a7521cc1",
          "control-46bd7a1e-0b02-42a1-b7af-96341f64dc1d",
          "control-9e6ef641-c8e4-4133-8579-ce9e1fd20521",
          "control-e25487b0-6106-4d7f-a8b9-065f895ee5a5",
          "control-0aa05658-5678-42bb-b8cb-215c212195f5",
        ],
      },
      "section-wyecamnhgsbhwovpgvalbjsulzkdbmpdxvozjdfutqhegmnilu": {
        uniqueId: "section-wyecamnhgsbhwovpgvalbjsulzkdbmpdxvozjdfutqhegmnilu",
        headline: null,
        headlineAdditionalClass: "no-section",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 3,
        type: "normal",
        rows: [],
        controls: [
          "control-rptfyakiksjaqphisozyakgetgnnqbzxrohmqiodousspnodjz",
          "control-barlhpbyxrhfvsuazsmhvuuhpawywmxhwchwngimofeqoolgla",
          "control-eszmurborvzhabmxyazjvwzzzenbcmvqlcdbjkinakqocjblxd",
          "control-beyijyzcebbodmcuigwnyfwvxyhbkysfaardujohglucyxwzsb",
          "control-aajnjyoxxcwaxqijunptsscceufygevlfxsliczwbqgqnmvveb",
          "control-nejgfyqutjgfrwpgnatrgymzgbrnwzydhjprmbgzxgmlqvugyl",
          "control-ddcaposhtuxlecyupgxbyjgisdmyyshuyoittfpnnquouphmyk",
          "control-jhtasilmdwpkrbtxykpkjakslzcjddrscxmdousgyddapshiec",
          "control-ifpveqaqdqcggzrlstkaeacejfgjtvvwfpdpjwwydqkzbpzsgb",
          "control-qylpyhpoxdtbiliziqriyfcnktcpvkcahyehqgkxryyazpdiqz",
          "control-fpdiuugkmqparerftomrtxklnxmyymkchfmokowkhiisqkmxdc",
          "control-azjoeybqaabllltoyffwvxanwwtzmrhtrlkutpkqppbmecvkql",
          "control-ymgbvroxpwqtzzzvwonkroglixcsnooaznkfinjiahwzrzwmyu",
        ],
      },
    },
    rows: {},
    controls: {
      "control-0aa05658-5678-42bb-b8cb-215c212195f5": {
        uniqueId: "control-0aa05658-5678-42bb-b8cb-215c212195f5",
        type: "label",
        name: null,
        label: "BEHAVIOUR OBSERVATIONS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "pt-4 mb-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-0b2c96d7-e7b4-4241-8303-c283da19d281": {
        uniqueId: "control-0b2c96d7-e7b4-4241-8303-c283da19d281",
        type: "label",
        name: null,
        label: "FATIGUE OBSERVATIONS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-22885751-0325-477b-b4e6-78d0742ecb80": {
        uniqueId: "control-22885751-0325-477b-b4e6-78d0742ecb80",
        type: "label",
        name: null,
        label: "ACTIVITY OBSERVATIONS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "pt-3 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-285431bc-bef7-4a0d-859a-60dfd13746ef": {
        uniqueId: "control-285431bc-bef7-4a0d-859a-60dfd13746ef",
        type: "label",
        name: null,
        label:
          'BTA: set to "Pending Approval", File Supervisor: set to "Approved"',
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-2c342b67-b3b3-4fa5-a830-8bbc1018729c": {
        uniqueId: "control-2c342b67-b3b3-4fa5-a830-8bbc1018729c",
        type: "label",
        name: null,
        label: "Session End Time",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-3398e48b-417a-41e5-9b22-699b47a0efd4": {
        uniqueId: "control-3398e48b-417a-41e5-9b22-699b47a0efd4",
        type: "dropDown",
        name: null,
        label: "Preferred BTA",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [{ text: "Spencer, Stuart", value: "Spencer, Stuart" }],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-33db13f4-8945-4231-b185-e9ae1f91870a": {
        uniqueId: "control-33db13f4-8945-4231-b185-e9ae1f91870a",
        type: "dropDown",
        name: null,
        label: "Progress Notes Status",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 m-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [
          { text: "Pending Approval", value: "Pending Approval" },
          { text: "Approved", value: "Approved" },
          { text: "Rejected", value: "Rejected" },
        ],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-36a58ab5-6a00-4591-b214-be05d4ac26d5": {
        uniqueId: "control-36a58ab5-6a00-4591-b214-be05d4ac26d5",
        type: "dropDown",
        name: null,
        label: "Fatigue Category",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 m-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-46bd7a1e-0b02-42a1-b7af-96341f64dc1d": {
        uniqueId: "control-46bd7a1e-0b02-42a1-b7af-96341f64dc1d",
        type: "label",
        name: null,
        label: null,
        subLabel:
          "Please choose from the drop down one of the goals pertaining to the session",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-50b09646-280d-46b2-aa27-e906e6b38522": {
        uniqueId: "control-50b09646-280d-46b2-aa27-e906e6b38522",
        type: "input",
        name: null,
        label: "Approved BTA Mileage/Destination",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-574ec8bc-4cd3-464c-9422-bf0363ce98d3": {
        uniqueId: "control-574ec8bc-4cd3-464c-9422-bf0363ce98d3",
        type: "input",
        name: "sessionStartMinutes",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: "MM",
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass: "m-0 no-padding no-radius sticky-left",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-9175748b-ce12-4b5f-a7a4-ba78170161e9": {
        uniqueId: "control-9175748b-ce12-4b5f-a7a4-ba78170161e9",
        type: "label",
        name: null,
        label: "Session Start Time",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-943adb05-785b-49b7-8808-78433d713e30": {
        uniqueId: "control-943adb05-785b-49b7-8808-78433d713e30",
        type: "dropDown",
        name: null,
        label: "Level of Fatigue noted",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 m-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-952445e9-82b3-4b9b-a928-735d48d1cb65": {
        uniqueId: "control-952445e9-82b3-4b9b-a928-735d48d1cb65",
        type: "input",
        name: "sessionEndMinutes",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: "MM",
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass: "m-0 no-padding no-radius sticky-left",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-9a3e8210-6d78-45b5-b461-5310bbbb0f24": {
        uniqueId: "control-9a3e8210-6d78-45b5-b461-5310bbbb0f24",
        type: "dropDown",
        name: "sessionStartAMPM",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: "AM/PM",
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass: "m-0 no-padding no-radius sticky-left2",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [
          { text: "AM", value: "AM" },
          { text: "PM", value: "PM" },
        ],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-9e6ef641-c8e4-4133-8579-ce9e1fd20521": {
        uniqueId: "control-9e6ef641-c8e4-4133-8579-ce9e1fd20521",
        type: "dropDown",
        name: null,
        label: "Activity Category:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-a18973f7-f980-487a-89f5-8718a7521cc1": {
        uniqueId: "control-a18973f7-f980-487a-89f5-8718a7521cc1",
        type: "label",
        name: null,
        label: "BTA Programming Goals:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ac69e643-56d4-4e52-8154-837442035516": {
        uniqueId: "control-ac69e643-56d4-4e52-8154-837442035516",
        type: "date",
        name: "sessionStartDate",
        label: "Session Date",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-c283610b-7401-4c1b-bf6f-1b0bc984acd0": {
        uniqueId: "control-c283610b-7401-4c1b-bf6f-1b0bc984acd0",
        type: "date",
        name: null,
        label: "Date of next session",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-c8920b25-2539-4d1e-8032-8ba89ffe09e5": {
        uniqueId: "control-c8920b25-2539-4d1e-8032-8ba89ffe09e5",
        type: "text",
        name: null,
        label: "Plan for next session:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize mt-4",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-c8d4fdeb-16e7-43e1-8f44-737d487fd856": {
        uniqueId: "control-c8d4fdeb-16e7-43e1-8f44-737d487fd856",
        type: "label",
        name: null,
        label: "PROGRAMMING GOALS AS DIRECTED BY BEHAVIOUR CONSULTANT:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "pt-2",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ca0eb531-fc7c-47b0-b501-f57411c5c05a": {
        uniqueId: "control-ca0eb531-fc7c-47b0-b501-f57411c5c05a",
        type: "input",
        name: "sessionStartHours",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: "HH",
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass: "m-0 no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-e25487b0-6106-4d7f-a8b9-065f895ee5a5": {
        uniqueId: "control-e25487b0-6106-4d7f-a8b9-065f895ee5a5",
        type: "text",
        name: null,
        label: "Activity Completed During Session:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-e4574160-94c2-4ef4-ab9e-0b76d8e4f2a2": {
        uniqueId: "control-e4574160-94c2-4ef4-ab9e-0b76d8e4f2a2",
        type: "dropDown",
        name: "sessionEndAMPM",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: "AM/PM",
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass: "m-0 no-padding no-radius sticky-left2",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [
          { text: "AM", value: "AM" },
          { text: "PM", value: "PM" },
        ],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-f75ae9eb-7051-406b-88e9-d4bdaa270bc8": {
        uniqueId: "control-f75ae9eb-7051-406b-88e9-d4bdaa270bc8",
        type: "input",
        name: "sessionEndHours",
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: "HH",
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass: "m-0 no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-f850d1e0-3428-4759-a9c1-417e247da84b": {
        uniqueId: "control-f850d1e0-3428-4759-a9c1-417e247da84b",
        type: "input",
        name: null,
        label: "Approved Stipend Expense/Activity Completed:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-aajnjyoxxcwaxqijunptsscceufygevlfxsliczwbqgqnmvveb": {
        uniqueId: "control-aajnjyoxxcwaxqijunptsscceufygevlfxsliczwbqgqnmvveb",
        type: "dropDown",
        name: null,
        label: "Duration of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-abkkrxmiozneyuvalylasrnkrukmegoobhxhswxhvlkaihonkl": {
        uniqueId: "control-abkkrxmiozneyuvalylasrnkrukmegoobhxhswxhvlkaihonkl",
        type: "dropDown",
        name: null,
        label: "Number of Prompts or Need for Assistance:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-asdqovjuddkuhoqpjrswhhpdtalqoyxbwhuxlnctvzlwhhyexs": {
        uniqueId: "control-asdqovjuddkuhoqpjrswhhpdtalqoyxbwhuxlnctvzlwhhyexs",
        type: "label",
        name: null,
        label: null,
        subLabel: "Select the behaviour observed during the treatment session.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-axbixwkptkxihphdyjarghvwyfzmvhadkewmqmebiewhvrqdns": {
        uniqueId: "control-axbixwkptkxihphdyjarghvwyfzmvhadkewmqmebiewhvrqdns",
        type: "label",
        name: null,
        label: "Negative or Maladaptive Behaviour(s) observed.",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "text-bold mb-0 under-line",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-aymghbddgfcirxpijuskqyupzaumlrgmihqpgelfaxriozzjty": {
        uniqueId: "control-aymghbddgfcirxpijuskqyupzaumlrgmihqpgelfaxriozzjty",
        type: "label",
        name: null,
        label: "Additional Observations",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "text-bold mb-0 under-line",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-azjoeybqaabllltoyffwvxanwwtzmrhtrlkutpkqppbmecvkql": {
        uniqueId: "control-azjoeybqaabllltoyffwvxanwwtzmrhtrlkutpkqppbmecvkql",
        type: "dropDown",
        name: null,
        label: "Supportive Prompting Category:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-barlhpbyxrhfvsuazsmhvuuhpawywmxhwchwngimofeqoolgla": {
        uniqueId: "control-barlhpbyxrhfvsuazsmhvuuhpawywmxhwchwngimofeqoolgla",
        type: "label",
        name: null,
        label: null,
        subLabel: "Select the behaviour observed during the treatment session.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-bbubvozrewgaecupsfyokdmwoglspumlqqkqkfjqofcyqgjebs": {
        uniqueId: "control-bbubvozrewgaecupsfyokdmwoglspumlqqkqkfjqofcyqgjebs",
        type: "text",
        name: null,
        label: "BEHAVIOUR:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-beyijyzcebbodmcuigwnyfwvxyhbkysfaardujohglucyxwzsb": {
        uniqueId: "control-beyijyzcebbodmcuigwnyfwvxyhbkysfaardujohglucyxwzsb",
        type: "dropDown",
        name: null,
        label: "Frequency of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-botzktihaortthyccgqlkezyzajovzufcjttnjxbkdzctkekpj": {
        uniqueId: "control-botzktihaortthyccgqlkezyzajovzufcjttnjxbkdzctkekpj",
        type: "input",
        name: null,
        label: "Supervising BCBA:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-btlugprzmggtvtbqebjmagmdeuexscwmguhcqmblescrdthjnt": {
        uniqueId: "control-btlugprzmggtvtbqebjmagmdeuexscwmguhcqmblescrdthjnt",
        type: "dropDown",
        name: null,
        label: "Intensity of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-bwuqukusmpcpufqjqmzvrmqfqylqovcstmyderiekpoydfcywt": {
        uniqueId: "control-bwuqukusmpcpufqjqmzvrmqfqylqovcstmyderiekpoydfcywt",
        type: "text",
        name: null,
        label: "ANTECEDENT:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-comxulcxupmpqsfldayiajemnepvaflibvngcmhvnfoenrkbek": {
        uniqueId: "control-comxulcxupmpqsfldayiajemnepvaflibvngcmhvnfoenrkbek",
        type: "label",
        name: null,
        label: null,
        subLabel: "Select the behaviour observed during the treatment session.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-czfqljtnzomqyrutacmfuogszvjbtkvofimxqwutrfakfuasvc": {
        uniqueId: "control-czfqljtnzomqyrutacmfuogszvjbtkvofimxqwutrfakfuasvc",
        type: "dropDown",
        name: null,
        label: "Frequency of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-ddcaposhtuxlecyupgxbyjgisdmyyshuyoittfpnnquouphmyk": {
        uniqueId: "control-ddcaposhtuxlecyupgxbyjgisdmyyshuyoittfpnnquouphmyk",
        type: "label",
        name: null,
        label: "Antecedent/Behaviour/Consequence",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-drexfuddaroiealomopswxnvtxmkkbamqcywqzaysonzvbasdr": {
        uniqueId: "control-drexfuddaroiealomopswxnvtxmkkbamqcywqzaysonzvbasdr",
        type: "label",
        name: null,
        label: "Negative or Maladaptive Behaviour(s) observed.",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "text-bold mb-0 under-line",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-dryhsiumjprrjgkrycyoadixdqprlpmuzabqpbrdyijlojcpax": {
        uniqueId: "control-dryhsiumjprrjgkrycyoadixdqprlpmuzabqpbrdyijlojcpax",
        type: "label",
        name: null,
        label: "Negative or Maladaptive Behaviour(s) observed 2",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "text-bold mb-0 under-line",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-easedfsmuiktlrtqsojxuyzurpusxumcmbtbwjxcqfhyvvtpbc": {
        uniqueId: "control-easedfsmuiktlrtqsojxuyzurpusxumcmbtbwjxcqfhyvvtpbc",
        type: "label",
        name: null,
        label: "Antecedent/Behaviour/Consequence",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ebqzenlkexnrfbeadrqshynauykwqdzecgyzsxbjzykviultah": {
        uniqueId: "control-ebqzenlkexnrfbeadrqshynauykwqdzecgyzsxbjzykviultah",
        type: "dropDown",
        name: null,
        label: "Supportive Prompting Category:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-efvzfbmxtiohytdgqdsmiiaraunmnbgzduzzskebotnbzsacoz": {
        uniqueId: "control-efvzfbmxtiohytdgqdsmiiaraunmnbgzduzzskebotnbzsacoz",
        type: "dropDown",
        name: null,
        label: "Duration of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-eglotbdvgvaykaktffcuaqjkijefrssjnfjdufiyleiaeyrsxd": {
        uniqueId: "control-eglotbdvgvaykaktffcuaqjkijefrssjnfjdufiyleiaeyrsxd",
        type: "text",
        name: null,
        label: "ANTECEDENT:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-eszmurborvzhabmxyazjvwzzzenbcmvqlcdbjkinakqocjblxd": {
        uniqueId: "control-eszmurborvzhabmxyazjvwzzzenbcmvqlcdbjkinakqocjblxd",
        type: "dropDown",
        name: null,
        label: "Category of Behaviour(s) observed:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-euzyxmpkwnpbszqdjxsozdcnnpxininrsemuhomhqqfdoltqgg": {
        uniqueId: "control-euzyxmpkwnpbszqdjxsozdcnnpxininrsemuhomhqqfdoltqgg",
        type: "dropDown",
        name: null,
        label: "Duration of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-fesaqngdcfsipnnvymlgypenmsgfqzmlnufgyqfpxjactiodou": {
        uniqueId: "control-fesaqngdcfsipnnvymlgypenmsgfqzmlnufgyqfpxjactiodou",
        type: "dropDown",
        name: null,
        label: "Intensity of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-fpdiuugkmqparerftomrtxklnxmyymkchfmokowkhiisqkmxdc": {
        uniqueId: "control-fpdiuugkmqparerftomrtxklnxmyymkchfmokowkhiisqkmxdc",
        type: "text",
        name: null,
        label: "CONSEQUENCE:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-fprscthehdalhydciqjttnouflilltfdshqodidrefkhbxokoq": {
        uniqueId: "control-fprscthehdalhydciqjttnouflilltfdshqodidrefkhbxokoq",
        type: "label",
        name: null,
        label: "Antecedent/Behaviour/Consequence",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-fxzwclhknjopgptqgzlflxmznllxfofpdthgdnqtsblrqsooko": {
        uniqueId: "control-fxzwclhknjopgptqgzlflxmznllxfofpdthgdnqtsblrqsooko",
        type: "text",
        name: null,
        label: "BEHAVIOUR:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-gedssvpydinyrrypbijljutpirdflgplunsmpzwcsmpnevgbnv": {
        uniqueId: "control-gedssvpydinyrrypbijljutpirdflgplunsmpzwcsmpnevgbnv",
        type: "dropDown",
        name: null,
        label: "Frequency of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-gqfrwoyrixtxcbmoeqffqhdfdlhbuvjagpjsrztkrvuhbsrhjt": {
        uniqueId: "control-gqfrwoyrixtxcbmoeqffqhdfdlhbuvjagpjsrztkrvuhbsrhjt",
        type: "label",
        name: null,
        label: "Antecedent/Behaviour/Consequence",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-grgmcssivuieobnbwchviwqrlvskuxsaeixjxletcxkcohgoyj": {
        uniqueId: "control-grgmcssivuieobnbwchviwqrlvskuxsaeixjxletcxkcohgoyj",
        type: "text",
        name: null,
        label: "CONSEQUENCE:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-gtolgolhrwwoabjxlzitscnrkaeubmicvdcrftmjwwcoazgrum": {
        uniqueId: "control-gtolgolhrwwoabjxlzitscnrkaeubmicvdcrftmjwwcoazgrum",
        type: "dropDown",
        name: null,
        label: "Intensity of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-hgivnzsbmkcjmxuxccbtfyvqdqpabilyyajttcasyqxecqhjmy": {
        uniqueId: "control-hgivnzsbmkcjmxuxccbtfyvqdqpabilyyajttcasyqxecqhjmy",
        type: "text",
        name: null,
        label: "CONSEQUENCE:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-hqgnvkiqdiianvoeztuqbpmgopgkpcvggyubvigprkttqxqaou": {
        uniqueId: "control-hqgnvkiqdiianvoeztuqbpmgopgkpcvggyubvigprkttqxqaou",
        type: "text",
        name: null,
        label: "CONSEQUENCE:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-ifpveqaqdqcggzrlstkaeacejfgjtvvwfpdpjwwydqkzbpzsgb": {
        uniqueId: "control-ifpveqaqdqcggzrlstkaeacejfgjtvvwfpdpjwwydqkzbpzsgb",
        type: "text",
        name: null,
        label: "ANTECEDENT:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-imgjcantgtvrdsbinmgviwddcfyufgudhipnjrafuayffohanl": {
        uniqueId: "control-imgjcantgtvrdsbinmgviwddcfyufgudhipnjrafuayffohanl",
        type: "label",
        name: null,
        label: "Antecedent/Behaviour/Consequence",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-inlzmwozqmnnowlybshtywjwsexmzyjfrkijivvbxrxtfczjmr": {
        uniqueId: "control-inlzmwozqmnnowlybshtywjwsexmzyjfrkijivvbxrxtfczjmr",
        type: "dropDown",
        name: null,
        label: "Category of Behaviour(s) observed:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-iqpyhpyyvgmjfpjcgurfrbzcaxqfqgilfascfzyfidadjktpnp": {
        uniqueId: "control-iqpyhpyyvgmjfpjcgurfrbzcaxqfqgilfascfzyfidadjktpnp",
        type: "dropDown",
        name: null,
        label: "Frequency of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-jcdntkfjjgntmnpnkwxpuuxixnmufpgadcqlrkhqtgyytonnhe": {
        uniqueId: "control-jcdntkfjjgntmnpnkwxpuuxixnmufpgadcqlrkhqtgyytonnhe",
        type: "text",
        name: null,
        label: "ANTECEDENT:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-jhtasilmdwpkrbtxykpkjakslzcjddrscxmdousgyddapshiec": {
        uniqueId: "control-jhtasilmdwpkrbtxykpkjakslzcjddrscxmdousgyddapshiec",
        type: "label",
        name: null,
        label: null,
        subLabel:
          "Please describe the events that lead to the selected behaviour, Describe the behaviour itself and the consequence or response to the behaviour.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-kjnhwapsrcczfsjxpmlsekfmbfeaiunzwpexpcmtckfnrpfiur": {
        uniqueId: "control-kjnhwapsrcczfsjxpmlsekfmbfeaiunzwpexpcmtckfnrpfiur",
        type: "text",
        name: null,
        label: "CONSEQUENCE:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-knnmihrcuoyybfzxwgcgsrqewdkukvucqmhvxeshqymryioaio": {
        uniqueId: "control-knnmihrcuoyybfzxwgcgsrqewdkukvucqmhvxeshqymryioaio",
        type: "dropDown",
        name: null,
        label: "Frequency of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-lfzjoiissgvbdhbhzcadiqzrroxrcqdylpmcidthjfzdlkairf": {
        uniqueId: "control-lfzjoiissgvbdhbhzcadiqzrroxrcqdylpmcidthjfzdlkairf",
        type: "label",
        name: null,
        label: "CLIENT NAME:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-lhrhsrynevpizziimzjupjdozhjwfuewujbcexwmsndjazcjgv": {
        uniqueId: "control-lhrhsrynevpizziimzjupjdozhjwfuewujbcexwmsndjazcjgv",
        type: "text",
        name: null,
        label: "ANTECEDENT:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-lpnguhsuzgeigemfglmgloeqwzilyfbreqxblojnfhvkauhpbf": {
        uniqueId: "control-lpnguhsuzgeigemfglmgloeqwzilyfbreqxblojnfhvkauhpbf",
        type: "dropDown",
        name: null,
        label: "Supportive Prompting Category:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-lxvtworfbxwkopusihyihbvzvqigtezoikrlbeauksvuirvysz": {
        uniqueId: "control-lxvtworfbxwkopusihyihbvzvqigtezoikrlbeauksvuirvysz",
        type: "dropDown",
        name: null,
        label: "Intensity of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-mbiyxgzvxpcdnbeitpiuxddkpyvhpoqhuyjvacdodmnldlkefm": {
        uniqueId: "control-mbiyxgzvxpcdnbeitpiuxddkpyvhpoqhuyjvacdodmnldlkefm",
        type: "dropDown",
        name: null,
        label: "Observations Contd.",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 m-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-mghsbnhfmpjcdkalbnlumuqavmxjxrxtoovnbjtxklshgygcfb": {
        uniqueId: "control-mghsbnhfmpjcdkalbnlumuqavmxjxrxtoovnbjtxklshgygcfb",
        type: "dropDown",
        name: null,
        label: "Noted Observations",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 m-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-mguyukvamoiyojzbyvubzzifttmwhmqevxjmwgxfyincqtvxcv": {
        uniqueId: "control-mguyukvamoiyojzbyvubzzifttmwhmqevxjmwgxfyincqtvxcv",
        type: "input",
        name: "patientLastName",
        label: "Last Name:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-mjknluzbhhvurbzabslgpndmmegmhsiaqbmqwqdhtrfswyjfwu": {
        uniqueId: "control-mjknluzbhhvurbzabslgpndmmegmhsiaqbmqwqdhtrfswyjfwu",
        type: "label",
        name: null,
        label: null,
        subLabel:
          "Please describe the events that lead to the selected behaviour, Describe the behaviour itself and the consequence or response to the behaviour.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-mozcdmzoedrtrdabicoklicsucvnzovqnnusgpuwofyqsvosca": {
        uniqueId: "control-mozcdmzoedrtrdabicoklicsucvnzovqnnusgpuwofyqsvosca",
        type: "dropDown",
        name: null,
        label: "Category of Behaviour(s) observed:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-nejgfyqutjgfrwpgnatrgymzgbrnwzydhjprmbgzxgmlqvugyl": {
        uniqueId: "control-nejgfyqutjgfrwpgnatrgymzgbrnwzydhjprmbgzxgmlqvugyl",
        type: "dropDown",
        name: null,
        label: "Intensity of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-ocokkmihdqxdbsyebfpsbngacsqiwdlyrjnblyberboiyqzirt": {
        uniqueId: "control-ocokkmihdqxdbsyebfpsbngacsqiwdlyrjnblyberboiyqzirt",
        type: "label",
        name: null,
        label: "Antecedent/Behaviour/Consequence",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-oijctjdepegvugwxfmiecuiqomaoadfsvfimcllxtvzdaqwldr": {
        uniqueId: "control-oijctjdepegvugwxfmiecuiqomaoadfsvfimcllxtvzdaqwldr",
        type: "dropDown",
        name: null,
        label: "Supportive Prompting Category:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-ojhofyzhsaccouuzdounniroebnaybziihotrvbynbaeklscem": {
        uniqueId: "control-ojhofyzhsaccouuzdounniroebnaybziihotrvbynbaeklscem",
        type: "label",
        name: null,
        label: null,
        subLabel: "Select the behaviour observed during the treatment session.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-oybfpvcmnocipuezonyvejcajobxihapimonwqkiulgzszeryk": {
        uniqueId: "control-oybfpvcmnocipuezonyvejcajobxihapimonwqkiulgzszeryk",
        type: "label",
        name: null,
        label: "Negative or Maladaptive Behaviour(s) observed.",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "text-bold mb-0 under-line",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-phjnwrjnnnyvughjylvnqmadwihwtvcogwuhumchaixdjlcjzj": {
        uniqueId: "control-phjnwrjnnnyvughjylvnqmadwihwtvcogwuhumchaixdjlcjzj",
        type: "dropDown",
        name: null,
        label: "Supportive Prompting Category:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-pnthtobdwonnxadnlsnthekvxjmxzwolieppxevybjqhcwwkff": {
        uniqueId: "control-pnthtobdwonnxadnlsnthekvxjmxzwolieppxevybjqhcwwkff",
        type: "dropDown",
        name: null,
        label: "Number of Prompts or Need for Assistance:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-posnmfhinnqtilbqnxgucpfbsbvlmcwzzvmomytdbvjoggdxrk": {
        uniqueId: "control-posnmfhinnqtilbqnxgucpfbsbvlmcwzzvmomytdbvjoggdxrk",
        type: "dropDown",
        name: null,
        label: "Duration of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-ppkzpaosmferymlthqiajlqbxtmxebzgtskwyxtbasruxftppk": {
        uniqueId: "control-ppkzpaosmferymlthqiajlqbxtmxebzgtskwyxtbasruxftppk",
        type: "label",
        name: null,
        label: null,
        subLabel:
          "Please describe the events that lead to the selected behaviour, Describe the behaviour itself and the consequence or response to the behaviour.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ppqgxjgxngwbefjyaxhhncckegjstcafnbclfeuvuoxfhqgpjo": {
        uniqueId: "control-ppqgxjgxngwbefjyaxhhncckegjstcafnbclfeuvuoxfhqgpjo",
        type: "label",
        name: null,
        label: null,
        subLabel:
          "Please describe the events that lead to the selected behaviour, Describe the behaviour itself and the consequence or response to the behaviour.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-pprozzesobfqwsmqzvmqsflhqgcanrkeniwlitfrgaqfrfcgsj": {
        uniqueId: "control-pprozzesobfqwsmqzvmqsflhqgcanrkeniwlitfrgaqfrfcgsj",
        type: "dropDown",
        name: null,
        label: "Supportive Prompting Category:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-pqkujkgyzhuxthqdxeszjehpuxawofcroruasfkvxywhjqtcji": {
        uniqueId: "control-pqkujkgyzhuxthqdxeszjehpuxawofcroruasfkvxywhjqtcji",
        type: "label",
        name: null,
        label: null,
        subLabel:
          "Please describe the events that lead to the selected behaviour, Describe the behaviour itself and the consequence or response to the behaviour.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-puweqkjfijayipevtwwdfcjytiqutcbntuogzodruxqjklssgx": {
        uniqueId: "control-puweqkjfijayipevtwwdfcjytiqutcbntuogzodruxqjklssgx",
        type: "label",
        name: null,
        label: null,
        subLabel: "Select the behaviour observed during the treatment session.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-pwdbwnzrdmjtxhrtgvxpevbpemzdtwzwwlkkpqtzwjrvwgcjfa": {
        uniqueId: "control-pwdbwnzrdmjtxhrtgvxpevbpemzdtwzwwlkkpqtzwjrvwgcjfa",
        type: "dropDown",
        name: null,
        label: "Number of Prompts or Need for Assistance:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-qylpyhpoxdtbiliziqriyfcnktcpvkcahyehqgkxryyazpdiqz": {
        uniqueId: "control-qylpyhpoxdtbiliziqriyfcnktcpvkcahyehqgkxryyazpdiqz",
        type: "text",
        name: null,
        label: "BEHAVIOUR:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-rbanvpkfdvervrgbukbuhiaoxieyifmgbzztnxrlrcxhrsdxok": {
        uniqueId: "control-rbanvpkfdvervrgbukbuhiaoxieyifmgbzztnxrlrcxhrsdxok",
        type: "input",
        name: "patientFirstName",
        label: "First Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-roopjghthqbktiplfyjxkptmcutvrgudknyakpoipurskincxd": {
        uniqueId: "control-roopjghthqbktiplfyjxkptmcutvrgudknyakpoipurskincxd",
        type: "dropDown",
        name: null,
        label: "Duration of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-rptfyakiksjaqphisozyakgetgnnqbzxrohmqiodousspnodjz": {
        uniqueId: "control-rptfyakiksjaqphisozyakgetgnnqbzxrohmqiodousspnodjz",
        type: "label",
        name: null,
        label: "Negative or Maladaptive Behaviour(s) observed.",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "text-bold mb-0 under-line",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-rsghrzafhrmmtwogetmimvrdpsmlcnlcmbgoswtiidbjblihsd": {
        uniqueId: "control-rsghrzafhrmmtwogetmimvrdpsmlcnlcmbgoswtiidbjblihsd",
        type: "dropDown",
        name: null,
        label: "Number of Prompts or Need for Assistance:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-skzcldjzqvosdsjnquihrfnbwbiasjiudbpjdqamaxsngnhaxc": {
        uniqueId: "control-skzcldjzqvosdsjnquihrfnbwbiasjiudbpjdqamaxsngnhaxc",
        type: "text",
        name: null,
        label: "CONSEQUENCE:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-tcqlmtkncqchoaljrmhwvyglhuhenirvqqoiqiuogiktzjnxdr": {
        uniqueId: "control-tcqlmtkncqchoaljrmhwvyglhuhenirvqqoiqiuogiktzjnxdr",
        type: "text",
        name: null,
        label: "ANTECEDENT:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-thdwlbnslhhgxwnsucuplybqkljdctmrwdtyrvehufajfpkbui": {
        uniqueId: "control-thdwlbnslhhgxwnsucuplybqkljdctmrwdtyrvehufajfpkbui",
        type: "dropDown",
        name: null,
        label: "Duration of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-tofbfdihvukgsickkxjwingpdcchdfhflrqsshqmubibqcampd": {
        uniqueId: "control-tofbfdihvukgsickkxjwingpdcchdfhflrqsshqmubibqcampd",
        type: "dropDown",
        name: null,
        label: "Category of Behaviour(s) observed:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-twpdemtnsycztsntxyyboghfucmaankkborgzcqzmkgjmvkykc": {
        uniqueId: "control-twpdemtnsycztsntxyyboghfucmaankkborgzcqzmkgjmvkykc",
        type: "text",
        name: null,
        label: "BEHAVIOUR:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-usljmzbmxvfewcczetgnrxyspzidutazkdglpupldhjbdyoqjs": {
        uniqueId: "control-usljmzbmxvfewcczetgnrxyspzidutazkdglpupldhjbdyoqjs",
        type: "label",
        name: null,
        label: null,
        subLabel:
          "Please describe the events that lead to the selected behaviour, Describe the behaviour itself and the consequence or response to the behaviour.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-uvecrfobcgpuwtyjioqisxavfmevvtistwrwqrhtfbotpdhfci": {
        uniqueId: "control-uvecrfobcgpuwtyjioqisxavfmevvtistwrwqrhtfbotpdhfci",
        type: "label",
        name: null,
        label: "Behaviour Therapy Assistant:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-vgupsuqtpcpixrfktbofwpiruujbrylldmsjepvzizgichaxkw": {
        uniqueId: "control-vgupsuqtpcpixrfktbofwpiruujbrylldmsjepvzizgichaxkw",
        type: "dropDown",
        name: null,
        label: "Number of Prompts or Need for Assistance:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-vsyukcmkocnlomtctpgfztvdntmnzfdjhiycwroshezzefeqrk": {
        uniqueId: "control-vsyukcmkocnlomtctpgfztvdntmnzfdjhiycwroshezzefeqrk",
        type: "text",
        name: null,
        label: "Example of Observation:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize m-0",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-xgpekbshfzhwkrullevejpxzaqundrboizciwukqtshdfwyrgf": {
        uniqueId: "control-xgpekbshfzhwkrullevejpxzaqundrboizciwukqtshdfwyrgf",
        type: "dropDown",
        name: null,
        label: "Frequency of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-xmkghymoqlmpxccebakqhbdytjebzzkpijihixbnvpeofotkgo": {
        uniqueId: "control-xmkghymoqlmpxccebakqhbdytjebzzkpijihixbnvpeofotkgo",
        type: "dropDown",
        name: null,
        label: "Observations Contd.",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 m-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-xvpenediacchfliebwgtvwnaokraweeqyipltthrrjaksdovij": {
        uniqueId: "control-xvpenediacchfliebwgtvwnaokraweeqyipltthrrjaksdovij",
        type: "label",
        name: null,
        label: null,
        subLabel: "Select the behaviour observed during the treatment session.",
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding no-radius no-break",
        additionalFieldClass: null,
        additionalLabelClass: "m-0 d-none",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-yhflfejdhozjkvcbhacdfrhqlwjdxbgrvrpjptvvkmazvxprgq": {
        uniqueId: "control-yhflfejdhozjkvcbhacdfrhqlwjdxbgrvrpjptvvkmazvxprgq",
        type: "text",
        name: null,
        label: "BEHAVIOUR:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-ymgbvroxpwqtzzzvwonkroglixcsnooaznkfinjiahwzrzwmyu": {
        uniqueId: "control-ymgbvroxpwqtzzzvwonkroglixcsnooaznkfinjiahwzrzwmyu",
        type: "dropDown",
        name: null,
        label: "Number of Prompts or Need for Assistance:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-yscxlkqpkkxyulccalrctvclwwqtnjqjvnidahaxcmggjpxxdh": {
        uniqueId: "control-yscxlkqpkkxyulccalrctvclwwqtnjqjvnidahaxcmggjpxxdh",
        type: "dropDown",
        name: null,
        label: "Category of Behaviour(s) observed:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-zadqaathbqdlarvuboxsqnmjaekxyhqwmajqtwinbdsmkbxnnw": {
        uniqueId: "control-zadqaathbqdlarvuboxsqnmjaekxyhqwmajqtwinbdsmkbxnnw",
        type: "dropDown",
        name: null,
        label: "Intensity of Behaviour",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-zfmruzjgknaapqfabhbwarjipuczgnvrfyqhqrnalpdcqzddpn": {
        uniqueId: "control-zfmruzjgknaapqfabhbwarjipuczgnvrfyqhqrnalpdcqzddpn",
        type: "label",
        name: null,
        label: "Negative or Maladaptive Behaviour(s) observed 2.",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding no-radius",
        additionalFieldClass: null,
        additionalLabelClass: "text-bold mb-0 under-line",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-zfumdaswlrwnwspdkfnctnezpqubwffborgzifxvxsaenbetcn": {
        uniqueId: "control-zfumdaswlrwnwspdkfnctnezpqubwffborgzifxvxsaenbetcn",
        type: "dropDown",
        name: null,
        label: "Category of Behaviour(s) observed:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-zoywugyjeixjoqtaziobdrrszkqfznurdlinvuhcgykwssqemr": {
        uniqueId: "control-zoywugyjeixjoqtaziobdrrszkqfznurdlinvuhcgykwssqemr",
        type: "text",
        name: null,
        label: "BEHAVIOUR:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
    },
  },
  "Exercise Program Note": {
    formConfig: {
      headline: null,
      subHeadline: null,
      isShowHeadline: false,
      renderFormTag: false,
      formActionURL: null,
      formMethod: "POST",
    },
    sections: {
      "section-azcttiuunwggbqyutrdzmfhdpzdschobfypagovudlbkvgjgvl": {
        uniqueId: "section-azcttiuunwggbqyutrdzmfhdpzdschobfypagovudlbkvgjgvl",
        headline: null,
        headlineAdditionalClass: null,
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: false,
        sortOrder: 1,
        type: "normal",
        rows: [],
        controls: [
          "control-vxnxsxlbqggjwlyxnqguerutjzxfwyvwsqexaathztdgxyznnt",
          "control-tpeeyeqdoikfvcqwjdouuywxqfbavxkpmtotvfsatootuknraq",
          "control-htvyhhtmgzcrzifgnsfijshusjisesycetgdhhhniecgekogbo",
          "control-7c2161e7-dbce-40b1-ab94-9f548bdad7a1",
          "control-6eae965c-ff4e-47dd-aa67-fbf97b6701ed",
          "control-bb1a5c39-1ee0-4d08-8cf1-96bbb00dd3cb",
          "control-094507fc-2603-4156-9f1d-998c915f0f4b",
          "control-17e89512-9b4e-4880-8e1c-96c292aaae6d",
          "control-7af3abdf-8b44-4cd2-bf85-f3834196874c",
          "control-175989e6-52ef-4938-ab3a-62c5c4746018",
          "control-8255a800-effd-4e99-b856-431e5ff94b52",
          "control-e72422fb-6aad-47fe-b56b-3bf06adb49fd",
          "control-114b07fc-0eab-4080-ad2a-1560afd52a35",
          "control-45815eb7-21dd-4c05-a2d3-3a9968475df3",
          "control-b8724990-089a-40d6-b15c-1b4fa9c33ae9",
          "control-9c969d0e-7995-4827-b5b7-60b074c4deae",
          "control-f9b9dcc7-97c8-4337-8066-ebdbea7bd9a0",
          "control-10ec50d6-0b80-42b5-bfea-34b3503f3f89",
          "control-8a78845f-b23b-4061-a5da-be262a5b7e98",
          "control-0b8b8131-291b-4f4f-9e2b-1f91b2b79b7e",
          "control-87cdc6ad-b9b8-47bc-83d3-85f16cbc5b78",
          "control-a500564f-99c3-4bf9-a99a-8cee4d7787e7",
          "control-523b1bbb-ac78-43b3-840c-f8068b23fce5",
          "control-bbcb3284-dd8a-4645-9fe0-719997d3b1c7",
          "control-f92c08b9-aa92-4ddb-b1a6-0878c88b4d8d",
          "control-3b352ad3-bd82-45ba-bbed-aa3a6f4c1448",
          "control-72e5c5be-290e-4297-bb0f-72a90dbd9ff2",
          "control-7e4220d8-9c7d-4a12-81b3-324a7af1295f",
          "control-303507d0-553a-42b8-93fb-c142eb6d66b6",
          "control-f8d1640c-0b29-4db0-8153-205cff4e1acd",
          "control-a394f18f-d28b-4731-a418-0bc728b28e13",
          "control-8150417c-538d-4da3-919c-141711e1bbff",
          "control-d3a704f3-f46c-4d51-9436-c4192965cc30",
          "control-60fcd060-9ed0-4d54-aafb-67d1c88b688d",
          "control-a8b63872-97bc-45ac-80f0-82730589da65",
          "control-51db31c3-8d6d-41ac-8af8-a97377269180",
          "control-e1879e47-6a1d-42e1-ab15-6702b547eeed",
          "control-8dafbb7b-1a00-4fbd-bc97-869d282ce186",
          "control-daee0932-070c-4966-aa27-6f5bbaee0f41",
          "control-c49c2f9a-2d6c-4f22-b23f-675275cb0f02",
          "control-c83245eb-5285-4a34-a9b0-69004bbe9312",
          "control-a25a6cef-e0a2-4128-9938-8e4e3792b577",
          "control-2fa2e0a9-a2f4-4e01-9612-083f530201bc",
          "control-549f39e1-d56d-4d5a-9d69-9c326430424e",
          "control-845f918c-7c21-40b1-a4cd-d93ccfd15e1b",
          "control-35b53869-6881-46c1-bf69-db6a574c90b7",
          "control-aa628f43-5ff1-4b94-9bca-9b4200a5c407",
          "control-7f576126-cb66-44d8-aeeb-a852b358cb34",
          "control-636b710c-c28d-4c8e-ac23-1780e73bdbb3",
          "control-904b1480-f1d2-4a00-ad28-be21f723d034",
          "control-8ea87c07-bccb-4700-8a49-ca322b074120",
          "control-e32395f5-393c-4c6d-b7ac-157cf064050c",
          "control-4d0f27e5-2517-475d-95b8-5bbf9ba2e6f4",
          "control-778c7557-73fc-48b2-8a05-68b7b1058362",
          "control-77989716-bad3-41f2-b7bc-9ff4a8667276",
          "control-e2efe9a0-e0b1-4f5f-b6cb-064442c3c08b",
          "control-46a1b2b6-3f9c-46ae-8166-ff35a7a10c13",
          "control-40754d01-cada-46e9-9ce3-23b3dbfcb5cc",
          "control-ebebe31b-94fc-45c8-bc1e-6acb7daa558e",
          "control-9606f817-ffbd-4b9f-a5dd-0515fbf62b01",
          "control-73011528-8ec0-4e5d-b183-e8287b19e359",
          "control-fc086899-03c4-4e00-85d1-516225d60104",
          "control-29917029-81f3-4826-b04f-9f34434a1538",
          "control-88ccfed4-2b72-4080-ba9a-6e87cef4431a",
          "control-e6729442-b9a3-4301-9d43-bc5b7dd3c334",
          "control-4ec6f55b-8422-4b41-92fe-3c0730834c81",
          "control-baa01a3b-a85e-4894-9282-cc075db83918",
          "control-808bee4a-931d-48b8-8dbd-000b49b47c55",
          "control-7b78109b-0802-43ad-847c-5e124afa2ae3",
          "control-8d17bf6f-f99b-4624-aacc-ee63ca14aed4",
          "control-f1de39bc-1c77-4625-878d-09dfad5aea3f",
          "control-de0f96f8-6967-41f1-941a-909585f349a5",
          "control-762a47a3-12d7-42d3-a621-1a8c01e4a879",
          "control-cb059e1a-a27d-4ddd-8a61-444ee0609247",
          "control-d91133be-75e3-4cf0-81fa-385e1380855c",
          "control-89957e36-44d2-412d-be98-43126df66159",
          "control-c2395cc9-4cc5-4037-af27-a7e74c3195ad",
          "control-a592bc6b-dd72-46d7-a23a-cd70a132dc34",
          "control-04acfaa4-e1b5-4fff-b8e1-3698d244e261",
        ],
      },
    },
    rows: {},
    controls: {
      "control-04acfaa4-e1b5-4fff-b8e1-3698d244e261": {
        uniqueId: "control-04acfaa4-e1b5-4fff-b8e1-3698d244e261",
        type: "date",
        name: null,
        label: "Date of Next Session",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-094507fc-2603-4156-9f1d-998c915f0f4b": {
        uniqueId: "control-094507fc-2603-4156-9f1d-998c915f0f4b",
        type: "input",
        name: "sessionEndTime",
        label: "Session end time",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-0b8b8131-291b-4f4f-9e2b-1f91b2b79b7e": {
        uniqueId: "control-0b8b8131-291b-4f4f-9e2b-1f91b2b79b7e",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-10ec50d6-0b80-42b5-bfea-34b3503f3f89": {
        uniqueId: "control-10ec50d6-0b80-42b5-bfea-34b3503f3f89",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-114b07fc-0eab-4080-ad2a-1560afd52a35": {
        uniqueId: "control-114b07fc-0eab-4080-ad2a-1560afd52a35",
        type: "label",
        name: null,
        label: "SETS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3 break-word",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-175989e6-52ef-4938-ab3a-62c5c4746018": {
        uniqueId: "control-175989e6-52ef-4938-ab3a-62c5c4746018",
        type: "dropDown",
        name: null,
        label: "Type of program implemented.",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-17e89512-9b4e-4880-8e1c-96c292aaae6d": {
        uniqueId: "control-17e89512-9b4e-4880-8e1c-96c292aaae6d",
        type: "dropDown",
        name: null,
        label: "Rehabilitation Therapist",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: "border-0 pt-1",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [{ text: "Spencer, Stuart", value: "Spencer, Stuart" }],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-29917029-81f3-4826-b04f-9f34434a1538": {
        uniqueId: "control-29917029-81f3-4826-b04f-9f34434a1538",
        type: "label",
        name: null,
        label: "3. Gestural",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-2fa2e0a9-a2f4-4e01-9612-083f530201bc": {
        uniqueId: "control-2fa2e0a9-a2f4-4e01-9612-083f530201bc",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-303507d0-553a-42b8-93fb-c142eb6d66b6": {
        uniqueId: "control-303507d0-553a-42b8-93fb-c142eb6d66b6",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-35b53869-6881-46c1-bf69-db6a574c90b7": {
        uniqueId: "control-35b53869-6881-46c1-bf69-db6a574c90b7",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-3b352ad3-bd82-45ba-bbed-aa3a6f4c1448": {
        uniqueId: "control-3b352ad3-bd82-45ba-bbed-aa3a6f4c1448",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-40754d01-cada-46e9-9ce3-23b3dbfcb5cc": {
        uniqueId: "control-40754d01-cada-46e9-9ce3-23b3dbfcb5cc",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-45815eb7-21dd-4c05-a2d3-3a9968475df3": {
        uniqueId: "control-45815eb7-21dd-4c05-a2d3-3a9968475df3",
        type: "label",
        name: null,
        label: "REPS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3 break-word",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-46a1b2b6-3f9c-46ae-8166-ff35a7a10c13": {
        uniqueId: "control-46a1b2b6-3f9c-46ae-8166-ff35a7a10c13",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-4d0f27e5-2517-475d-95b8-5bbf9ba2e6f4": {
        uniqueId: "control-4d0f27e5-2517-475d-95b8-5bbf9ba2e6f4",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-4ec6f55b-8422-4b41-92fe-3c0730834c81": {
        uniqueId: "control-4ec6f55b-8422-4b41-92fe-3c0730834c81",
        type: "label",
        name: null,
        label: "6. Descriptive Feedback",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-51db31c3-8d6d-41ac-8af8-a97377269180": {
        uniqueId: "control-51db31c3-8d6d-41ac-8af8-a97377269180",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-523b1bbb-ac78-43b3-840c-f8068b23fce5": {
        uniqueId: "control-523b1bbb-ac78-43b3-840c-f8068b23fce5",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-549f39e1-d56d-4d5a-9d69-9c326430424e": {
        uniqueId: "control-549f39e1-d56d-4d5a-9d69-9c326430424e",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-60fcd060-9ed0-4d54-aafb-67d1c88b688d": {
        uniqueId: "control-60fcd060-9ed0-4d54-aafb-67d1c88b688d",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-636b710c-c28d-4c8e-ac23-1780e73bdbb3": {
        uniqueId: "control-636b710c-c28d-4c8e-ac23-1780e73bdbb3",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-6eae965c-ff4e-47dd-aa67-fbf97b6701ed": {
        uniqueId: "control-6eae965c-ff4e-47dd-aa67-fbf97b6701ed",
        type: "date",
        name: "sessionStartDate",
        label: "Date of Session",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 m-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-72e5c5be-290e-4297-bb0f-72a90dbd9ff2": {
        uniqueId: "control-72e5c5be-290e-4297-bb0f-72a90dbd9ff2",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-73011528-8ec0-4e5d-b183-e8287b19e359": {
        uniqueId: "control-73011528-8ec0-4e5d-b183-e8287b19e359",
        type: "label",
        name: null,
        label: "1. Visual",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-762a47a3-12d7-42d3-a621-1a8c01e4a879": {
        uniqueId: "control-762a47a3-12d7-42d3-a621-1a8c01e4a879",
        type: "radio",
        name: null,
        label:
          "Was there any fatigue observed during the exercise program? If yes, please describe:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "white-bg sticky-up border no-padding",
        additionalFieldClass: "pl-3 ml-3",
        additionalLabelClass: "ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-778c7557-73fc-48b2-8a05-68b7b1058362": {
        uniqueId: "control-778c7557-73fc-48b2-8a05-68b7b1058362",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-77989716-bad3-41f2-b7bc-9ff4a8667276": {
        uniqueId: "control-77989716-bad3-41f2-b7bc-9ff4a8667276",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-7af3abdf-8b44-4cd2-bf85-f3834196874c": {
        uniqueId: "control-7af3abdf-8b44-4cd2-bf85-f3834196874c",
        type: "label",
        name: null,
        label: "TYPE OF EXERCISE PROGRAM EMPLOYED",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-7b78109b-0802-43ad-847c-5e124afa2ae3": {
        uniqueId: "control-7b78109b-0802-43ad-847c-5e124afa2ae3",
        type: "label",
        name: null,
        label: "0- Easy- 10- Hardest",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-7c2161e7-dbce-40b1-ab94-9f548bdad7a1": {
        uniqueId: "control-7c2161e7-dbce-40b1-ab94-9f548bdad7a1",
        type: "text",
        name: null,
        label: "Insurance information:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize m-0",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-7e4220d8-9c7d-4a12-81b3-324a7af1295f": {
        uniqueId: "control-7e4220d8-9c7d-4a12-81b3-324a7af1295f",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-7f576126-cb66-44d8-aeeb-a852b358cb34": {
        uniqueId: "control-7f576126-cb66-44d8-aeeb-a852b358cb34",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-808bee4a-931d-48b8-8dbd-000b49b47c55": {
        uniqueId: "control-808bee4a-931d-48b8-8dbd-000b49b47c55",
        type: "label",
        name: null,
        label:
          "Difficulty Key: I.E. How difficult was this task/exercise for the client to complete?",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-8150417c-538d-4da3-919c-141711e1bbff": {
        uniqueId: "control-8150417c-538d-4da3-919c-141711e1bbff",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-8255a800-effd-4e99-b856-431e5ff94b52": {
        uniqueId: "control-8255a800-effd-4e99-b856-431e5ff94b52",
        type: "label",
        name: null,
        label: "EXERCISE/STRETCH",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3 break-word",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-845f918c-7c21-40b1-a4cd-d93ccfd15e1b": {
        uniqueId: "control-845f918c-7c21-40b1-a4cd-d93ccfd15e1b",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-87cdc6ad-b9b8-47bc-83d3-85f16cbc5b78": {
        uniqueId: "control-87cdc6ad-b9b8-47bc-83d3-85f16cbc5b78",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-88ccfed4-2b72-4080-ba9a-6e87cef4431a": {
        uniqueId: "control-88ccfed4-2b72-4080-ba9a-6e87cef4431a",
        type: "label",
        name: null,
        label: "4. Hepatic",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-89957e36-44d2-412d-be98-43126df66159": {
        uniqueId: "control-89957e36-44d2-412d-be98-43126df66159",
        type: "text",
        name: null,
        label: "Observed/Reported Pain:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-8a78845f-b23b-4061-a5da-be262a5b7e98": {
        uniqueId: "control-8a78845f-b23b-4061-a5da-be262a5b7e98",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-8d17bf6f-f99b-4624-aacc-ee63ca14aed4": {
        uniqueId: "control-8d17bf6f-f99b-4624-aacc-ee63ca14aed4",
        type: "label",
        name: null,
        label: "PHYSICAL DYSFUNCTION/BARRIERS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-8dafbb7b-1a00-4fbd-bc97-869d282ce186": {
        uniqueId: "control-8dafbb7b-1a00-4fbd-bc97-869d282ce186",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-8ea87c07-bccb-4700-8a49-ca322b074120": {
        uniqueId: "control-8ea87c07-bccb-4700-8a49-ca322b074120",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-904b1480-f1d2-4a00-ad28-be21f723d034": {
        uniqueId: "control-904b1480-f1d2-4a00-ad28-be21f723d034",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-9606f817-ffbd-4b9f-a5dd-0515fbf62b01": {
        uniqueId: "control-9606f817-ffbd-4b9f-a5dd-0515fbf62b01",
        type: "label",
        name: null,
        label: "Prompting Key:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-9c969d0e-7995-4827-b5b7-60b074c4deae": {
        uniqueId: "control-9c969d0e-7995-4827-b5b7-60b074c4deae",
        type: "label",
        name: null,
        label: "PROMPTS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3 break-word",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-a25a6cef-e0a2-4128-9938-8e4e3792b577": {
        uniqueId: "control-a25a6cef-e0a2-4128-9938-8e4e3792b577",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-a394f18f-d28b-4731-a418-0bc728b28e13": {
        uniqueId: "control-a394f18f-d28b-4731-a418-0bc728b28e13",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-a500564f-99c3-4bf9-a99a-8cee4d7787e7": {
        uniqueId: "control-a500564f-99c3-4bf9-a99a-8cee4d7787e7",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-a592bc6b-dd72-46d7-a23a-cd70a132dc34": {
        uniqueId: "control-a592bc6b-dd72-46d7-a23a-cd70a132dc34",
        type: "text",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize m-0",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-a8b63872-97bc-45ac-80f0-82730589da65": {
        uniqueId: "control-a8b63872-97bc-45ac-80f0-82730589da65",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-aa628f43-5ff1-4b94-9bca-9b4200a5c407": {
        uniqueId: "control-aa628f43-5ff1-4b94-9bca-9b4200a5c407",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-b8724990-089a-40d6-b15c-1b4fa9c33ae9": {
        uniqueId: "control-b8724990-089a-40d6-b15c-1b4fa9c33ae9",
        type: "label",
        name: null,
        label: "PURPOSE",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3 break-word",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-baa01a3b-a85e-4894-9282-cc075db83918": {
        uniqueId: "control-baa01a3b-a85e-4894-9282-cc075db83918",
        type: "label",
        name: null,
        label: "7 . Indirect",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-bb1a5c39-1ee0-4d08-8cf1-96bbb00dd3cb": {
        uniqueId: "control-bb1a5c39-1ee0-4d08-8cf1-96bbb00dd3cb",
        type: "input",
        name: "sessionStartTime",
        label: "Session start time:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-bbcb3284-dd8a-4645-9fe0-719997d3b1c7": {
        uniqueId: "control-bbcb3284-dd8a-4645-9fe0-719997d3b1c7",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-c2395cc9-4cc5-4037-af27-a7e74c3195ad": {
        uniqueId: "control-c2395cc9-4cc5-4037-af27-a7e74c3195ad",
        type: "label",
        name: null,
        label: "GENERAL NOTES",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-c49c2f9a-2d6c-4f22-b23f-675275cb0f02": {
        uniqueId: "control-c49c2f9a-2d6c-4f22-b23f-675275cb0f02",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-c83245eb-5285-4a34-a9b0-69004bbe9312": {
        uniqueId: "control-c83245eb-5285-4a34-a9b0-69004bbe9312",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-cb059e1a-a27d-4ddd-8a61-444ee0609247": {
        uniqueId: "control-cb059e1a-a27d-4ddd-8a61-444ee0609247",
        type: "text",
        name: null,
        label: "Observed Fatigue:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-d3a704f3-f46c-4d51-9436-c4192965cc30": {
        uniqueId: "control-d3a704f3-f46c-4d51-9436-c4192965cc30",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-d91133be-75e3-4cf0-81fa-385e1380855c": {
        uniqueId: "control-d91133be-75e3-4cf0-81fa-385e1380855c",
        type: "radio",
        name: null,
        label:
          "Was any pain observed or reported during the routine? If yes, please describe:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "white-bg border no-padding",
        additionalFieldClass: "pl-3 ml-3",
        additionalLabelClass: "ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-daee0932-070c-4966-aa27-6f5bbaee0f41": {
        uniqueId: "control-daee0932-070c-4966-aa27-6f5bbaee0f41",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-de0f96f8-6967-41f1-941a-909585f349a5": {
        uniqueId: "control-de0f96f8-6967-41f1-941a-909585f349a5",
        type: "text",
        name: null,
        label: "Example:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-e1879e47-6a1d-42e1-ab15-6702b547eeed": {
        uniqueId: "control-e1879e47-6a1d-42e1-ab15-6702b547eeed",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-e2efe9a0-e0b1-4f5f-b6cb-064442c3c08b": {
        uniqueId: "control-e2efe9a0-e0b1-4f5f-b6cb-064442c3c08b",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-e32395f5-393c-4c6d-b7ac-157cf064050c": {
        uniqueId: "control-e32395f5-393c-4c6d-b7ac-157cf064050c",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-e6729442-b9a3-4301-9d43-bc5b7dd3c334": {
        uniqueId: "control-e6729442-b9a3-4301-9d43-bc5b7dd3c334",
        type: "label",
        name: null,
        label: "5. Postive Reinforcement",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-e72422fb-6aad-47fe-b56b-3bf06adb49fd": {
        uniqueId: "control-e72422fb-6aad-47fe-b56b-3bf06adb49fd",
        type: "label",
        name: null,
        label: "WEIGHT",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3 break-word",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ebebe31b-94fc-45c8-bc1e-6acb7daa558e": {
        uniqueId: "control-ebebe31b-94fc-45c8-bc1e-6acb7daa558e",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-f1de39bc-1c77-4625-878d-09dfad5aea3f": {
        uniqueId: "control-f1de39bc-1c77-4625-878d-09dfad5aea3f",
        type: "dropDown",
        name: null,
        label: "Physical Barriers Observed during session",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-f8d1640c-0b29-4db0-8153-205cff4e1acd": {
        uniqueId: "control-f8d1640c-0b29-4db0-8153-205cff4e1acd",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass: "cell-input",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-f92c08b9-aa92-4ddb-b1a6-0878c88b4d8d": {
        uniqueId: "control-f92c08b9-aa92-4ddb-b1a6-0878c88b4d8d",
        type: "input",
        name: null,
        label: null,
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-f9b9dcc7-97c8-4337-8066-ebdbea7bd9a0": {
        uniqueId: "control-f9b9dcc7-97c8-4337-8066-ebdbea7bd9a0",
        type: "label",
        name: null,
        label: "DIFFICULTY",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-3 break-word",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-fc086899-03c4-4e00-85d1-516225d60104": {
        uniqueId: "control-fc086899-03c4-4e00-85d1-516225d60104",
        type: "label",
        name: null,
        label: "2. Verbal",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 text-bold",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-htvyhhtmgzcrzifgnsfijshusjisesycetgdhhhniecgekogbo": {
        uniqueId: "control-htvyhhtmgzcrzifgnsfijshusjisesycetgdhhhniecgekogbo",
        type: "input",
        name: "patientLastName",
        label: "Last Name:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-tpeeyeqdoikfvcqwjdouuywxqfbavxkpmtotvfsatootuknraq": {
        uniqueId: "control-tpeeyeqdoikfvcqwjdouuywxqfbavxkpmtotvfsatootuknraq",
        type: "input",
        name: "patientFirstName",
        label: "First Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-vxnxsxlbqggjwlyxnqguerutjzxfwyvwsqexaathztdgxyznnt": {
        uniqueId: "control-vxnxsxlbqggjwlyxnqguerutjzxfwyvwsqexaathztdgxyznnt",
        type: "label",
        name: null,
        label: "CLIENT NAME:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
    },
  },
  "RT Client Information Sheet": {
    formConfig: {
      headline: null,
      subHeadline: null,
      isShowHeadline: false,
      renderFormTag: false,
      formActionURL: null,
      formMethod: "POST",
    },
    sections: {
      "section-169b4aec-d35a-4a03-8823-3b7e651b1d70": {
        uniqueId: "section-169b4aec-d35a-4a03-8823-3b7e651b1d70",
        headline: "Manager notes/RSW availability",
        headlineAdditionalClass: "text-bold border-bottom-bolder-black mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 10,
        type: "normal",
        rows: [],
        controls: [
          "control-055bdd9a-dbc5-4025-b69d-38967902c1a5",
          "control-afe9da58-2d60-4d82-ab27-1dbb68aa2ec9",
          "control-e4fee2dd-a5ef-46f5-8026-8d1452769dea",
          "control-f2db1a4d-74d6-468a-9bc7-fe9f37ac3e86",
        ],
      },
      "section-72526481-d6e1-4b56-951b-bff902c9950b": {
        uniqueId: "section-72526481-d6e1-4b56-951b-bff902c9950b",
        headline: "Client Goals as directed by Treatment Team",
        headlineAdditionalClass: "text-bold border-bottom-bolder-black mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 8,
        type: "normal",
        rows: [],
        controls: [],
      },
      "section-b2b3a5d9-a432-4cc9-93af-c13b0c28bbc0": {
        uniqueId: "section-b2b3a5d9-a432-4cc9-93af-c13b0c28bbc0",
        headline: "Rehab Professionals Contact info",
        headlineAdditionalClass: "text-bold border-bottom-bolder-black mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 2,
        type: "normal",
        rows: [],
        controls: [],
      },
      "section-gnpcxoshyvqhhpvecxxfrmtyynwjvcukwobowkomhtaujzkift": {
        uniqueId: "section-gnpcxoshyvqhhpvecxxfrmtyynwjvcukwobowkomhtaujzkift",
        headline: "Rehabilitation Professional 4",
        headlineAdditionalClass: "border-bottom-bold mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 6,
        type: "normal",
        rows: [],
        controls: [
          "control-xyvcrnrcwqzvnetpqxlnegocgtddngwkyislzduyvotagiagjh",
          "control-zqjtgkfzkcfizzmtopaxaorgqrtkcbzzkjzgyuyfkrtbcalrjo",
          "control-himbrtidqyokuirzmprwfzyqnbccdvrmrqemiyljvrjjuccfxh",
          "control-kthpqnycztufsyhtfknhtiyldlbkdtjqkznpajuslxiifkamof",
          "control-oqvjepvvgkwunhdfgjkiysbptaedbypkydvblttdvsyqswgdhw",
          "control-mhovqrbixgjkzsmpahtlfswspwsyofhvgmeevolfgqwzahercn",
          "control-omwutpuxguxffkhhtonlmztuftjxwgqiotbhronxgywkorhzti",
          "control-vnoxvpwpjssbxmuqudnrnxmgqiroltqyuzpbzfgfsisggoemdy",
          "control-kehbzvxplemqatooedppbjwxdqshvsjfsfqbcadqyaggaqigro",
          "control-hhsfneaiakidxqtancdsnwwqnqwvlczqqabzduqrpucyxqcqhe",
          "control-iiindkqyuruuyaeqmiekplgtzoplyeztpcprglqfykudyqpkdu",
          "control-actefjrxucvpdnkubuzjcpotyaupcuangmxfmyidqtbmantewu",
          "control-sxnvzuvopnqmyyicuvhkxekhtmzlcmkutdfmanbsyrsxjripcp",
          "control-kjksqraktkxzclnacvjcwxzjrcromkhinmosccqdqyzjecapng",
          "control-vjvezohtphcycdlwubilrvpfvfgnympborshbqdnssbclculqt",
          "control-omaoouqcuwwrygwocpkbxaqoptlyeeymieofhtwsqpwvkbrnue",
          "control-kveydtxozmisgwfphuglwfvifcmyojfshgmyoncbhdrdbpyoyd",
          "control-auzftayhhzojfgydsyxvmelndmulpgkxyzmpmtlvaiycsupjqj",
          "control-yycjvnkzbmqhnbzbwmzkyxolfzinsgppbemhozffphmuqakqhv",
        ],
      },
      "section-ognvhysgiulidelymifxfpdvzbnlzzujobgftpbvjbmdyiojwp": {
        uniqueId: "section-ognvhysgiulidelymifxfpdvzbnlzzujobgftpbvjbmdyiojwp",
        headline: null,
        headlineAdditionalClass: null,
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: false,
        sortOrder: 9,
        type: "normal",
        rows: [],
        controls: [
          "control-fwbljkrsttervhqpgupbzpnduxntjcqlvqxgnghnhkpibhfngm",
          "control-fkfcpcrpuyrledxodwssliojvqcezwrknadphufcszbvttlanp",
          "control-pdbtrysmcywqlhtzgugenzqxerifeicpwwktyjoabuyvpmuxkz",
          "control-9f114a60-281d-4fa9-accd-0cb4b3fc2b68",
          "control-53764777-9ac8-4ab5-9781-b40983f79c41",
          "control-eb26c6e0-1d81-4ff3-a4f3-ca593d33e220",
          "control-2d8086af-9ed1-4eb8-9972-2ddd61406a60",
          "control-82c445d9-97ea-418f-953c-d36f60ee7ea1",
          "control-c12185f7-b22e-49da-964b-c3418de9ce52",
          "control-21c8ccfb-76dc-4914-a878-0cbaeffc6ea8",
          "control-dd44dc43-03ab-456e-b83e-8adcea9bc8df",
        ],
      },
      "section-qhhmhnqekudbkbiamwtixjgbbiaojqugokuqlplbccmrbgndvs": {
        uniqueId: "section-qhhmhnqekudbkbiamwtixjgbbiaojqugokuqlplbccmrbgndvs",
        headline: "Client Information",
        headlineAdditionalClass: "border-bottom-bold",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 1,
        type: "normal",
        rows: [],
        controls: [
          "control-kxjiadfgodjyexqjxgenjpaeiwixlnnpdbaoouibyiopeurxqn",
          "control-rtqkibjptnmagkwnvtbkysgahidpsnrjwpiwlbsusbkjxfuqax",
          "control-njugjgzozspiyblpxmhmlwtqucfejbqlmxgwfdkvrlwnzcjzqf",
          "control-pcyikunfkftwxzrjutbizgygshxloztawkblnwbjzynnhnxdet",
          "control-adloaeqstgcsapvzogjlyonkxgipviusposgwtdbpanouqfhxv",
          "control-zyuhnzvxohrqfaftlxzersjsxcmthrcnvcsdlovcmmpulmpuom",
          "control-aplvpohmfnastjuyhizzlxgsppencuizzyieunvatgtiwseisx",
          "control-gsfcytalqnmaqgtqfcqbduuxolqpsripdyqtomwyczieoselsj",
          "control-nngrfmndhmhvvadbbmrmzenrgtpherksaeqdmxgtrpiyammwju",
          "control-tyicpaeqwhdytpwimyehoheiqpofnopryrpnkuwwnusdecaasl",
          "control-fouomqqrfulwhvbvywkwtigxmjlixxuomxdaxnyqsjxmfzgsaq",
          "control-oifkknbixivjcafklvlzycramrtvcoppoypkpklnqvcxzjfbwo",
          "control-tfflvsfkjptkvfhiffimsnyisuphojmbesbdlwgytvjfpqhcos",
          "control-yialfetpdsqlumfptodraopxlnmppghhfaeqmhcdiowvfvkngj",
          "control-ybrthjplbpvbbcxfxtuqiuozplfdjoqxyvhtpbzdbwbqgvvcru",
          "control-5532130a-653b-4465-8590-437c2c71cfe4",
          "control-0e583660-ab4d-43e2-8f80-91e26fc2391f",
          "control-942cde6b-6884-491a-a9c0-a7f4f50f8874",
          "control-79636709-897f-4990-9519-ce0789d1f2b7",
          "control-b9482d8e-5f1c-49f8-8303-af8003ec3faf",
          "control-85c83157-f50c-475b-a4e4-f187f5e02284",
          "control-d093f884-c347-4600-9227-58893441deb9",
          "control-896c00c1-0fd1-4781-8ef4-8b0a24d7a165",
        ],
      },
      "section-sbtghbfcxurrjzlrjaztcroxofgzupnxytiewzrviimzdahgle": {
        uniqueId: "section-sbtghbfcxurrjzlrjaztcroxofgzupnxytiewzrviimzdahgle",
        headline: "Rehabilitation Professional 2",
        headlineAdditionalClass: "border-bottom-bold mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 4,
        type: "normal",
        rows: [],
        controls: [
          "control-oqgaiuagylegpwzeojlhqckiqeaetlvcvyxmuyvifqhaksuriq",
          "control-boghqpgieqlwhyrmxxkslwikidrspahtszldlyhjwmmfiwcbxe",
          "control-zrvagkwlqqxvqnasqukiwkyoregtszebqdjfbdnswvvnchwmsj",
          "control-yzhpvjdrahqjutzpiukeqgnjcvsxoflfxnjszuncesaarsjcpo",
          "control-wamvmpwawqlmbzstamyggbvttttydxiuskjwnsucizstcvbphi",
          "control-jfslbfopglxyhuewojxkfrddjttwqdgfmwvnshdfvxfnmlwxxp",
          "control-dtwhxpokaymppflgcntyaihgdmivyhxaawmubndkawrnodyylq",
          "control-yqvxsxdmlhrufhjukgovflkxfqyihcqzxbokiyxolfhuhdievb",
          "control-wgnmukkbqlzzuczkapmdhwzwxrltygfutqimsrhkggbxbyntah",
          "control-htknqgmmbrwwynvzavfkufyoedvokmrrjcvqotycvssbwejjin",
          "control-nejukeqeqblnsbtxjidwbaolsqqoypawvimegvtwgaqzvntzqw",
          "control-swpjuhxrazqgtoamalaumaohzniwuduflwmyhnkpebfdhyoegy",
          "control-cmuohpccgvoovsxgczrufrnxuqvsdlndydasgaowpjrfxuvqwi",
          "control-touruylrrmluwgkytijtyrxwvlirowhxhukobaplwkdeoeuwhy",
          "control-eylfibmdlalxnlnfdrcxkfpmrugzqrogvxwiqxnwzrkodtkgbi",
          "control-zpznrggpxllweeinfpainggppkkjeagnmyjtevnfdcyjilelrc",
        ],
      },
      "section-trstbjeqpjpvpwhhtcjbutkmkownzvorrmrzonpggkxnkbvvqc": {
        uniqueId: "section-trstbjeqpjpvpwhhtcjbutkmkownzvorrmrzonpggkxnkbvvqc",
        headline: "Rehabilitation Professional 5",
        headlineAdditionalClass: "border-bottom-bold mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 7,
        type: "normal",
        rows: [],
        controls: [
          "control-xyvcrnrcwqzvnetpqxlnegocgtddngwkyislzduyvotagiagjh",
          "control-zqjtgkfzkcfizzmtopaxaorgqrtkcbzzkjzgyuyfkrtbcalrjo",
          "control-himbrtidqyokuirzmprwfzyqnbccdvrmrqemiyljvrjjuccfxh",
          "control-kthpqnycztufsyhtfknhtiyldlbkdtjqkznpajuslxiifkamof",
          "control-oqvjepvvgkwunhdfgjkiysbptaedbypkydvblttdvsyqswgdhw",
          "control-mhovqrbixgjkzsmpahtlfswspwsyofhvgmeevolfgqwzahercn",
          "control-omwutpuxguxffkhhtonlmztuftjxwgqiotbhronxgywkorhzti",
          "control-vnoxvpwpjssbxmuqudnrnxmgqiroltqyuzpbzfgfsisggoemdy",
          "control-kehbzvxplemqatooedppbjwxdqshvsjfsfqbcadqyaggaqigro",
          "control-hhsfneaiakidxqtancdsnwwqnqwvlczqqabzduqrpucyxqcqhe",
          "control-iiindkqyuruuyaeqmiekplgtzoplyeztpcprglqfykudyqpkdu",
          "control-actefjrxucvpdnkubuzjcpotyaupcuangmxfmyidqtbmantewu",
          "control-sxnvzuvopnqmyyicuvhkxekhtmzlcmkutdfmanbsyrsxjripcp",
          "control-kjksqraktkxzclnacvjcwxzjrcromkhinmosccqdqyzjecapng",
          "control-vjvezohtphcycdlwubilrvpfvfgnympborshbqdnssbclculqt",
          "control-omaoouqcuwwrygwocpkbxaqoptlyeeymieofhtwsqpwvkbrnue",
          "control-kveydtxozmisgwfphuglwfvifcmyojfshgmyoncbhdrdbpyoyd",
          "control-auzftayhhzojfgydsyxvmelndmulpgkxyzmpmtlvaiycsupjqj",
          "control-yycjvnkzbmqhnbzbwmzkyxolfzinsgppbemhozffphmuqakqhv",
        ],
      },
      "section-vihkmdpvihxucmzrupgljgdrikfxtskozbzgbaobyaqmxxlqtu": {
        uniqueId: "section-vihkmdpvihxucmzrupgljgdrikfxtskozbzgbaobyaqmxxlqtu",
        headline: "Rehabilitation Professional 1",
        headlineAdditionalClass: "border-bottom-bold",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 3,
        type: "normal",
        rows: [],
        controls: [
          "control-oqgaiuagylegpwzeojlhqckiqeaetlvcvyxmuyvifqhaksuriq",
          "control-boghqpgieqlwhyrmxxkslwikidrspahtszldlyhjwmmfiwcbxe",
          "control-zrvagkwlqqxvqnasqukiwkyoregtszebqdjfbdnswvvnchwmsj",
          "control-yzhpvjdrahqjutzpiukeqgnjcvsxoflfxnjszuncesaarsjcpo",
          "control-wamvmpwawqlmbzstamyggbvttttydxiuskjwnsucizstcvbphi",
          "control-jfslbfopglxyhuewojxkfrddjttwqdgfmwvnshdfvxfnmlwxxp",
          "control-dtwhxpokaymppflgcntyaihgdmivyhxaawmubndkawrnodyylq",
          "control-yqvxsxdmlhrufhjukgovflkxfqyihcqzxbokiyxolfhuhdievb",
          "control-wgnmukkbqlzzuczkapmdhwzwxrltygfutqimsrhkggbxbyntah",
          "control-htknqgmmbrwwynvzavfkufyoedvokmrrjcvqotycvssbwejjin",
          "control-nejukeqeqblnsbtxjidwbaolsqqoypawvimegvtwgaqzvntzqw",
          "control-swpjuhxrazqgtoamalaumaohzniwuduflwmyhnkpebfdhyoegy",
          "control-cmuohpccgvoovsxgczrufrnxuqvsdlndydasgaowpjrfxuvqwi",
          "control-touruylrrmluwgkytijtyrxwvlirowhxhukobaplwkdeoeuwhy",
          "control-eylfibmdlalxnlnfdrcxkfpmrugzqrogvxwiqxnwzrkodtkgbi",
          "control-zpznrggpxllweeinfpainggppkkjeagnmyjtevnfdcyjilelrc",
        ],
      },
      "section-ygvkviwltluodlqujamnpukiabagvvtssdjrwfjefurgitwsup": {
        uniqueId: "section-ygvkviwltluodlqujamnpukiabagvvtssdjrwfjefurgitwsup",
        headline: "Rehabilitation Professional 3",
        headlineAdditionalClass: "border-bottom-bold mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 5,
        type: "normal",
        rows: [],
        controls: [
          "control-oqgaiuagylegpwzeojlhqckiqeaetlvcvyxmuyvifqhaksuriq",
          "control-boghqpgieqlwhyrmxxkslwikidrspahtszldlyhjwmmfiwcbxe",
          "control-zrvagkwlqqxvqnasqukiwkyoregtszebqdjfbdnswvvnchwmsj",
          "control-yzhpvjdrahqjutzpiukeqgnjcvsxoflfxnjszuncesaarsjcpo",
          "control-wamvmpwawqlmbzstamyggbvttttydxiuskjwnsucizstcvbphi",
          "control-jfslbfopglxyhuewojxkfrddjttwqdgfmwvnshdfvxfnmlwxxp",
          "control-dtwhxpokaymppflgcntyaihgdmivyhxaawmubndkawrnodyylq",
          "control-yqvxsxdmlhrufhjukgovflkxfqyihcqzxbokiyxolfhuhdievb",
          "control-wgnmukkbqlzzuczkapmdhwzwxrltygfutqimsrhkggbxbyntah",
          "control-htknqgmmbrwwynvzavfkufyoedvokmrrjcvqotycvssbwejjin",
          "control-nejukeqeqblnsbtxjidwbaolsqqoypawvimegvtwgaqzvntzqw",
          "control-swpjuhxrazqgtoamalaumaohzniwuduflwmyhnkpebfdhyoegy",
          "control-cmuohpccgvoovsxgczrufrnxuqvsdlndydasgaowpjrfxuvqwi",
          "control-touruylrrmluwgkytijtyrxwvlirowhxhukobaplwkdeoeuwhy",
          "control-eylfibmdlalxnlnfdrcxkfpmrugzqrogvxwiqxnwzrkodtkgbi",
          "control-zpznrggpxllweeinfpainggppkkjeagnmyjtevnfdcyjilelrc",
        ],
      },
    },
    rows: {},
    controls: {
      "control-055bdd9a-dbc5-4025-b69d-38967902c1a5": {
        uniqueId: "control-055bdd9a-dbc5-4025-b69d-38967902c1a5",
        type: "text",
        name: null,
        label: "RSW availability",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-0e583660-ab4d-43e2-8f80-91e26fc2391f": {
        uniqueId: "control-0e583660-ab4d-43e2-8f80-91e26fc2391f",
        type: "text",
        name: null,
        label: "Approved Billing For RT:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize m-0",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-21c8ccfb-76dc-4914-a878-0cbaeffc6ea8": {
        uniqueId: "control-21c8ccfb-76dc-4914-a878-0cbaeffc6ea8",
        type: "radio",
        name: null,
        label: "Were all the above goals Approved By the Treatment team?",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "white-bg sticky-up border no-padding",
        additionalFieldClass: "pl-3 ml-3",
        additionalLabelClass: "ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-2d8086af-9ed1-4eb8-9972-2ddd61406a60": {
        uniqueId: "control-2d8086af-9ed1-4eb8-9972-2ddd61406a60",
        type: "text",
        name: null,
        label: "Client Goal # 4",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-53764777-9ac8-4ab5-9781-b40983f79c41": {
        uniqueId: "control-53764777-9ac8-4ab5-9781-b40983f79c41",
        type: "text",
        name: null,
        label: "Client Goal # 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-5532130a-653b-4465-8590-437c2c71cfe4": {
        uniqueId: "control-5532130a-653b-4465-8590-437c2c71cfe4",
        type: "label",
        name: null,
        label: "CLIENT BACKGROUND INFORMATION/INSTRUCTIONS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-5",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-79636709-897f-4990-9519-ce0789d1f2b7": {
        uniqueId: "control-79636709-897f-4990-9519-ce0789d1f2b7",
        type: "dropDown",
        name: null,
        label: "Triage Level",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 m-0 sticky-up-1",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [
          { text: "Acquired Brain Injury", value: "Acquired Brain Injury" },
        ],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-82c445d9-97ea-418f-953c-d36f60ee7ea1": {
        uniqueId: "control-82c445d9-97ea-418f-953c-d36f60ee7ea1",
        type: "text",
        name: null,
        label: "Client Goal # 5",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-85c83157-f50c-475b-a4e4-f187f5e02284": {
        uniqueId: "control-85c83157-f50c-475b-a4e4-f187f5e02284",
        type: "label",
        name: null,
        label: "SCHEDULE INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-896c00c1-0fd1-4781-8ef4-8b0a24d7a165": {
        uniqueId: "control-896c00c1-0fd1-4781-8ef4-8b0a24d7a165",
        type: "dropDown",
        name: null,
        label: "Manager",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-942cde6b-6884-491a-a9c0-a7f4f50f8874": {
        uniqueId: "control-942cde6b-6884-491a-a9c0-a7f4f50f8874",
        type: "text",
        name: null,
        label: "Description of accident/Injuries",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-9f114a60-281d-4fa9-accd-0cb4b3fc2b68": {
        uniqueId: "control-9f114a60-281d-4fa9-accd-0cb4b3fc2b68",
        type: "text",
        name: null,
        label: "Client Goal # 1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-afe9da58-2d60-4d82-ab27-1dbb68aa2ec9": {
        uniqueId: "control-afe9da58-2d60-4d82-ab27-1dbb68aa2ec9",
        type: "text",
        name: null,
        label: "Manager's Notes",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-b9482d8e-5f1c-49f8-8303-af8003ec3faf": {
        uniqueId: "control-b9482d8e-5f1c-49f8-8303-af8003ec3faf",
        type: "input",
        name: null,
        label: "Manager Notes to RSW",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-c12185f7-b22e-49da-964b-c3418de9ce52": {
        uniqueId: "control-c12185f7-b22e-49da-964b-c3418de9ce52",
        type: "dropDown",
        name: null,
        label: "Regional Manager",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-d093f884-c347-4600-9227-58893441deb9": {
        uniqueId: "control-d093f884-c347-4600-9227-58893441deb9",
        type: "date",
        name: null,
        label: "Start Date",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 0,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-dd44dc43-03ab-456e-b83e-8adcea9bc8df": {
        uniqueId: "control-dd44dc43-03ab-456e-b83e-8adcea9bc8df",
        type: "date",
        name: null,
        label: "Date when goals were approved",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass:
          "no-padding no-radius white-bg border input-border-0 m-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 0,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-e4fee2dd-a5ef-46f5-8026-8d1452769dea": {
        uniqueId: "control-e4fee2dd-a5ef-46f5-8026-8d1452769dea",
        type: "date",
        name: null,
        label: "Date of Last Supervision",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 0,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-eb26c6e0-1d81-4ff3-a4f3-ca593d33e220": {
        uniqueId: "control-eb26c6e0-1d81-4ff3-a4f3-ca593d33e220",
        type: "text",
        name: null,
        label: "Client Goal # 3",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-f2db1a4d-74d6-468a-9bc7-fe9f37ac3e86": {
        uniqueId: "control-f2db1a4d-74d6-468a-9bc7-fe9f37ac3e86",
        type: "text",
        name: null,
        label: "Monthly schedule/Mileage/Expenses",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-actefjrxucvpdnkubuzjcpotyaupcuangmxfmyidqtbmantewu": {
        uniqueId: "control-actefjrxucvpdnkubuzjcpotyaupcuangmxfmyidqtbmantewu",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-adloaeqstgcsapvzogjlyonkxgipviusposgwtdbpanouqfhxv": {
        uniqueId: "control-adloaeqstgcsapvzogjlyonkxgipviusposgwtdbpanouqfhxv",
        type: "input",
        name: "patientLastName",
        label: "Last Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-aergrlcovevuubdmqoibtrbxzswkcgqcelrxgtisnvtqavctgy": {
        uniqueId: "control-aergrlcovevuubdmqoibtrbxzswkcgqcelrxgtisnvtqavctgy",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-aplvpohmfnastjuyhizzlxgsppencuizzyieunvatgtiwseisx": {
        uniqueId: "control-aplvpohmfnastjuyhizzlxgsppencuizzyieunvatgtiwseisx",
        type: "date",
        name: null,
        label: "Birth Date",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up h-date-30",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-atlzuwjsoewjkemojwwbiaumhlmuuqurpvmgkojtfkjvdtatfa": {
        uniqueId: "control-atlzuwjsoewjkemojwwbiaumhlmuuqurpvmgkojtfkjvdtatfa",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-auzftayhhzojfgydsyxvmelndmulpgkxyzmpmtlvaiycsupjqj": {
        uniqueId: "control-auzftayhhzojfgydsyxvmelndmulpgkxyzmpmtlvaiycsupjqj",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-bcfvtgkfvhukgvqlagfkrgxjdudfkhxjobnffuymrlnckypuid": {
        uniqueId: "control-bcfvtgkfvhukgvqlagfkrgxjdudfkhxjobnffuymrlnckypuid",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-biwbyzjjjcecpkgckmmpapzvgqjseoasvqwgydmyuoyhksxfes": {
        uniqueId: "control-biwbyzjjjcecpkgckmmpapzvgqjseoasvqwgydmyuoyhksxfes",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-boghqpgieqlwhyrmxxkslwikidrspahtszldlyhjwmmfiwcbxe": {
        uniqueId: "control-boghqpgieqlwhyrmxxkslwikidrspahtszldlyhjwmmfiwcbxe",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-brpvbqgvlplrzyramybmxivwvvsdoioidcdggnozzvoftvmujk": {
        uniqueId: "control-brpvbqgvlplrzyramybmxivwvvsdoioidcdggnozzvoftvmujk",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-chmdbypgsnoxdnkonsprwwhbeejmixguziumrezkanhpgninmf": {
        uniqueId: "control-chmdbypgsnoxdnkonsprwwhbeejmixguziumrezkanhpgninmf",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-cicbzkgsbpkszaisoyvtfvkbcistfpdyrwkvlqziejklqtvpkq": {
        uniqueId: "control-cicbzkgsbpkszaisoyvtfvkbcistfpdyrwkvlqziejklqtvpkq",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ckyaudavtuoeapcqguquzctvhibopwwuhsvvjeyuauqqweuuja": {
        uniqueId: "control-ckyaudavtuoeapcqguquzctvhibopwwuhsvvjeyuauqqweuuja",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-cmuohpccgvoovsxgczrufrnxuqvsdlndydasgaowpjrfxuvqwi": {
        uniqueId: "control-cmuohpccgvoovsxgczrufrnxuqvsdlndydasgaowpjrfxuvqwi",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-djwpfeibyagmcotbbxmdxwgnupueresyczfjdrjupjbsmfosmz": {
        uniqueId: "control-djwpfeibyagmcotbbxmdxwgnupueresyczfjdrjupjbsmfosmz",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-dsdjiopedqnidwfaytxoesxkagyeoahtcodffnkisbstxlhjho": {
        uniqueId: "control-dsdjiopedqnidwfaytxoesxkagyeoahtcodffnkisbstxlhjho",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-dtwhxpokaymppflgcntyaihgdmivyhxaawmubndkawrnodyylq": {
        uniqueId: "control-dtwhxpokaymppflgcntyaihgdmivyhxaawmubndkawrnodyylq",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-eylfibmdlalxnlnfdrcxkfpmrugzqrogvxwiqxnwzrkodtkgbi": {
        uniqueId: "control-eylfibmdlalxnlnfdrcxkfpmrugzqrogvxwiqxnwzrkodtkgbi",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-eywdvubakvbymtkddpygzqahhgvmoiazopxbcfieujbgeiteav": {
        uniqueId: "control-eywdvubakvbymtkddpygzqahhgvmoiazopxbcfieujbgeiteav",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ffkmlrngdnpibqmsjfmwosukegmjzwuepmjvfshgtmasugwstp": {
        uniqueId: "control-ffkmlrngdnpibqmsjfmwosukegmjzwuepmjvfshgtmasugwstp",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-fkfcpcrpuyrledxodwssliojvqcezwrknadphufcszbvttlanp": {
        uniqueId: "control-fkfcpcrpuyrledxodwssliojvqcezwrknadphufcszbvttlanp",
        type: "input",
        name: "patientFirstName",
        label: "First Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-fouomqqrfulwhvbvywkwtigxmjlixxuomxdaxnyqsjxmfzgsaq": {
        uniqueId: "control-fouomqqrfulwhvbvywkwtigxmjlixxuomxdaxnyqsjxmfzgsaq",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-fpggysrvlqgnlffmbxwuizzqhnqgmtzpqtoidqrpvirlgpkqvv": {
        uniqueId: "control-fpggysrvlqgnlffmbxwuizzqhnqgmtzpqtoidqrpvirlgpkqvv",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ftumxjisajnzuknfxxzkcbrobskjsuaxthtthxqlzvmcflzjir": {
        uniqueId: "control-ftumxjisajnzuknfxxzkcbrobskjsuaxthtthxqlzvmcflzjir",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-fwbljkrsttervhqpgupbzpnduxntjcqlvqxgnghnhkpibhfngm": {
        uniqueId: "control-fwbljkrsttervhqpgupbzpnduxntjcqlvqxgnghnhkpibhfngm",
        type: "label",
        name: null,
        label: "CLIENT GOALS:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-gqjcagptaiokmuywuthgirhsgtdoupezwetrtiflyjnxruvohu": {
        uniqueId: "control-gqjcagptaiokmuywuthgirhsgtdoupezwetrtiflyjnxruvohu",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-gsfcytalqnmaqgtqfcqbduuxolqpsripdyqtomwyczieoselsj": {
        uniqueId: "control-gsfcytalqnmaqgtqfcqbduuxolqpsripdyqtomwyczieoselsj",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-hhsfneaiakidxqtancdsnwwqnqwvlczqqabzduqrpucyxqcqhe": {
        uniqueId: "control-hhsfneaiakidxqtancdsnwwqnqwvlczqqabzduqrpucyxqcqhe",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-himbrtidqyokuirzmprwfzyqnbccdvrmrqemiyljvrjjuccfxh": {
        uniqueId: "control-himbrtidqyokuirzmprwfzyqnbccdvrmrqemiyljvrjjuccfxh",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-htknqgmmbrwwynvzavfkufyoedvokmrrjcvqotycvssbwejjin": {
        uniqueId: "control-htknqgmmbrwwynvzavfkufyoedvokmrrjcvqotycvssbwejjin",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-htuiygomklpmmgaofgilbbmljaswbeyhwwvcnhsbzoaqjlklit": {
        uniqueId: "control-htuiygomklpmmgaofgilbbmljaswbeyhwwvcnhsbzoaqjlklit",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ifntwosopttndivmtyayoynwgqkkwuyrpacoryewoohqmjjlwk": {
        uniqueId: "control-ifntwosopttndivmtyayoynwgqkkwuyrpacoryewoohqmjjlwk",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-iiindkqyuruuyaeqmiekplgtzoplyeztpcprglqfykudyqpkdu": {
        uniqueId: "control-iiindkqyuruuyaeqmiekplgtzoplyeztpcprglqfykudyqpkdu",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ilezubrfuueurilxadpccjdwkhahzxxcrkupxjbydgitlibvwi": {
        uniqueId: "control-ilezubrfuueurilxadpccjdwkhahzxxcrkupxjbydgitlibvwi",
        type: "radio",
        name: null,
        label: "Medical Decisions",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-iptlucywkspavfmhupyymkyuvoxazyhscpsyvabxmesoqkjkyu": {
        uniqueId: "control-iptlucywkspavfmhupyymkyuvoxazyhscpsyvabxmesoqkjkyu",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-itbgoeqpfkxeaycdcuetlunzxzkmmnzsnuhglqrcfvbtbhbldz": {
        uniqueId: "control-itbgoeqpfkxeaycdcuetlunzxzkmmnzsnuhglqrcfvbtbhbldz",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-iwglxpuvcdbvgoezwtwxhurfclgzwgrhihjysicolnezrnonba": {
        uniqueId: "control-iwglxpuvcdbvgoezwtwxhurfclgzwgrhihjysicolnezrnonba",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-jfslbfopglxyhuewojxkfrddjttwqdgfmwvnshdfvxfnmlwxxp": {
        uniqueId: "control-jfslbfopglxyhuewojxkfrddjttwqdgfmwvnshdfvxfnmlwxxp",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-jnpeiosdafmzuatfiguzhkcrxjpgrmwihdwiiosjlacvcunvah": {
        uniqueId: "control-jnpeiosdafmzuatfiguzhkcrxjpgrmwihdwiiosjlacvcunvah",
        type: "input",
        name: null,
        label: "Relationship to client:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-jumgrcnabhcipetoekfkhrvpopfgcjnmzglricllafjykgmqef": {
        uniqueId: "control-jumgrcnabhcipetoekfkhrvpopfgcjnmzglricllafjykgmqef",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-kehbzvxplemqatooedppbjwxdqshvsjfsfqbcadqyaggaqigro": {
        uniqueId: "control-kehbzvxplemqatooedppbjwxdqshvsjfsfqbcadqyaggaqigro",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-kgeqnnoflhzfxzaiavjiqpanwzoedqurlehvtfrulmipvekxfu": {
        uniqueId: "control-kgeqnnoflhzfxzaiavjiqpanwzoedqurlehvtfrulmipvekxfu",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-kjksqraktkxzclnacvjcwxzjrcromkhinmosccqdqyzjecapng": {
        uniqueId: "control-kjksqraktkxzclnacvjcwxzjrcromkhinmosccqdqyzjecapng",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-klfcqejxrhfipqkytzmimvxdsutfvxelpmexyyqajlutwbkjtq": {
        uniqueId: "control-klfcqejxrhfipqkytzmimvxdsutfvxelpmexyyqajlutwbkjtq",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-kqxezpzebbtxycfprjkoflxbmryartpthqbrgdorhqbwcpixse": {
        uniqueId: "control-kqxezpzebbtxycfprjkoflxbmryartpthqbrgdorhqbwcpixse",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-krroccvvmjfkkfwxwsjdxxerqvfmijiggcicgsojbbwzampxgl": {
        uniqueId: "control-krroccvvmjfkkfwxwsjdxxerqvfmijiggcicgsojbbwzampxgl",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-kshqofhddebgfdgtxvvkyfqhvewaeowhairjkvtbrtjzpsswah": {
        uniqueId: "control-kshqofhddebgfdgtxvvkyfqhvewaeowhairjkvtbrtjzpsswah",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-kthpqnycztufsyhtfknhtiyldlbkdtjqkznpajuslxiifkamof": {
        uniqueId: "control-kthpqnycztufsyhtfknhtiyldlbkdtjqkznpajuslxiifkamof",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ktjzmklzdfmbbwrfutnchxojglcbxttnvwsycyqppehqdzmqvf": {
        uniqueId: "control-ktjzmklzdfmbbwrfutnchxojglcbxttnvwsycyqppehqdzmqvf",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-kveydtxozmisgwfphuglwfvifcmyojfshgmyoncbhdrdbpyoyd": {
        uniqueId: "control-kveydtxozmisgwfphuglwfvifcmyojfshgmyoncbhdrdbpyoyd",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-kvffneptphhkcwurumsavctshxugusnfsgpgsellefznlbanvq": {
        uniqueId: "control-kvffneptphhkcwurumsavctshxugusnfsgpgsellefznlbanvq",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-kxjiadfgodjyexqjxgenjpaeiwixlnnpdbaoouibyiopeurxqn": {
        uniqueId: "control-kxjiadfgodjyexqjxgenjpaeiwixlnnpdbaoouibyiopeurxqn",
        type: "label",
        name: null,
        label: "CLIENT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-kyzwzzufzsksogknbvibjoouaypfrrzuyyeprylvfrfzhgsvhe": {
        uniqueId: "control-kyzwzzufzsksogknbvibjoouaypfrrzuyyeprylvfrfzhgsvhe",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-mbdqrbinndbxyjmtnyfbjfdxctcgvobubhvjuiwdbnnwfuqioc": {
        uniqueId: "control-mbdqrbinndbxyjmtnyfbjfdxctcgvobubhvjuiwdbnnwfuqioc",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-mhovqrbixgjkzsmpahtlfswspwsyofhvgmeevolfgqwzahercn": {
        uniqueId: "control-mhovqrbixgjkzsmpahtlfswspwsyofhvgmeevolfgqwzahercn",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-mjwctgmsyidnmmvacvukkopcxkmlpyxjkilgbrjaaonrvflysa": {
        uniqueId: "control-mjwctgmsyidnmmvacvukkopcxkmlpyxjkilgbrjaaonrvflysa",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-nejukeqeqblnsbtxjidwbaolsqqoypawvimegvtwgaqzvntzqw": {
        uniqueId: "control-nejukeqeqblnsbtxjidwbaolsqqoypawvimegvtwgaqzvntzqw",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-njugjgzozspiyblpxmhmlwtqucfejbqlmxgwfdkvrlwnzcjzqf": {
        uniqueId: "control-njugjgzozspiyblpxmhmlwtqucfejbqlmxgwfdkvrlwnzcjzqf",
        type: "input",
        name: null,
        label: "Title",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-nlivblmeihlstitcrojzuwtmbbezapfctspioiftskthfjakxw": {
        uniqueId: "control-nlivblmeihlstitcrojzuwtmbbezapfctspioiftskthfjakxw",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-nngrfmndhmhvvadbbmrmzenrgtpherksaeqdmxgtrpiyammwju": {
        uniqueId: "control-nngrfmndhmhvvadbbmrmzenrgtpherksaeqdmxgtrpiyammwju",
        type: "radio",
        name: null,
        label: "Gender",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Male", value: "Male" },
          { text: "Female", value: "Female" },
        ],
      },
      "control-ntqueoqdlujzithfrsmrbapcbxtltsfqqgjkqrmwcyqnhojfnb": {
        uniqueId: "control-ntqueoqdlujzithfrsmrbapcbxtltsfqqgjkqrmwcyqnhojfnb",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-oifkknbixivjcafklvlzycramrtvcoppoypkpklnqvcxzjfbwo": {
        uniqueId: "control-oifkknbixivjcafklvlzycramrtvcoppoypkpklnqvcxzjfbwo",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-oisjaedgtcgajunzbspnegllllvtreztbsizltpumacptdisxf": {
        uniqueId: "control-oisjaedgtcgajunzbspnegllllvtreztbsizltpumacptdisxf",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-omaoouqcuwwrygwocpkbxaqoptlyeeymieofhtwsqpwvkbrnue": {
        uniqueId: "control-omaoouqcuwwrygwocpkbxaqoptlyeeymieofhtwsqpwvkbrnue",
        type: "radio",
        name: null,
        label: "Medical Decisions",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-omwutpuxguxffkhhtonlmztuftjxwgqiotbhronxgywkorhzti": {
        uniqueId: "control-omwutpuxguxffkhhtonlmztuftjxwgqiotbhronxgywkorhzti",
        type: "date",
        name: null,
        label: "Birth Date",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up h-date-30",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-omxrzmftyidaxoyaytcofsnxuzhezsrtaathopudxhdqnvrlob": {
        uniqueId: "control-omxrzmftyidaxoyaytcofsnxuzhezsrtaathopudxhdqnvrlob",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-oqgaiuagylegpwzeojlhqckiqeaetlvcvyxmuyvifqhaksuriq": {
        uniqueId: "control-oqgaiuagylegpwzeojlhqckiqeaetlvcvyxmuyvifqhaksuriq",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-oqvjepvvgkwunhdfgjkiysbptaedbypkydvblttdvsyqswgdhw": {
        uniqueId: "control-oqvjepvvgkwunhdfgjkiysbptaedbypkydvblttdvsyqswgdhw",
        type: "input",
        name: null,
        label: "Relationship",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-pcyikunfkftwxzrjutbizgygshxloztawkblnwbjzynnhnxdet": {
        uniqueId: "control-pcyikunfkftwxzrjutbizgygshxloztawkblnwbjzynnhnxdet",
        type: "input",
        name: "patientFirstName",
        label: "First Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-pdbtrysmcywqlhtzgugenzqxerifeicpwwktyjoabuyvpmuxkz": {
        uniqueId: "control-pdbtrysmcywqlhtzgugenzqxerifeicpwwktyjoabuyvpmuxkz",
        type: "input",
        name: "patientLastName",
        label: "Last Name:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-pjenqijbruyvwdggmfvbsnzqdmyvcqhnyjsutwinmtffqcmtlz": {
        uniqueId: "control-pjenqijbruyvwdggmfvbsnzqdmyvcqhnyjsutwinmtffqcmtlz",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-pknegxhhhdzhxlqqhiftlriwggnctkrdwkbgomzeflumiijuab": {
        uniqueId: "control-pknegxhhhdzhxlqqhiftlriwggnctkrdwkbgomzeflumiijuab",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-pmgxhwsasesgswzpzfgdveojvyqiitmtonffwxbnyzluoodssj": {
        uniqueId: "control-pmgxhwsasesgswzpzfgdveojvyqiitmtonffwxbnyzluoodssj",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-pnppozbszvuppdcrnanacqdhblsijkabvsrjeijwwijtqrxyjk": {
        uniqueId: "control-pnppozbszvuppdcrnanacqdhblsijkabvsrjeijwwijtqrxyjk",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-pnsdaibuxoyneivzghkwowutjgqivmtxcshhuwijztoekulwok": {
        uniqueId: "control-pnsdaibuxoyneivzghkwowutjgqivmtxcshhuwijztoekulwok",
        type: "date",
        name: null,
        label: "Birth Date",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up h-date-30",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-qvqdmbzfijjipytsegvwtnccjfkoaobaqzaqjgkhwsivzewxia": {
        uniqueId: "control-qvqdmbzfijjipytsegvwtnccjfkoaobaqzaqjgkhwsivzewxia",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-qxmttuxntmbsztvabponikfqywnwxpftzndtrqnamdtcbknatm": {
        uniqueId: "control-qxmttuxntmbsztvabponikfqywnwxpftzndtrqnamdtcbknatm",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-rtqkibjptnmagkwnvtbkysgahidpsnrjwpiwlbsusbkjxfuqax": {
        uniqueId: "control-rtqkibjptnmagkwnvtbkysgahidpsnrjwpiwlbsusbkjxfuqax",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-rxeigzxumbrlmdwmpcwlyrkdgiwtssywabfyugnzmefvlktgbq": {
        uniqueId: "control-rxeigzxumbrlmdwmpcwlyrkdgiwtssywabfyugnzmefvlktgbq",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-savycqdjzfwpoijwkqqzcsgyoyzekgvsxeorufbsvctrrxhdky": {
        uniqueId: "control-savycqdjzfwpoijwkqqzcsgyoyzekgvsxeorufbsvctrrxhdky",
        type: "input",
        name: null,
        label: "Relationship to client:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-sfpfybjaavktcsqceixdfjfzfzrifefofhoaihxemhuukdlqka": {
        uniqueId: "control-sfpfybjaavktcsqceixdfjfzfzrifefofhoaihxemhuukdlqka",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-sgkeuzcqgygiyxdmidhzaqvjsvjwkrhetjlstouhfrcuofsjsg": {
        uniqueId: "control-sgkeuzcqgygiyxdmidhzaqvjsvjwkrhetjlstouhfrcuofsjsg",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-skbctxgmqafxouerkpcmohnibtdwxxextfymfmyrtsctkwdmzr": {
        uniqueId: "control-skbctxgmqafxouerkpcmohnibtdwxxextfymfmyrtsctkwdmzr",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-svuxesveoyodnbbvwyjmxwkdeejdigczhalxyelmzsijrijhrv": {
        uniqueId: "control-svuxesveoyodnbbvwyjmxwkdeejdigczhalxyelmzsijrijhrv",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-swpjuhxrazqgtoamalaumaohzniwuduflwmyhnkpebfdhyoegy": {
        uniqueId: "control-swpjuhxrazqgtoamalaumaohzniwuduflwmyhnkpebfdhyoegy",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-sxnvzuvopnqmyyicuvhkxekhtmzlcmkutdfmanbsyrsxjripcp": {
        uniqueId: "control-sxnvzuvopnqmyyicuvhkxekhtmzlcmkutdfmanbsyrsxjripcp",
        type: "label",
        name: null,
        label: "AUTHORIZATIONS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-tfflvsfkjptkvfhiffimsnyisuphojmbesbdlwgytvjfpqhcos": {
        uniqueId: "control-tfflvsfkjptkvfhiffimsnyisuphojmbesbdlwgytvjfpqhcos",
        type: "date",
        name: null,
        label: "Date of Accident",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up h-date-30",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 0,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-thbcrsdkhbxbbcxjpfpfueowekkendkvqjaezfdbkqoqaaewcj": {
        uniqueId: "control-thbcrsdkhbxbbcxjpfpfueowekkendkvqjaezfdbkqoqaaewcj",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-touruylrrmluwgkytijtyrxwvlirowhxhukobaplwkdeoeuwhy": {
        uniqueId: "control-touruylrrmluwgkytijtyrxwvlirowhxhukobaplwkdeoeuwhy",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-tyicpaeqwhdytpwimyehoheiqpofnopryrpnkuwwnusdecaasl": {
        uniqueId: "control-tyicpaeqwhdytpwimyehoheiqpofnopryrpnkuwwnusdecaasl",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-tzdagkdsbvxljrngvuizdghwtghqgxgcfvgdatfwgjcstggjqe": {
        uniqueId: "control-tzdagkdsbvxljrngvuizdghwtghqgxgcfvgdatfwgjcstggjqe",
        type: "input",
        name: null,
        label: "Relationship",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-upwzywlbgsbgppmhliqsctrahrtcvtahetwnsuvuwezlbniank": {
        uniqueId: "control-upwzywlbgsbgppmhliqsctrahrtcvtahetwnsuvuwezlbniank",
        type: "label",
        name: null,
        label: "AUTHORIZATIONS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-usdellqllfglqbpiyvvinglcoihcvjpiyfjefbaqpfbuhsthou": {
        uniqueId: "control-usdellqllfglqbpiyvvinglcoihcvjpiyfjefbaqpfbuhsthou",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-vcvrxqeeyxvkmukglpmaxmcayudeigmyeqnxmghopzftyflkvd": {
        uniqueId: "control-vcvrxqeeyxvkmukglpmaxmcayudeigmyeqnxmghopzftyflkvd",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-vjvezohtphcycdlwubilrvpfvfgnympborshbqdnssbclculqt": {
        uniqueId: "control-vjvezohtphcycdlwubilrvpfvfgnympborshbqdnssbclculqt",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-vnoxvpwpjssbxmuqudnrnxmgqiroltqyuzpbzfgfsisggoemdy": {
        uniqueId: "control-vnoxvpwpjssbxmuqudnrnxmgqiroltqyuzpbzfgfsisggoemdy",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-wamvmpwawqlmbzstamyggbvttttydxiuskjwnsucizstcvbphi": {
        uniqueId: "control-wamvmpwawqlmbzstamyggbvttttydxiuskjwnsucizstcvbphi",
        type: "input",
        name: null,
        label: "Relationship to client:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-waxfocytiztkhpzninwjdlcispwtrgyzzemwyepbrqplrdplmf": {
        uniqueId: "control-waxfocytiztkhpzninwjdlcispwtrgyzzemwyepbrqplrdplmf",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-wdgtndmzwjzcjbkhwiifupedguqcpovafxzutyhtisvsrwpker": {
        uniqueId: "control-wdgtndmzwjzcjbkhwiifupedguqcpovafxzutyhtisvsrwpker",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-weszhcfhomwtgowlvjniexjofefitkwjwizlniwapcbeqcsdeh": {
        uniqueId: "control-weszhcfhomwtgowlvjniexjofefitkwjwizlniwapcbeqcsdeh",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-wgnmukkbqlzzuczkapmdhwzwxrltygfutqimsrhkggbxbyntah": {
        uniqueId: "control-wgnmukkbqlzzuczkapmdhwzwxrltygfutqimsrhkggbxbyntah",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-xyvcrnrcwqzvnetpqxlnegocgtddngwkyislzduyvotagiagjh": {
        uniqueId: "control-xyvcrnrcwqzvnetpqxlnegocgtddngwkyislzduyvotagiagjh",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ybrthjplbpvbbcxfxtuqiuozplfdjoqxyvhtpbzdbwbqgvvcru": {
        uniqueId: "control-ybrthjplbpvbbcxfxtuqiuozplfdjoqxyvhtpbzdbwbqgvvcru",
        type: "input",
        name: null,
        label: "Mobile Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-yialfetpdsqlumfptodraopxlnmppghhfaeqmhcdiowvfvkngj": {
        uniqueId: "control-yialfetpdsqlumfptodraopxlnmppghhfaeqmhcdiowvfvkngj",
        type: "input",
        name: null,
        label: "Home Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-yjyhbktnutcvqemrnfppkbncfclwdvvcvukqkkvskonqpzqovv": {
        uniqueId: "control-yjyhbktnutcvqemrnfppkbncfclwdvvcvukqkkvskonqpzqovv",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-yqvxsxdmlhrufhjukgovflkxfqyihcqzxbokiyxolfhuhdievb": {
        uniqueId: "control-yqvxsxdmlhrufhjukgovflkxfqyihcqzxbokiyxolfhuhdievb",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-yycjvnkzbmqhnbzbwmzkyxolfzinsgppbemhozffphmuqakqhv": {
        uniqueId: "control-yycjvnkzbmqhnbzbwmzkyxolfzinsgppbemhozffphmuqakqhv",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-yzhpvjdrahqjutzpiukeqgnjcvsxoflfxnjszuncesaarsjcpo": {
        uniqueId: "control-yzhpvjdrahqjutzpiukeqgnjcvsxoflfxnjszuncesaarsjcpo",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-zcgvqbexwjwcwjchmwfxezvcewblzibdabbljboculauuftpja": {
        uniqueId: "control-zcgvqbexwjwcwjchmwfxezvcewblzibdabbljboculauuftpja",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-zpznrggpxllweeinfpainggppkkjeagnmyjtevnfdcyjilelrc": {
        uniqueId: "control-zpznrggpxllweeinfpainggppkkjeagnmyjtevnfdcyjilelrc",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-zqjtgkfzkcfizzmtopaxaorgqrtkcbzzkjzgyuyfkrtbcalrjo": {
        uniqueId: "control-zqjtgkfzkcfizzmtopaxaorgqrtkcbzzkjzgyuyfkrtbcalrjo",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-zrvagkwlqqxvqnasqukiwkyoregtszebqdjfbdnswvvnchwmsj": {
        uniqueId: "control-zrvagkwlqqxvqnasqukiwkyoregtszebqdjfbdnswvvnchwmsj",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-zyuhnzvxohrqfaftlxzersjsxcmthrcnvcsdlovcmmpulmpuom": {
        uniqueId: "control-zyuhnzvxohrqfaftlxzersjsxcmthrcnvcsdlovcmmpulmpuom",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
    },
  },
  "BT Client Info Form": {
    formConfig: {
      headline: null,
      subHeadline: null,
      isShowHeadline: false,
      renderFormTag: false,
      formActionURL: null,
      formMethod: "POST",
    },
    sections: {
      "section-1bcc3c41-9181-4a74-820a-499fd194fa7c": {
        uniqueId: "section-1bcc3c41-9181-4a74-820a-499fd194fa7c",
        headline: "Pandemic related Information",
        headlineAdditionalClass: "text-bold border-bottom-bolder-black mt-4",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 12,
        type: "normal",
        rows: [],
        controls: [
          "control-d72c315d-13fc-4d64-b812-dfb8fb1755e2",
          "control-889ed644-b81a-4767-a32c-f1a76ec14644",
        ],
      },
      "section-57d506ee-b4e2-414b-a4af-0e6fb8e1a0bb": {
        uniqueId: "section-57d506ee-b4e2-414b-a4af-0e6fb8e1a0bb",
        headline: "Responsible Parties Contact Info",
        headlineAdditionalClass: "text-bold border-bottom-bolder-black mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 2,
        type: "normal",
        rows: [],
        controls: [],
      },
      "section-a3bdf429-b318-49c7-ae64-3bd350e25dd3": {
        uniqueId: "section-a3bdf429-b318-49c7-ae64-3bd350e25dd3",
        headline: "BEHAVIOUR SUPPORT PLAN",
        headlineAdditionalClass: "text-bold border-bottom-bold",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 10,
        type: "normal",
        rows: [],
        controls: [
          "control-c915e002-8671-47df-8cb9-c86d8f62fbc6",
          "control-a7ee7c74-a301-4d7e-bc92-aec62960bcb4",
        ],
      },
      "section-df71fed9-dd2f-409c-817f-c6e732b83714": {
        uniqueId: "section-df71fed9-dd2f-409c-817f-c6e732b83714",
        headline: "Programming Goals (BSP)",
        headlineAdditionalClass: "text-bold border-bottom-bolder-black mt-5",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 9,
        type: "normal",
        rows: [],
        controls: [],
      },
      "section-gvyrxucjzjtdlzwygawlrphucvxfklfawjrshzheydpummlyrm": {
        uniqueId: "section-gvyrxucjzjtdlzwygawlrphucvxfklfawjrshzheydpummlyrm",
        headline: "Contact 5",
        headlineAdditionalClass: "border-bottom-bold mt-4",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 7,
        type: "normal",
        rows: [],
        controls: [
          "control-dwhrujazcceythlbgyvpxfvzfqnyauboxutvspiqsjinbsgvam",
          "control-sjwxlviykaqqunegwhbbcpkaxvttxiakczlnzlmqfaqnswceom",
          "control-pjkzdmlwormlwtbppnyvvgihdsghkocitpitfmjwrbxbzrtazn",
          "control-sfyoliqyvrfelinyikkzqfhtlsuhladfbdnrptoqlsbfajacfz",
          "control-qfxxflqmrlsuiwnywvtwfpjiwcuenehdbuemmwndwkpvbeezcw",
          "control-dmldyjhswnziamrubedkkpdpnraulelxfbhujrzcoswgpcioow",
          "control-hgjayxoorymzlvxykeliprzffgbfzxtwzqxlfeznaycsoaljbl",
          "control-xthpzmocygevfbnfzddjstvxkwirzvybjhzkzciuturfezlghb",
          "control-wxdowylwvfkvcthcfdgybiphcfrfefxmupthksuxxbvrtzmktt",
          "control-qcecsvfudmsqjuqhlkxijlweqhkijvmwrqkeohfaktwfthqpsb",
          "control-twifjrrpkldggyqedkvmybxsgoxlnacvhdaqcottbmjrtmnkhn",
          "control-zmaoqszcglbioerdahulljjddiwtozvijbckalsmtowdieesiq",
          "control-solpwnnvnniockorcaelivmurfgaackdwgyetkggdgbqftowqg",
          "control-geaydzozisesgmujjtbgdogdtdlufjyfntncrxczygjebgejsa",
          "control-ggbzxzcqrqynaetjdwwhfjxiangrtsfpgbmwocpfsfpvklltke",
          "control-brhvfzbkwufdmygbaolpbtvndxyxsotswiykzcmvlhgpmzqijy",
          "control-ueivtprgigavlanebuklnzyxkjufvuzeawpoejsckepljmsvuc",
          "control-waicvcjaybmhgaxspcpqqdvsdtsaazfunyerfdeffwjhixzdux",
          "control-ybaugjklbgzsgeqzqgxnvcspeyeizyjwbwxxwtgyhcvpfkzvuu",
        ],
      },
      "section-ipfitclbbdzluvufnjbtkohumcuhpsjxfkrjtwhaojvijhaigb": {
        uniqueId: "section-ipfitclbbdzluvufnjbtkohumcuhpsjxfkrjtwhaojvijhaigb",
        headline: "Contact 2",
        headlineAdditionalClass: "border-bottom-bold mt-4",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 4,
        type: "normal",
        rows: [],
        controls: [
          "control-eonoqsqhmhkwuacjmpcvkiqbniazbooezdgmlrczszkkbtxdqw",
          "control-nykecirvsnvuuasticwoyggboalnhcwazqondzscgbrfnumwvb",
          "control-zwjblkcaulfkjrukabbsaxiaqogqoqqgimjtjmuvjivmslqyhm",
          "control-vcayentelxopqjjjbqlwboevlgoembliixztejxknaalynnnih",
          "control-xhhcjsfdlsdfnxzyrajdtdpjyabpijchdpwhneadgyihnwirzp",
          "control-hamedmykjrqewqyayufpfeldcmsqhczebalryjrofqlrunvbaa",
          "control-vaxcogwfluufyjvtlcwvzuggnahdieapmbityrbvtemyuunlkb",
          "control-ikdmurknxvrqremjodpkkfydwgmkzerynrdpnezwdevxfqdutt",
          "control-dwglrpyyflhqmafxtpaafkfltijttchwiutilpuvyzrktvqlhi",
          "control-ldileoredvcqtksybefzpilenjodmwmunemnnjspletjozmhnr",
          "control-jxtsecmdolqkwtzgawljzluzfrbmubyrylzxofnatnqzcvkadg",
          "control-naohsxgtdqyqcsebavemwvayybmdzfsujhmighajtsgvmhajhr",
          "control-suduzbgwekuohmmspqgmfltuldljnocerramajnznjhnajrygt",
          "control-wtpovfuwkvwhzizuicvigjobbuulxvjxupstimsuvrsblltwnu",
          "control-bkvxmiyuxlgrpeidcydevzzibwlznrjevlkxymwkvkxypncxso",
          "control-zdcksxxehpcoscfgoikfgipskymnnonvjokpzsxaqegkubhjjw",
        ],
      },
      "section-kfhccqirnxdrtfzkeshhvosewqfyzjlelliobvaqqzrqzjjivg": {
        uniqueId: "section-kfhccqirnxdrtfzkeshhvosewqfyzjlelliobvaqqzrqzjjivg",
        headline: "Contact 1",
        headlineAdditionalClass: "border-bottom-bold",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 3,
        type: "normal",
        rows: [],
        controls: [
          "control-hymzdiymwvpyzxxnpvrfegavrrormwktvaabzxzwqvvcrftwhr",
          "control-wqelfnxeywkjqeflrhshubddtngxfylauwcelmlcsoqbongkxd",
          "control-wmzjksjwotoraxyzmiueltwbvrvoenptjmguuvyufbskgqbmzg",
          "control-eqqeejcddlvcyawpazicaihymexahtkzybdlbekvecmomnzvmh",
          "control-hxtcvvgssizzzsegfaiepjuahygxongfqswythsqajoolplzwu",
          "control-vigbvxlmmtsanspnmmnuleuileuhkxkahfcextmwyexmxmpxoj",
          "control-mfipsrxszuudcbwwcgtfpotghlfofqwrmmbypvmkpmviiszeqf",
          "control-mrvvhqqidnidlcvssckikxjjbvrcffvcbmyeumzfpqnjqvqcbr",
          "control-twbcnkbhcjunayrgjdjvthgfgewpahbwqsibayljlpirihimat",
          "control-xsfbtwizqucwyxjnvvyevnsgyhvqcteishtidcmgalwvyvbcha",
          "control-gwxnrgygavhyhrqseyloicjxercpjftdaiqitmmawkakmjgqid",
          "control-nppwdgfpwbcfvyamdeiuevfzzkymmunybxznmabgswdkcmvhhp",
          "control-mdtfmizhtvlsrxsaltjjihecoyvqeoowezyyjosjflsaemowxw",
          "control-xerbtonnsbwyfjjxamnmarrxzjrsrhlmbhdoajqhkujflsktrf",
          "control-pqntisfpoprgtbsthflkbnhzbprmvehcjjxajapszimxzskenf",
          "control-mszyewhyyudhmzaqkpocdrwaonihtlqvyxxdtlqxxvytydqxzg",
        ],
      },
      "section-mckpymwtupguyoadbbxjtupuiypvojcgqswkpzfvrltqpdsevl": {
        uniqueId: "section-mckpymwtupguyoadbbxjtupuiypvojcgqswkpzfvrltqpdsevl",
        headline: null,
        headlineAdditionalClass: null,
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: false,
        sortOrder: 11,
        type: "normal",
        rows: [],
        controls: [
          "control-zsargkaxsdjmejjkcopexeartuwvjumraftbmkzvbyerdvkzpr",
          "control-xcqiqbkmcnjqklgvdomglchvgrubobfjjrglawsjbqvfrrtjjx",
          "control-pkhwqwlauqcideouyppyzpoixhostqlzgzwjuwlggmyvovdypb",
          "control-eff784e3-b8e4-46b3-9d5c-8a918cc6b1b2",
          "control-d841bc04-4f71-480c-9f75-85b56bdb1634",
          "control-f88f38da-f0fa-48d4-9522-eaf78e6416b2",
          "control-1806593e-a57b-473d-afac-45a78189ba4e",
          "control-454ac15b-4a8c-4793-9ab3-ede5d72dea9a",
          "control-a4ffaf9b-ae57-4abe-b409-eb3f504969b5",
          "control-5800e382-32a2-4f26-a32a-aa6362ed7a91",
          "control-772690a5-d948-4e81-9867-d5f86500c873",
          "control-637c8c5d-4276-482a-811d-fe00d9eea003",
          "control-5f033af3-2565-42ba-89a4-0428fbd49cad",
          "control-8eeda840-747e-44b1-8663-cb9d79095519",
          "control-0cda7c94-e5bf-4282-8c0d-68f7e882a0d2",
          "control-40fdfdca-8cd0-46aa-afcf-9246d34f3004",
        ],
      },
      "section-nyksnxfspdjvocsqsqgboeazossxcwdcqbzgfmlboreuqvufml": {
        uniqueId: "section-nyksnxfspdjvocsqsqgboeazossxcwdcqbzgfmlboreuqvufml",
        headline: "Contact 4",
        headlineAdditionalClass: "border-bottom-bold mt-4",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 6,
        type: "normal",
        rows: [],
        controls: [
          "control-eonoqsqhmhkwuacjmpcvkiqbniazbooezdgmlrczszkkbtxdqw",
          "control-nykecirvsnvuuasticwoyggboalnhcwazqondzscgbrfnumwvb",
          "control-zwjblkcaulfkjrukabbsaxiaqogqoqqgimjtjmuvjivmslqyhm",
          "control-vcayentelxopqjjjbqlwboevlgoembliixztejxknaalynnnih",
          "control-xhhcjsfdlsdfnxzyrajdtdpjyabpijchdpwhneadgyihnwirzp",
          "control-hamedmykjrqewqyayufpfeldcmsqhczebalryjrofqlrunvbaa",
          "control-vaxcogwfluufyjvtlcwvzuggnahdieapmbityrbvtemyuunlkb",
          "control-ikdmurknxvrqremjodpkkfydwgmkzerynrdpnezwdevxfqdutt",
          "control-dwglrpyyflhqmafxtpaafkfltijttchwiutilpuvyzrktvqlhi",
          "control-ldileoredvcqtksybefzpilenjodmwmunemnnjspletjozmhnr",
          "control-jxtsecmdolqkwtzgawljzluzfrbmubyrylzxofnatnqzcvkadg",
          "control-naohsxgtdqyqcsebavemwvayybmdzfsujhmighajtsgvmhajhr",
          "control-suduzbgwekuohmmspqgmfltuldljnocerramajnznjhnajrygt",
          "control-wtpovfuwkvwhzizuicvigjobbuulxvjxupstimsuvrsblltwnu",
          "control-bkvxmiyuxlgrpeidcydevzzibwlznrjevlkxymwkvkxypncxso",
          "control-zdcksxxehpcoscfgoikfgipskymnnonvjokpzsxaqegkubhjjw",
        ],
      },
      "section-pfsfgaxkdcywrutaufxandpueundaugfbsctakfncgcwsbqddo": {
        uniqueId: "section-pfsfgaxkdcywrutaufxandpueundaugfbsctakfncgcwsbqddo",
        headline: "Contact 3",
        headlineAdditionalClass: "border-bottom-bold mt-4",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 5,
        type: "normal",
        rows: [],
        controls: [
          "control-eonoqsqhmhkwuacjmpcvkiqbniazbooezdgmlrczszkkbtxdqw",
          "control-nykecirvsnvuuasticwoyggboalnhcwazqondzscgbrfnumwvb",
          "control-zwjblkcaulfkjrukabbsaxiaqogqoqqgimjtjmuvjivmslqyhm",
          "control-vcayentelxopqjjjbqlwboevlgoembliixztejxknaalynnnih",
          "control-xhhcjsfdlsdfnxzyrajdtdpjyabpijchdpwhneadgyihnwirzp",
          "control-hamedmykjrqewqyayufpfeldcmsqhczebalryjrofqlrunvbaa",
          "control-vaxcogwfluufyjvtlcwvzuggnahdieapmbityrbvtemyuunlkb",
          "control-ikdmurknxvrqremjodpkkfydwgmkzerynrdpnezwdevxfqdutt",
          "control-dwglrpyyflhqmafxtpaafkfltijttchwiutilpuvyzrktvqlhi",
          "control-ldileoredvcqtksybefzpilenjodmwmunemnnjspletjozmhnr",
          "control-jxtsecmdolqkwtzgawljzluzfrbmubyrylzxofnatnqzcvkadg",
          "control-naohsxgtdqyqcsebavemwvayybmdzfsujhmighajtsgvmhajhr",
          "control-suduzbgwekuohmmspqgmfltuldljnocerramajnznjhnajrygt",
          "control-wtpovfuwkvwhzizuicvigjobbuulxvjxupstimsuvrsblltwnu",
          "control-bkvxmiyuxlgrpeidcydevzzibwlznrjevlkxymwkvkxypncxso",
          "control-zdcksxxehpcoscfgoikfgipskymnnonvjokpzsxaqegkubhjjw",
        ],
      },
      "section-soxgsqdloifjwanljkutgjeaxvyxcpmchtsjkjhtglmwnecobr": {
        uniqueId: "section-soxgsqdloifjwanljkutgjeaxvyxcpmchtsjkjhtglmwnecobr",
        headline: "Contact 6",
        headlineAdditionalClass: "border-bottom-bold mt-4",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 8,
        type: "normal",
        rows: [],
        controls: [
          "control-dwhrujazcceythlbgyvpxfvzfqnyauboxutvspiqsjinbsgvam",
          "control-sjwxlviykaqqunegwhbbcpkaxvttxiakczlnzlmqfaqnswceom",
          "control-pjkzdmlwormlwtbppnyvvgihdsghkocitpitfmjwrbxbzrtazn",
          "control-sfyoliqyvrfelinyikkzqfhtlsuhladfbdnrptoqlsbfajacfz",
          "control-qfxxflqmrlsuiwnywvtwfpjiwcuenehdbuemmwndwkpvbeezcw",
          "control-dmldyjhswnziamrubedkkpdpnraulelxfbhujrzcoswgpcioow",
          "control-hgjayxoorymzlvxykeliprzffgbfzxtwzqxlfeznaycsoaljbl",
          "control-xthpzmocygevfbnfzddjstvxkwirzvybjhzkzciuturfezlghb",
          "control-wxdowylwvfkvcthcfdgybiphcfrfefxmupthksuxxbvrtzmktt",
          "control-qcecsvfudmsqjuqhlkxijlweqhkijvmwrqkeohfaktwfthqpsb",
          "control-twifjrrpkldggyqedkvmybxsgoxlnacvhdaqcottbmjrtmnkhn",
          "control-zmaoqszcglbioerdahulljjddiwtozvijbckalsmtowdieesiq",
          "control-solpwnnvnniockorcaelivmurfgaackdwgyetkggdgbqftowqg",
          "control-geaydzozisesgmujjtbgdogdtdlufjyfntncrxczygjebgejsa",
          "control-ggbzxzcqrqynaetjdwwhfjxiangrtsfpgbmwocpfsfpvklltke",
          "control-brhvfzbkwufdmygbaolpbtvndxyxsotswiykzcmvlhgpmzqijy",
          "control-ueivtprgigavlanebuklnzyxkjufvuzeawpoejsckepljmsvuc",
          "control-waicvcjaybmhgaxspcpqqdvsdtsaazfunyerfdeffwjhixzdux",
          "control-ybaugjklbgzsgeqzqgxnvcspeyeizyjwbwxxwtgyhcvpfkzvuu",
        ],
      },
      "section-vcpoyktzdqnurjawwlocndmiqaqpdidvqscehyusqjuxgrgwba": {
        uniqueId: "section-vcpoyktzdqnurjawwlocndmiqaqpdidvqscehyusqjuxgrgwba",
        headline: "Client Information",
        headlineAdditionalClass: "border-bottom-bold",
        subHeadline: null,
        subHeadlineAdditionalClass: null,
        isShowHeadline: true,
        sortOrder: 1,
        type: "normal",
        rows: [],
        controls: [
          "control-brusjdgotdnznsyyujeyowavetlsvlsllshptlaufbcdmqwiqx",
          "control-ziutzunosrythittfikhpvdjkifyagnmepighxlxqfcnctmxbn",
          "control-dmrmijeidbvghbztajoswellktjkwtopbftcphpstvyaxvzyxr",
          "control-ulwkdohbxjflqyxpqpioknrnlnopekpkjmpxnkmiwltjftarji",
          "control-vpnvegvknbepquseaayxkrkddurmvqweyeyateslliouaspyif",
          "control-xrqblvectplmaciaritdhjsidhpoobkdibujxnyxrcpgbqqqlc",
          "control-qwugsrnbohlqtmccpfgglsfzsimjzvnimbiacwlgytuecsqwll",
          "control-xwsiynzeibuybzfqcnirccqsicdthvwsvcgfrqbnvgmjprskbs",
          "control-kxyweiqxglybzkzowfiyvwvemehfcqrdmvfekhfrzgunpfffte",
          "control-ngcvlynoezjgvxkabpmuprfbqkokyaftellyrkhjpcltvisabf",
          "control-ngdzfjzaopghtkzhninevoxskgzjdtukjzwhpxdxdrsxvjtmdp",
          "control-gvzcmgbvdnpczwyheuqiuxgoiphtoasovyypjztnlgtsbgadqe",
          "control-cbxxlpbgmsewxheunbztsalbfffhftfjuimxsobcjakxgetkko",
          "control-pavtautnczumusiknzxhlwogctzlbqpilrxcwbipfbjzwbhgkm",
          "control-gnvzkdgqmztegofagyueelncxakhzsgvoawynfogjklgqzrhua",
          "control-b228eb94-57ee-44a8-9747-8ce65ebc9c06",
          "control-95434215-aa11-47f1-b917-0470a86db148",
          "control-c9d1a7b0-d87b-4f0b-95a4-6e6845daee04",
          "control-2aa9c167-df1a-4857-99aa-5cd42a24e6bb",
          "control-e0a06df2-02e8-4129-ba42-720cf22573bd",
          "control-361f8384-5fb7-40ac-9f4e-d3bdbf35028e",
          "control-016a529b-b524-4c7c-8396-1ae5e97d5e54",
          "control-ab9f5fd9-d55b-4edf-8e4e-f8de8e8e2008",
          "control-8a38c590-9f3e-4020-8e27-cce205215e19",
          "control-d1c902f2-bf65-4379-aaa8-2bd165a55d98",
        ],
      },
    },
    rows: {},
    controls: {
      "control-016a529b-b524-4c7c-8396-1ae5e97d5e54": {
        uniqueId: "control-016a529b-b524-4c7c-8396-1ae5e97d5e54",
        type: "label",
        name: null,
        label: "BILLING AND SPECIAL DIRECTIVES",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-0cda7c94-e5bf-4282-8c0d-68f7e882a0d2": {
        uniqueId: "control-0cda7c94-e5bf-4282-8c0d-68f7e882a0d2",
        type: "radio",
        name: null,
        label: "Were all the above goals Approved By the Treatment team?",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "white-bg border no-padding mt-4",
        additionalFieldClass: "pl-3 ml-3",
        additionalLabelClass: "ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-1806593e-a57b-473d-afac-45a78189ba4e": {
        uniqueId: "control-1806593e-a57b-473d-afac-45a78189ba4e",
        type: "text",
        name: null,
        label: "Client Goal # 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize mt-4",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-2aa9c167-df1a-4857-99aa-5cd42a24e6bb": {
        uniqueId: "control-2aa9c167-df1a-4857-99aa-5cd42a24e6bb",
        type: "text",
        name: null,
        label: "Important Information pertaining to client's behaviour:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-361f8384-5fb7-40ac-9f4e-d3bdbf35028e": {
        uniqueId: "control-361f8384-5fb7-40ac-9f4e-d3bdbf35028e",
        type: "dropDown",
        name: null,
        label: "Behaviour Therapy Assistant:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-40fdfdca-8cd0-46aa-afcf-9246d34f3004": {
        uniqueId: "control-40fdfdca-8cd0-46aa-afcf-9246d34f3004",
        type: "date",
        name: null,
        label: "Date when goals were last updated:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 0,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-454ac15b-4a8c-4793-9ab3-ede5d72dea9a": {
        uniqueId: "control-454ac15b-4a8c-4793-9ab3-ede5d72dea9a",
        type: "dropDown",
        name: null,
        label: "TYPE OF GOAL:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-4",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-5800e382-32a2-4f26-a32a-aa6362ed7a91": {
        uniqueId: "control-5800e382-32a2-4f26-a32a-aa6362ed7a91",
        type: "dropDown",
        name: null,
        label: "TYPE OF GOAL:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-4",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-5f033af3-2565-42ba-89a4-0428fbd49cad": {
        uniqueId: "control-5f033af3-2565-42ba-89a4-0428fbd49cad",
        type: "text",
        name: null,
        label: "Client Goal # 5",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize mt-4",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-637c8c5d-4276-482a-811d-fe00d9eea003": {
        uniqueId: "control-637c8c5d-4276-482a-811d-fe00d9eea003",
        type: "dropDown",
        name: null,
        label: "TYPE OF GOAL:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-4",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-772690a5-d948-4e81-9867-d5f86500c873": {
        uniqueId: "control-772690a5-d948-4e81-9867-d5f86500c873",
        type: "text",
        name: null,
        label: "Client Goal # 4",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize mt-4",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-889ed644-b81a-4767-a32c-f1a76ec14644": {
        uniqueId: "control-889ed644-b81a-4767-a32c-f1a76ec14644",
        type: "text",
        name: null,
        label:
          "Any special direction related to the client as it relates to the Pandemic",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-8a38c590-9f3e-4020-8e27-cce205215e19": {
        uniqueId: "control-8a38c590-9f3e-4020-8e27-cce205215e19",
        type: "label",
        name: null,
        label: "FUNCTIONAL BEHAVIOUR ASSESSMENT COMPLETED:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-8eeda840-747e-44b1-8663-cb9d79095519": {
        uniqueId: "control-8eeda840-747e-44b1-8663-cb9d79095519",
        type: "dropDown",
        name: null,
        label: "Behaviour Consultant",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-4",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-95434215-aa11-47f1-b917-0470a86db148": {
        uniqueId: "control-95434215-aa11-47f1-b917-0470a86db148",
        type: "text",
        name: null,
        label: "Description of diagnosis and/or Reason for Referral:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 no-resize",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1 pr-3",
        defaultValue: null,
        validations: [],
        rows: 3,
      },
      "control-a4ffaf9b-ae57-4abe-b409-eb3f504969b5": {
        uniqueId: "control-a4ffaf9b-ae57-4abe-b409-eb3f504969b5",
        type: "text",
        name: null,
        label: "Client Goal # 3",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize mt-4",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-a7ee7c74-a301-4d7e-bc92-aec62960bcb4": {
        uniqueId: "control-a7ee7c74-a301-4d7e-bc92-aec62960bcb4",
        type: "checkbox",
        name: null,
        label: "Data Requirements:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: null,
        additionalLabelClass: "m-0",
        defaultValue: null,
        validations: [],
        displayMode: "line",
        position: "left",
        items: [
          { text: "ABC Data", value: "ABC Data" },
          {
            text: "Specialized Data as directed by BCBA",
            value: "Specialized Data as directed by BCBA",
          },
          {
            text: "Baseline data (direct or indirect observation)",
            value: "Baseline data (direct or indirect observation)",
          },
        ],
      },
      "control-ab9f5fd9-d55b-4edf-8e4e-f8de8e8e2008": {
        uniqueId: "control-ab9f5fd9-d55b-4edf-8e4e-f8de8e8e2008",
        type: "text",
        name: null,
        label: "Approved Funding for Client:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-b228eb94-57ee-44a8-9747-8ce65ebc9c06": {
        uniqueId: "control-b228eb94-57ee-44a8-9747-8ce65ebc9c06",
        type: "label",
        name: null,
        label: "REASON FOR REFERRAL:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-5",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-c915e002-8671-47df-8cb9-c86d8f62fbc6": {
        uniqueId: "control-c915e002-8671-47df-8cb9-c86d8f62fbc6",
        type: "text",
        name: null,
        label: "Behaviour Support Plan Overview:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize sticky-up-10",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-c9d1a7b0-d87b-4f0b-95a4-6e6845daee04": {
        uniqueId: "control-c9d1a7b0-d87b-4f0b-95a4-6e6845daee04",
        type: "checkbox",
        name: null,
        label: "Documentation Obtained:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 pb-2",
        defaultValue: null,
        validations: [],
        displayMode: "line",
        position: "left",
        items: [
          {
            text: "Consent Forms ( General and Virtual)",
            value: "Consent Forms ( General and Virtual)",
          },
          {
            text: "Background Docs ( Assessments, Diagnoses, Treatment history)",
            value:
              "Background Docs ( Assessments, Diagnoses, Treatment history)",
          },
          {
            text: "Signed Service Agreement",
            value: "Signed Service Agreement",
          },
        ],
      },
      "control-d1c902f2-bf65-4379-aaa8-2bd165a55d98": {
        uniqueId: "control-d1c902f2-bf65-4379-aaa8-2bd165a55d98",
        type: "date",
        name: null,
        label: "Date of FBA",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 0,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-d72c315d-13fc-4d64-b812-dfb8fb1755e2": {
        uniqueId: "control-d72c315d-13fc-4d64-b812-dfb8fb1755e2",
        type: "label",
        name: null,
        label:
          "PANDEMIC INFORMATION (ANCHOR PROTOCOLS, PUBLIC HEALTH INFORMATION OR SPECIFICS RELATED TO CLIENT'S NEEDS)",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "no-padding mt-4 mb-4",
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-d841bc04-4f71-480c-9f75-85b56bdb1634": {
        uniqueId: "control-d841bc04-4f71-480c-9f75-85b56bdb1634",
        type: "text",
        name: null,
        label: "Client Goal # 1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 no-resize mt-4",
        additionalFieldClass: "border-0",
        additionalLabelClass: "pl-3 pt-1",
        defaultValue: null,
        validations: [],
        rows: 2,
      },
      "control-e0a06df2-02e8-4129-ba42-720cf22573bd": {
        uniqueId: "control-e0a06df2-02e8-4129-ba42-720cf22573bd",
        type: "dropDown",
        name: null,
        label: "Behaviour Consultant:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "api",
        multiple: false,
        items: [],
        apiURL: "http://{getProvidersAPI}",
        apiTextKey: "name",
        apiValueKey: "id",
      },
      "control-eff784e3-b8e4-46b3-9d5c-8a918cc6b1b2": {
        uniqueId: "control-eff784e3-b8e4-46b3-9d5c-8a918cc6b1b2",
        type: "dropDown",
        name: null,
        label: "TYPE OF GOAL:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-4",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-f88f38da-f0fa-48d4-9522-eaf78e6416b2": {
        uniqueId: "control-f88f38da-f0fa-48d4-9522-eaf78e6416b2",
        type: "dropDown",
        name: null,
        label: "TYPE OF GOAL:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass:
          "no-padding no-radius white-bg border input-border-0 mt-4",
        additionalFieldClass: "border-0 pt-1 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-afkpvtjcjpbferxrlslijomuvtadvvowzbdninwxsocbaqakgs": {
        uniqueId: "control-afkpvtjcjpbferxrlslijomuvtadvvowzbdninwxsocbaqakgs",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-amryvgqzgfofwexvkkdpsidvvucvetjewonywcpphofzitsjvs": {
        uniqueId: "control-amryvgqzgfofwexvkkdpsidvvucvetjewonywcpphofzitsjvs",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-axjsqihxdogmnzqlzqpzzrazvjqyoduwxyrsdpjdsoxjpjvfcr": {
        uniqueId: "control-axjsqihxdogmnzqlzqpzzrazvjqyoduwxyrsdpjdsoxjpjvfcr",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-beswjzysqsjyjzmonxnbpexduvgumbkfpzfnhcrazqzqdwznxr": {
        uniqueId: "control-beswjzysqsjyjzmonxnbpexduvgumbkfpzfnhcrazqzqdwznxr",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-bkvxmiyuxlgrpeidcydevzzibwlznrjevlkxymwkvkxypncxso": {
        uniqueId: "control-bkvxmiyuxlgrpeidcydevzzibwlznrjevlkxymwkvkxypncxso",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-brhvfzbkwufdmygbaolpbtvndxyxsotswiykzcmvlhgpmzqijy": {
        uniqueId: "control-brhvfzbkwufdmygbaolpbtvndxyxsotswiykzcmvlhgpmzqijy",
        type: "radio",
        name: null,
        label: "Medical Decisions",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-brusjdgotdnznsyyujeyowavetlsvlsllshptlaufbcdmqwiqx": {
        uniqueId: "control-brusjdgotdnznsyyujeyowavetlsvlsllshptlaufbcdmqwiqx",
        type: "label",
        name: null,
        label: "CLIENT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-bxzrjkexusbxncbmfozjalhyudwtulljhxhtjtaebiykgpdziz": {
        uniqueId: "control-bxzrjkexusbxncbmfozjalhyudwtulljhxhtjtaebiykgpdziz",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-cbdhecdpgpnwuezglfooqxspgotmewudwrfothjiwpkmlhberg": {
        uniqueId: "control-cbdhecdpgpnwuezglfooqxspgotmewudwrfothjiwpkmlhberg",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-cbxxlpbgmsewxheunbztsalbfffhftfjuimxsobcjakxgetkko": {
        uniqueId: "control-cbxxlpbgmsewxheunbztsalbfffhftfjuimxsobcjakxgetkko",
        type: "date",
        name: null,
        label: "Date of Accident",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up h-date-30",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 0,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-ceqeenwrgdrdryljtzbmxkdggnzltpctduynojntrqfakvwokt": {
        uniqueId: "control-ceqeenwrgdrdryljtzbmxkdggnzltpctduynojntrqfakvwokt",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-cjkwtxcqdppybsyalxrzktusvenkigssbxrkdrjzztfyncdrov": {
        uniqueId: "control-cjkwtxcqdppybsyalxrzktusvenkigssbxrkdrjzztfyncdrov",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-cnhliksizzsyzkyxbvxrjkyiaafwdpckibkczqagoitgakpolo": {
        uniqueId: "control-cnhliksizzsyzkyxbvxrjkyiaafwdpckibkczqagoitgakpolo",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-dgeqxelhkndljfayhtgvubtqjbemlivacjvapcpmgnnzrlbkfr": {
        uniqueId: "control-dgeqxelhkndljfayhtgvubtqjbemlivacjvapcpmgnnzrlbkfr",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-dljpojkvmfwmjbrlwgrwyjjiwxzvwsjhhzrvvfbzmfbsutcraa": {
        uniqueId: "control-dljpojkvmfwmjbrlwgrwyjjiwxzvwsjhhzrvvfbzmfbsutcraa",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-dmldyjhswnziamrubedkkpdpnraulelxfbhujrzcoswgpcioow": {
        uniqueId: "control-dmldyjhswnziamrubedkkpdpnraulelxfbhujrzcoswgpcioow",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-dmrmijeidbvghbztajoswellktjkwtopbftcphpstvyaxvzyxr": {
        uniqueId: "control-dmrmijeidbvghbztajoswellktjkwtopbftcphpstvyaxvzyxr",
        type: "input",
        name: null,
        label: "Title",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-1 md-layout-item md-size-5",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-dwglrpyyflhqmafxtpaafkfltijttchwiutilpuvyzrktvqlhi": {
        uniqueId: "control-dwglrpyyflhqmafxtpaafkfltijttchwiutilpuvyzrktvqlhi",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-dwhrujazcceythlbgyvpxfvzfqnyauboxutvspiqsjinbsgvam": {
        uniqueId: "control-dwhrujazcceythlbgyvpxfvzfqnyauboxutvspiqsjinbsgvam",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-emguhmttehslvpmclpeuypljrrepoovangxfiqwmempfsabnpg": {
        uniqueId: "control-emguhmttehslvpmclpeuypljrrepoovangxfiqwmempfsabnpg",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-eonoqsqhmhkwuacjmpcvkiqbniazbooezdgmlrczszkkbtxdqw": {
        uniqueId: "control-eonoqsqhmhkwuacjmpcvkiqbniazbooezdgmlrczszkkbtxdqw",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-eqqeejcddlvcyawpazicaihymexahtkzybdlbekvecmomnzvmh": {
        uniqueId: "control-eqqeejcddlvcyawpazicaihymexahtkzybdlbekvecmomnzvmh",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-ewcnmtpmojggpstffdrbjoaidvwcimyrktxormfzkwnalohkox": {
        uniqueId: "control-ewcnmtpmojggpstffdrbjoaidvwcimyrktxormfzkwnalohkox",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-gdcsyddozllrrxenkgvktuddiyhwegtcnypwsxzneiktactozf": {
        uniqueId: "control-gdcsyddozllrrxenkgvktuddiyhwegtcnypwsxzneiktactozf",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-geaydzozisesgmujjtbgdogdtdlufjyfntncrxczygjebgejsa": {
        uniqueId: "control-geaydzozisesgmujjtbgdogdtdlufjyfntncrxczygjebgejsa",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-ggbzxzcqrqynaetjdwwhfjxiangrtsfpgbmwocpfsfpvklltke": {
        uniqueId: "control-ggbzxzcqrqynaetjdwwhfjxiangrtsfpgbmwocpfsfpvklltke",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-gjdjozrfdaepmmglajqbkgrxzfhmtpamznwcaosazewjoedagg": {
        uniqueId: "control-gjdjozrfdaepmmglajqbkgrxzfhmtpamznwcaosazewjoedagg",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-gnvzkdgqmztegofagyueelncxakhzsgvoawynfogjklgqzrhua": {
        uniqueId: "control-gnvzkdgqmztegofagyueelncxakhzsgvoawynfogjklgqzrhua",
        type: "input",
        name: null,
        label: "Mobile Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-gvzcmgbvdnpczwyheuqiuxgoiphtoasovyypjztnlgtsbgadqe": {
        uniqueId: "control-gvzcmgbvdnpczwyheuqiuxgoiphtoasovyypjztnlgtsbgadqe",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-gwxnrgygavhyhrqseyloicjxercpjftdaiqitmmawkakmjgqid": {
        uniqueId: "control-gwxnrgygavhyhrqseyloicjxercpjftdaiqitmmawkakmjgqid",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-hamedmykjrqewqyayufpfeldcmsqhczebalryjrofqlrunvbaa": {
        uniqueId: "control-hamedmykjrqewqyayufpfeldcmsqhczebalryjrofqlrunvbaa",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-hbuaybdhmbryxjvxkiqgnhwbztyunmkjrpecktpivcijtwcdqu": {
        uniqueId: "control-hbuaybdhmbryxjvxkiqgnhwbztyunmkjrpecktpivcijtwcdqu",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-hgjayxoorymzlvxykeliprzffgbfzxtwzqxlfeznaycsoaljbl": {
        uniqueId: "control-hgjayxoorymzlvxykeliprzffgbfzxtwzqxlfeznaycsoaljbl",
        type: "date",
        name: null,
        label: "Birth Date",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up h-date-30",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-hjqtisrqcartmvajokcdeszurwgrpdrzkubzdduyepmtoxplot": {
        uniqueId: "control-hjqtisrqcartmvajokcdeszurwgrpdrzkubzdduyepmtoxplot",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-hxtcvvgssizzzsegfaiepjuahygxongfqswythsqajoolplzwu": {
        uniqueId: "control-hxtcvvgssizzzsegfaiepjuahygxongfqswythsqajoolplzwu",
        type: "input",
        name: null,
        label: "Relationship to client:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-hymzdiymwvpyzxxnpvrfegavrrormwktvaabzxzwqvvcrftwhr": {
        uniqueId: "control-hymzdiymwvpyzxxnpvrfegavrrormwktvaabzxzwqvvcrftwhr",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ikdmurknxvrqremjodpkkfydwgmkzerynrdpnezwdevxfqdutt": {
        uniqueId: "control-ikdmurknxvrqremjodpkkfydwgmkzerynrdpnezwdevxfqdutt",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-jbhgwfjlgpvqiuyeaeabiintsrdlvzxajlzomoflwasmqylkwf": {
        uniqueId: "control-jbhgwfjlgpvqiuyeaeabiintsrdlvzxajlzomoflwasmqylkwf",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-jnskyhbmwhtnnyroimzeptzinunknovwvsveqzgfygjxlqtkla": {
        uniqueId: "control-jnskyhbmwhtnnyroimzeptzinunknovwvsveqzgfygjxlqtkla",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-jxtsecmdolqkwtzgawljzluzfrbmubyrylzxofnatnqzcvkadg": {
        uniqueId: "control-jxtsecmdolqkwtzgawljzluzfrbmubyrylzxofnatnqzcvkadg",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-kihqcrwulydprnjsvzucximnheiteubwtfaiibivnetblzumzt": {
        uniqueId: "control-kihqcrwulydprnjsvzucximnheiteubwtfaiibivnetblzumzt",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-kxyweiqxglybzkzowfiyvwvemehfcqrdmvfekhfrzgunpfffte": {
        uniqueId: "control-kxyweiqxglybzkzowfiyvwvemehfcqrdmvfekhfrzgunpfffte",
        type: "radio",
        name: null,
        label: "Gender",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Male", value: "Male" },
          { text: "Female", value: "Female" },
        ],
      },
      "control-kzbvlusznxhiunrdrcshybivfzbnugikhplxvhvtgqbrykerot": {
        uniqueId: "control-kzbvlusznxhiunrdrcshybivfzbnugikhplxvhvtgqbrykerot",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ldileoredvcqtksybefzpilenjodmwmunemnnjspletjozmhnr": {
        uniqueId: "control-ldileoredvcqtksybefzpilenjodmwmunemnnjspletjozmhnr",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-lfuxbliuhadnvrqlgepcjzbdyuzzqdiwataqntkrraqbpjpogp": {
        uniqueId: "control-lfuxbliuhadnvrqlgepcjzbdyuzzqdiwataqntkrraqbpjpogp",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-lnvfgvzmmknqrjznsvuomrbzvjwmazloivuqpotbhlitnpwpmr": {
        uniqueId: "control-lnvfgvzmmknqrjznsvuomrbzvjwmazloivuqpotbhlitnpwpmr",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-lpoclwloemkkkwrubxclciszwiurefnpcdmnaucmioejnfbrkm": {
        uniqueId: "control-lpoclwloemkkkwrubxclciszwiurefnpcdmnaucmioejnfbrkm",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-mdtfmizhtvlsrxsaltjjihecoyvqeoowezyyjosjflsaemowxw": {
        uniqueId: "control-mdtfmizhtvlsrxsaltjjihecoyvqeoowezyyjosjflsaemowxw",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-mfipsrxszuudcbwwcgtfpotghlfofqwrmmbypvmkpmviiszeqf": {
        uniqueId: "control-mfipsrxszuudcbwwcgtfpotghlfofqwrmmbypvmkpmviiszeqf",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-mrvvhqqidnidlcvssckikxjjbvrcffvcbmyeumzfpqnjqvqcbr": {
        uniqueId: "control-mrvvhqqidnidlcvssckikxjjbvrcffvcbmyeumzfpqnjqvqcbr",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-mszyewhyyudhmzaqkpocdrwaonihtlqvyxxdtlqxxvytydqxzg": {
        uniqueId: "control-mszyewhyyudhmzaqkpocdrwaonihtlqvyxxdtlqxxvytydqxzg",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-mvmrvqignnvxunflykpujynzflboihxixxidsgwcfpiqdwgaww": {
        uniqueId: "control-mvmrvqignnvxunflykpujynzflboihxixxidsgwcfpiqdwgaww",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-naohsxgtdqyqcsebavemwvayybmdzfsujhmighajtsgvmhajhr": {
        uniqueId: "control-naohsxgtdqyqcsebavemwvayybmdzfsujhmighajtsgvmhajhr",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-ngcvlynoezjgvxkabpmuprfbqkokyaftellyrkhjpcltvisabf": {
        uniqueId: "control-ngcvlynoezjgvxkabpmuprfbqkokyaftellyrkhjpcltvisabf",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ngdzfjzaopghtkzhninevoxskgzjdtukjzwhpxdxdrsxvjtmdp": {
        uniqueId: "control-ngdzfjzaopghtkzhninevoxskgzjdtukjzwhpxdxdrsxvjtmdp",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-nlxfbsgugvngdbhyoumlukamdlkssbrajqpykmunwbdcrnyiwn": {
        uniqueId: "control-nlxfbsgugvngdbhyoumlukamdlkssbrajqpykmunwbdcrnyiwn",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-nohnccqtnrenwifzwayttatydjgnfqlkyblkqipppzaijmqewh": {
        uniqueId: "control-nohnccqtnrenwifzwayttatydjgnfqlkyblkqipppzaijmqewh",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-nppwdgfpwbcfvyamdeiuevfzzkymmunybxznmabgswdkcmvhhp": {
        uniqueId: "control-nppwdgfpwbcfvyamdeiuevfzzkymmunybxznmabgswdkcmvhhp",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-npsnqnwduuerllqihnbdtmlvkvcxqokipjtlbmwvpnpmywdctw": {
        uniqueId: "control-npsnqnwduuerllqihnbdtmlvkvcxqokipjtlbmwvpnpmywdctw",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-nykecirvsnvuuasticwoyggboalnhcwazqondzscgbrfnumwvb": {
        uniqueId: "control-nykecirvsnvuuasticwoyggboalnhcwazqondzscgbrfnumwvb",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ochxrmbppzfrbjrqtmpizgfvkttbnfejnpkflvopbblihpiswn": {
        uniqueId: "control-ochxrmbppzfrbjrqtmpizgfvkttbnfejnpkflvopbblihpiswn",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-pavtautnczumusiknzxhlwogctzlbqpilrxcwbipfbjzwbhgkm": {
        uniqueId: "control-pavtautnczumusiknzxhlwogctzlbqpilrxcwbipfbjzwbhgkm",
        type: "input",
        name: null,
        label: "Home Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-pjkzdmlwormlwtbppnyvvgihdsghkocitpitfmjwrbxbzrtazn": {
        uniqueId: "control-pjkzdmlwormlwtbppnyvvgihdsghkocitpitfmjwrbxbzrtazn",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-pkhwqwlauqcideouyppyzpoixhostqlzgzwjuwlggmyvovdypb": {
        uniqueId: "control-pkhwqwlauqcideouyppyzpoixhostqlzgzwjuwlggmyvovdypb",
        type: "input",
        name: "patientLastName",
        label: "Last Name:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-pnifulxbruwjidqjsggrayzzegpisumialqnlchsolpprndiid": {
        uniqueId: "control-pnifulxbruwjidqjsggrayzzegpisumialqnlchsolpprndiid",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-pqntisfpoprgtbsthflkbnhzbprmvehcjjxajapszimxzskenf": {
        uniqueId: "control-pqntisfpoprgtbsthflkbnhzbprmvehcjjxajapszimxzskenf",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-psdjabvtxyvbbadbmbeisqpaqaeoxwvwsnjtdgzgktyljngsxr": {
        uniqueId: "control-psdjabvtxyvbbadbmbeisqpaqaeoxwvwsnjtdgzgktyljngsxr",
        type: "radio",
        name: null,
        label: "Medical Decisions",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-qarzfxqkntlkhbfreblzbfrhnbenhrqhdqxlupggllnhsggayl": {
        uniqueId: "control-qarzfxqkntlkhbfreblzbfrhnbenhrqhdqxlupggllnhsggayl",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-qcecsvfudmsqjuqhlkxijlweqhkijvmwrqkeohfaktwfthqpsb": {
        uniqueId: "control-qcecsvfudmsqjuqhlkxijlweqhkijvmwrqkeohfaktwfthqpsb",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-qfxxflqmrlsuiwnywvtwfpjiwcuenehdbuemmwndwkpvbeezcw": {
        uniqueId: "control-qfxxflqmrlsuiwnywvtwfpjiwcuenehdbuemmwndwkpvbeezcw",
        type: "input",
        name: null,
        label: "Relationship",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-qinfszjpiwvkabrsckfsehtadqqtnikshsgfwfqgtdvmqsqxsd": {
        uniqueId: "control-qinfszjpiwvkabrsckfsehtadqqtnikshsgfwfqgtdvmqsqxsd",
        type: "date",
        name: null,
        label: "Birth Date",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up h-date-30",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-qjzspooeoxmiafrlvbvdcwmdixfccoeanvfikjehochniiccym": {
        uniqueId: "control-qjzspooeoxmiafrlvbvdcwmdixfccoeanvfikjehochniiccym",
        type: "input",
        name: null,
        label: "Relationship to client:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-qwawljinbtlfmdbhzxqmonpbmdgkfmxrggqrzqhwucgtxmuiwk": {
        uniqueId: "control-qwawljinbtlfmdbhzxqmonpbmdgkfmxrggqrzqhwucgtxmuiwk",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-qwugsrnbohlqtmccpfgglsfzsimjzvnimbiacwlgytuecsqwll": {
        uniqueId: "control-qwugsrnbohlqtmccpfgglsfzsimjzvnimbiacwlgytuecsqwll",
        type: "date",
        name: null,
        label: "Birth Date",
        subLabel: null,
        isShowLabel: true,
        placeholderText: "YYYY-MM-DD",
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up h-date-30",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        format: "YYYY-MM-DD",
        firstDay: 1,
        numberOfMonths: 1,
        numberOfColumns: 1,
        minDate: null,
        maxDate: null,
        singleMode: true,
        minDays: 0,
        maxDays: 0,
        returnType: "format",
      },
      "control-qywogcrsntrdkpwadwevkiquvnyozeleafmlhqlnedukivlber": {
        uniqueId: "control-qywogcrsntrdkpwadwevkiquvnyozeleafmlhqlnedukivlber",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-rbbogncxkgptynuhslzankckuhgtbfbxfkofneuqtovusgqvfj": {
        uniqueId: "control-rbbogncxkgptynuhslzankckuhgtbfbxfkofneuqtovusgqvfj",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-rmbtxnemgnfldxhxtlaheojqdptyntkidlejynargpducmcksc": {
        uniqueId: "control-rmbtxnemgnfldxhxtlaheojqdptyntkidlejynargpducmcksc",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-sfyoliqyvrfelinyikkzqfhtlsuhladfbdnrptoqlsbfajacfz": {
        uniqueId: "control-sfyoliqyvrfelinyikkzqfhtlsuhladfbdnrptoqlsbfajacfz",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-sjwxlviykaqqunegwhbbcpkaxvttxiakczlnzlmqfaqnswceom": {
        uniqueId: "control-sjwxlviykaqqunegwhbbcpkaxvttxiakczlnzlmqfaqnswceom",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-solpwnnvnniockorcaelivmurfgaackdwgyetkggdgbqftowqg": {
        uniqueId: "control-solpwnnvnniockorcaelivmurfgaackdwgyetkggdgbqftowqg",
        type: "label",
        name: null,
        label: "AUTHORIZATIONS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-srrdelfhcomrjvpjblxlgkyluhqnymqujrpnpbeoduavpxfnqq": {
        uniqueId: "control-srrdelfhcomrjvpjblxlgkyluhqnymqujrpnpbeoduavpxfnqq",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ssdmybcqdlnotamxagofmorcixnzdqcebhuzopfroywhujggtu": {
        uniqueId: "control-ssdmybcqdlnotamxagofmorcixnzdqcebhuzopfroywhujggtu",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-suduzbgwekuohmmspqgmfltuldljnocerramajnznjhnajrygt": {
        uniqueId: "control-suduzbgwekuohmmspqgmfltuldljnocerramajnznjhnajrygt",
        type: "input",
        name: null,
        label: "Number",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-synjcobdrdzqptivxepauijrlrpszypcmsrlxbjebllfbngqnp": {
        uniqueId: "control-synjcobdrdzqptivxepauijrlrpszypcmsrlxbjebllfbngqnp",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-tblhuncnbnymctduyrnrjslxfjmghgcjcrwgyqnzszpbtippmd": {
        uniqueId: "control-tblhuncnbnymctduyrnrjslxfjmghgcjcrwgyqnzszpbtippmd",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ttmqzpfquhpyksygktyyvzcagkamjnrcqchgfzfaiegchqvrlk": {
        uniqueId: "control-ttmqzpfquhpyksygktyyvzcagkamjnrcqchgfzfaiegchqvrlk",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-twbcnkbhcjunayrgjdjvthgfgewpahbwqsibayljlpirihimat": {
        uniqueId: "control-twbcnkbhcjunayrgjdjvthgfgewpahbwqsibayljlpirihimat",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-twifjrrpkldggyqedkvmybxsgoxlnacvhdaqcottbmjrtmnkhn": {
        uniqueId: "control-twifjrrpkldggyqedkvmybxsgoxlnacvhdaqcottbmjrtmnkhn",
        type: "input",
        name: null,
        label: "Zip Code",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-txhlcwignjxwmquykrdpzvdrizrmceaxfhmvsmynpaxfiggzep": {
        uniqueId: "control-txhlcwignjxwmquykrdpzvdrizrmceaxfhmvsmynpaxfiggzep",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-tzorramnodkzebnjfgkzahyevlzfzqhvbwvdsgbohxgkbxfdrt": {
        uniqueId: "control-tzorramnodkzebnjfgkzahyevlzfzqhvbwvdsgbohxgkbxfdrt",
        type: "label",
        name: null,
        label: "AUTHORIZATIONS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-ubcxzfdfjctqeehalfbhefrfdmllulempxxhjyvfkxlrlzloix": {
        uniqueId: "control-ubcxzfdfjctqeehalfbhefrfdmllulempxxhjyvfkxlrlzloix",
        type: "input",
        name: null,
        label: "Relationship to client:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ueivtprgigavlanebuklnzyxkjufvuzeawpoejsckepljmsvuc": {
        uniqueId: "control-ueivtprgigavlanebuklnzyxkjufvuzeawpoejsckepljmsvuc",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-ulwkdohbxjflqyxpqpioknrnlnopekpkjmpxnkmiwltjftarji": {
        uniqueId: "control-ulwkdohbxjflqyxpqpioknrnlnopekpkjmpxnkmiwltjftarji",
        type: "input",
        name: "patientFirstName",
        label: "First Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-uqgmvmyjcbvaulptlmqmqtpjtwccxcjfwxjzevemqagasedyjv": {
        uniqueId: "control-uqgmvmyjcbvaulptlmqmqtpjtwccxcjfwxjzevemqagasedyjv",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-vaxcogwfluufyjvtlcwvzuggnahdieapmbityrbvtemyuunlkb": {
        uniqueId: "control-vaxcogwfluufyjvtlcwvzuggnahdieapmbityrbvtemyuunlkb",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-vcayentelxopqjjjbqlwboevlgoembliixztejxknaalynnnih": {
        uniqueId: "control-vcayentelxopqjjjbqlwboevlgoembliixztejxknaalynnnih",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-vgydcqvaxefyosjbdgpsehzvaesaxeficctivjbamlohyqtfqb": {
        uniqueId: "control-vgydcqvaxefyosjbdgpsehzvaesaxeficctivjbamlohyqtfqb",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-vhglwtywziijkngbrmhakkvfmokjgxiowrwsjiuxmjfazuxswe": {
        uniqueId: "control-vhglwtywziijkngbrmhakkvfmokjgxiowrwsjiuxmjfazuxswe",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-vigbvxlmmtsanspnmmnuleuileuhkxkahfcextmwyexmxmpxoj": {
        uniqueId: "control-vigbvxlmmtsanspnmmnuleuileuhkxkahfcextmwyexmxmpxoj",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-vpnvegvknbepquseaayxkrkddurmvqweyeyateslliouaspyif": {
        uniqueId: "control-vpnvegvknbepquseaayxkrkddurmvqweyeyateslliouaspyif",
        type: "input",
        name: "patientLastName",
        label: "Last Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-vwdgvthbjwtwmciomdgnzqxhiumajunfndsgpfspopweqmpwoq": {
        uniqueId: "control-vwdgvthbjwtwmciomdgnzqxhiumajunfndsgpfspopweqmpwoq",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-waicvcjaybmhgaxspcpqqdvsdtsaazfunyerfdeffwjhixzdux": {
        uniqueId: "control-waicvcjaybmhgaxspcpqqdvsdtsaazfunyerfdeffwjhixzdux",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-wfpqcwvxomilpgalhknlkozzfaahurwrskeijaewyxhvzcbxse": {
        uniqueId: "control-wfpqcwvxomilpgalhknlkozzfaahurwrskeijaewyxhvzcbxse",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-wmzjksjwotoraxyzmiueltwbvrvoenptjmguuvyufbskgqbmzg": {
        uniqueId: "control-wmzjksjwotoraxyzmiueltwbvrvoenptjmguuvyufbskgqbmzg",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-wozyaxllsrlhkooodbdyvjgjgqowybpuuezwtluomdemeqkwtg": {
        uniqueId: "control-wozyaxllsrlhkooodbdyvjgjgqowybpuuezwtluomdemeqkwtg",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-wqelfnxeywkjqeflrhshubddtngxfylauwcelmlcsoqbongkxd": {
        uniqueId: "control-wqelfnxeywkjqeflrhshubddtngxfylauwcelmlcsoqbongkxd",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-wtclenujmbgajwwmdojwyzuciomflizwuqcgksjpnwvyidxbct": {
        uniqueId: "control-wtclenujmbgajwwmdojwyzuciomflizwuqcgksjpnwvyidxbct",
        type: "label",
        name: null,
        label: "ADDRESS",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-wtpovfuwkvwhzizuicvigjobbuulxvjxupstimsuvrsblltwnu": {
        uniqueId: "control-wtpovfuwkvwhzizuicvigjobbuulxvjxupstimsuvrsblltwnu",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-wuofkjoubfcqabquiuwjrlzniyvmrsiobahrapugaxhxadghwh": {
        uniqueId: "control-wuofkjoubfcqabquiuwjrlzniyvmrsiobahrapugaxhxadghwh",
        type: "dropDown",
        name: null,
        label: "State",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-3 md-layout-item md-size-25",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3 sticky-up-1",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-wxdowylwvfkvcthcfdgybiphcfrfefxmupthksuxxbvrtzmktt": {
        uniqueId: "control-wxdowylwvfkvcthcfdgybiphcfrfefxmupthksuxxbvrtzmktt",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-xawtopxymdztzmtngbvxmyalpvmnlnfmnqimzzddtcvxcnxbzc": {
        uniqueId: "control-xawtopxymdztzmtngbvxmyalpvmnlnfmnqimzzddtcvxcnxbzc",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-xcqiqbkmcnjqklgvdomglchvgrubobfjjrglawsjbqvfrrtjjx": {
        uniqueId: "control-xcqiqbkmcnjqklgvdomglchvgrubobfjjrglawsjbqvfrrtjjx",
        type: "input",
        name: "patientFirstName",
        label: "First Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "no-padding m-0 no-radius white-bg border input-border-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-xerbtonnsbwyfjjxamnmarrxzjrsrhlmbhdoajqhkujflsktrf": {
        uniqueId: "control-xerbtonnsbwyfjjxamnmarrxzjrsrhlmbhdoajqhkujflsktrf",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-xhhcjsfdlsdfnxzyrajdtdpjyabpijchdpwhneadgyihnwirzp": {
        uniqueId: "control-xhhcjsfdlsdfnxzyrajdtdpjyabpijchdpwhneadgyihnwirzp",
        type: "input",
        name: null,
        label: "Relationship to client:",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-xraappwnhcxyetecwcnjrnwkjncmgphkbiawngjoytoqfqgyjh": {
        uniqueId: "control-xraappwnhcxyetecwcnjrnwkjncmgphkbiawngjoytoqfqgyjh",
        type: "label",
        name: null,
        label: "PERSONAL INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-xrqblvectplmaciaritdhjsidhpoobkdibujxnyxrcpgbqqqlc": {
        uniqueId: "control-xrqblvectplmaciaritdhjsidhpoobkdibujxnyxrcpgbqqqlc",
        type: "input",
        name: null,
        label: "Line1",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-0 pl-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-xsfbtwizqucwyxjnvvyevnsgyhvqcteishtidcmgalwvyvbcha": {
        uniqueId: "control-xsfbtwizqucwyxjnvvyevnsgyhvqcteishtidcmgalwvyvbcha",
        type: "emptyBlock",
        name: null,
        label: "Empty Block",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: null,
        additionalFieldClass: null,
        additionalLabelClass: null,
        defaultValue: null,
        validations: [],
      },
      "control-xthpzmocygevfbnfzddjstvxkwirzvybjhzkzciuturfezlghb": {
        uniqueId: "control-xthpzmocygevfbnfzddjstvxkwirzvybjhzkzciuturfezlghb",
        type: "input",
        name: null,
        label: "City",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-xwsiynzeibuybzfqcnirccqsicdthvwsvcgfrqbnvgmjprskbs": {
        uniqueId: "control-xwsiynzeibuybzfqcnirccqsicdthvwsvcgfrqbnvgmjprskbs",
        type: "input",
        name: null,
        label: "Line 2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-ybaugjklbgzsgeqzqgxnvcspeyeizyjwbwxxwtgyhcvpfkzvuu": {
        uniqueId: "control-ybaugjklbgzsgeqzqgxnvcspeyeizyjwbwxxwtgyhcvpfkzvuu",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-yfiiqryvfqohdehzrligiuvvivnkhijarbwyadzgwihhdrjoln": {
        uniqueId: "control-yfiiqryvfqohdehzrligiuvvivnkhijarbwyadzgwihhdrjoln",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-yjmqdhqjazzmcarjhfliygzjmyxdvllphxcakgpnhlasqycxek": {
        uniqueId: "control-yjmqdhqjazzmcarjhfliygzjmyxdvllphxcakgpnhlasqycxek",
        type: "input",
        name: null,
        label: "Relationship",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-yjzpuqrkrnwhmwpygkgvhsoxxgelloaoiarilbyfqldnqjkfqx": {
        uniqueId: "control-yjzpuqrkrnwhmwpygkgvhsoxxgelloaoiarilbyfqldnqjkfqx",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-yxcnwoeemeijzupodqnnxvdntjprgqlvrqjiacpuonphtjkzwm": {
        uniqueId: "control-yxcnwoeemeijzupodqnnxvdntjprgqlvrqjiacpuonphtjkzwm",
        type: "dropDown",
        name: null,
        label: "Phone",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-yxczxujaurdebvzmgisgoqanzbzrtqevzpjpvzlzqioidwbzoj": {
        uniqueId: "control-yxczxujaurdebvzmgisgoqanzbzrtqevzpjpvzlzqioidwbzoj",
        type: "dropDown",
        name: null,
        label: "Phone2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-2 md-layout-item md-size-10",
        additionalContainerClass:
          "space-between m-0 white-bg control-border sticky-up-1 p-0",
        additionalFieldClass: "pt-0 pb-0 h-30 border-0",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
        dataMode: "list",
        multiple: false,
        items: [],
        apiURL: null,
        apiTextKey: "text",
        apiValueKey: "value",
      },
      "control-zdcksxxehpcoscfgoikfgipskymnnonvjokpzsxaqegkubhjjw": {
        uniqueId: "control-zdcksxxehpcoscfgoikfgipskymnnonvjokpzsxaqegkubhjjw",
        type: "input",
        name: null,
        label: "Number2",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-4 md-layout-item md-size-33",
        additionalContainerClass:
          "space-between m-0 white-bg control-border p-0 sticky-up-1 pr-3",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-zegizpefocaoxrcpzcglppisonyqrsimmgnxwvzqiefesqutyw": {
        uniqueId: "control-zegizpefocaoxrcpzcglppisonyqrsimmgnxwvzqiefesqutyw",
        type: "radio",
        name: null,
        label: "HIPAA Disclosure Authorization",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pl-3 pr-0 sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 pt-1 mb-0 ml-3",
        defaultValue: null,
        validations: [],
        displayMode: "next",
        position: "left",
        items: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
      },
      "control-ziutzunosrythittfikhpvdjkifyagnmepighxlxqfcnctmxbn": {
        uniqueId: "control-ziutzunosrythittfikhpvdjkifyagnmepighxlxqfcnctmxbn",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pl-3 pr-0",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-zketgsftebywxmbaxlzuzgnrfcchrwjxzptbltaftlyzjpuyxw": {
        uniqueId: "control-zketgsftebywxmbaxlzuzgnrfcchrwjxzptbltaftlyzjpuyxw",
        type: "label",
        name: null,
        label: "CONTACT INFORMATION",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass: "m-0 space-between pr-3",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0 mt-4",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-zmaoqszcglbioerdahulljjddiwtozvijbckalsmtowdieesiq": {
        uniqueId: "control-zmaoqszcglbioerdahulljjddiwtozvijbckalsmtowdieesiq",
        type: "input",
        name: null,
        label: "Email",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0 sticky-up",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
      "control-zsargkaxsdjmejjkcopexeartuwvjumraftbmkzvbyerdvkzpr": {
        uniqueId: "control-zsargkaxsdjmejjkcopexeartuwvjumraftbmkzvbyerdvkzpr",
        type: "label",
        name: null,
        label: "CLIENT GOALS:",
        subLabel: null,
        isShowLabel: false,
        placeholderText: null,
        containerClass: "col-md-12 md-layout-item md-size-100",
        additionalContainerClass: "m-0 no-padding sticky-up",
        additionalFieldClass: null,
        additionalLabelClass: "p-0 m-0",
        defaultValue: null,
        validations: [],
        forAttribute: null,
      },
      "control-zwjblkcaulfkjrukabbsaxiaqogqoqqgimjtjmuvjivmslqyhm": {
        uniqueId: "control-zwjblkcaulfkjrukabbsaxiaqogqoqqgimjtjmuvjivmslqyhm",
        type: "input",
        name: null,
        label: "Name",
        subLabel: null,
        isShowLabel: true,
        placeholderText: null,
        containerClass: "col-md-6 md-layout-item md-size-50",
        additionalContainerClass:
          "space-between m-0 white-bg control-border pr-3 pl-0",
        additionalFieldClass: "pt-0 pb-0 h-30",
        additionalLabelClass: "p-0 pl-3 pt-1 mb-0",
        defaultValue: null,
        validations: [],
      },
    },
  },
};

export default {
  DEMO,
  DEMO2,
  CONTROLS: CONTROLS_CONSTANTS.CONTROLS,
  DEMOES,
};
